export const PRODUCT = {
  PRODUCTLIST: "PRODUCTLIST",
  CLEARPRODUCTLIST: "CLEARPRODUCTLIST",
};

export const CART = {
  CARTID: "CARTID",
  GETCARTCOUNT: "GETCARTCOUNT",
};

export const BRANCH = {
  GETBRANCH: "GETBRANCH",
  GETBRANCHTIME: "GETBRANCHTIME",
  LANGUAGE: "LANGUAGE",
  TYPE: "TYPE",
  CLEARBRANCHLIST: "CLEARBRANCHLIST",
  BRANCHLIST: "BRANCHLIST",
  BRANCHID: "BRANCHID",
  BRANCHNAME: "BRANCHNAME",
  BRANCHTIMEDATA: "BRANCHTIMEDATA",
  BRANCHTIMEDATACLEAR: "BRANCHTIMEDATACLEAR",
  BRANCHDETAILS: "BRANCHDETAILS",
  BRANCHPRIMARYLANGU: "BRANCHPRIMARYLANGU",
  BRANCHLANGUAGES: "BRANCHLANGUAGES",
  DELIVERYSETTINGSDATA: "DELIVERYSETTINGSDATA",
  DINEINTIMEDATA: "DINEINTIMEDATA",
  BRANCHDETAILSBYID: "BRANCHDETAILSBYID",
  WELCOMEMESSAGEDATA: "WELCOMEMESSAGEDATA",
};

export const MENU = {
  GETMENULIST: "GETMENULIST",
  SETMENULIST: "SETMENULIST",
};

export const ORDER = {
  CHECKOUT: "CHECKOUT",
  GETCHARGES: "GETCHARGES",
  GETCHECKORDERNOTERS: "GETCHECKORDERNOTERS",
  GETORDERDELIVERYCOST: "GETORDERDELIVERYCOST",
  SETORDERCOSTDATA: "SETORDERCOSTDATA",
  CLEAR: "CLEARSETORDERCOSTDATA",
  SHOWORDERNOTE: "SHOWORDERNOTE",
  SETCHARGES: "SETCHARGES",
  CLEARCHARGES: "CLEARCHARGES",
  SETCHECKOUTDATA: "SETCHECKOUTDATA",
  SETDELIVERYERROR: "SETDELIVERYERROR",
  CLEARDELIVERYERROR: "CLEARDELIVERYERROR",
  SETDELIVERYCOST: "SETDELIVERYCOST",
  SETORDERDATA: "SETORDERDATA",
};

export const CUSTOM = {
  URL: "URL",
  GETCUSTOMDATA: "GETCUSTOMDATA",
  RES: "RES",

  CMSURL: "CMSURL",
  GETCMSDATA: "GETCMSDATA",
  CMSRES: "CMSRES",
};
