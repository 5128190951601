import { call, put, select, takeEvery } from "redux-saga/effects";
import { apiMenuList } from "../../api";
import { apiCMDData, apiCustomData } from "../../api/custom-api";
import { cmsData, customData } from "../../core";
import { CUSTOM, MENU, setCMSRes, setCustomeRes } from "../actions";

const customUrl = (state) => state.custom.customUrl;

function* getCustomUrl() {
  try {
    const customUrl_ = yield select(customUrl);
    // console.log("customUrl_", customUrl_);
    const data = customData(customUrl_);
    // console.log("data", data);
    const response = yield call(apiCustomData, data);
    // console.log("sdsgsdg", response);
    if (response?.data) {
      //   console.log("sdgwetwt", response?.data);
      yield put(setCustomeRes(response.data));
    } else {
      // console.log("nodata")
      yield put(setCustomeRes([]));
    }
  } catch (error) {}
}


function* getCMSUrl() {
  try {
    const customUrl_ = yield select(customUrl);
    // console.log("customUrl_", customUrl_);
    const data = cmsData(customUrl_);
    // console.log("data", data);
    const response = yield call(apiCMDData, data);
    // console.log("sdsgsdg", response);
    if (response?.data) {
          const abc = { 
        footerData : response?.data?.footerData?.filter(item => item.is_active === true),
        headerData : response?.data?.headerData
      }
      // console.log("response", abc);
      // console.log("response" ,activeData)
      yield put(setCMSRes(abc));
    } else {
      // console.log("nodata")
      yield put(setCMSRes([]));
    }
  } catch (error) {}
}

export function* watchCustomUrl() {
  yield takeEvery(CUSTOM.GETCUSTOMDATA, getCustomUrl);
}

export function* watchCMSUrl() {
  yield takeEvery(CUSTOM.GETCMSDATA, getCMSUrl);
}



