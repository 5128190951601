
import { styled } from "@mui/material/styles";


export const colourStylesHeader = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: "0px",
      }),
      option: (base, state) => ({
        ...base,
        color: "#1e2022",
        backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
        cursor: "pointer",
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };