import { BRANCH } from "../../actions";

const branchPrimaryLanguageReducer = (state = 'en', action) => {
    switch (action.type) {
        case BRANCH.BRANCHPRIMARYLANGU:
            return action.primarylangu;
        default:
            return state;
    }
}

export default branchPrimaryLanguageReducer;