import { BRANCH } from "../../actions";



const branchDineInTimeDataReducer = (state = null, action) => {
    switch (action.type) {
        case BRANCH.DINEINTIMEDATA:
            return action.DineInTimeData;
        default:
            return state;
    }
}

export default branchDineInTimeDataReducer;