import $ from "jquery";
window.jquery = window.$ = $;

export function applyFilterMeHandlers() {
  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which !== 46 || $(this).val().indexOf(".") !== -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which === 46 && $(this).caret().start === 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") === 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });
}
