import { BRANCH } from "../../actions";

const languageReducer = (state = 'en', action) => {
    switch (action.type) {
        case BRANCH.LANGUAGE:
            return action.language
        default:
            return state;
    }
}

export default languageReducer;