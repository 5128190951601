import { combineReducers } from "@reduxjs/toolkit";
import { branchReducer } from "./branch";
import { menuReducer } from "./menu";
import { orderReducer } from "./order";
import { productReducer } from "./products";
import { customReducer } from "./custom";
import { cartReducer } from "./cart";

const rootReducer = combineReducers({
  product: productReducer,
  branch: branchReducer,
  menu: menuReducer,
  order: orderReducer,
  custom: customReducer,
  cart: cartReducer,
});

export { rootReducer };
