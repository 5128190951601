import { CUSTOM } from "./action-types";

const setCustome = (url) => ({
  type: CUSTOM.URL,
  url,
});

const setCustomeRes = (res) => ({
  type: CUSTOM.RES,
  res,
});

const getCustomData = () => ({
  type: CUSTOM.GETCUSTOMDATA,
});


const setCMS = (url) => ({
  type: CUSTOM.CMSURL,
  url,
});

const setCMSRes = (res) => ({
  type: CUSTOM.CMSRES,
  res,
});

const getCMSData = () => ({
  type: CUSTOM.GETCMSDATA,
});

export { setCustome, getCustomData, setCustomeRes ,setCMS ,setCMSRes , getCMSData};
