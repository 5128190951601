import { ORDER } from "../../actions";

const orderDeliveryCostErrorReducer = (state = '', action) => {
    switch (action.type) {
        case ORDER.CLEARDELIVERYERROR:
            return '';
        case ORDER.SETDELIVERYERROR:
            return action.error;
        default:
            return state;
    }
}

export default orderDeliveryCostErrorReducer;