import { combineReducers } from "@reduxjs/toolkit";
import chargesReducer from "./charges-reducer";
import checkoutReducer from "./checkout-data";
import deliveryCostReducer from "./delivery-cost";
import orderCostDataReducer from "./order-cost-data";
import orderDataReducer from "./order-data";
import orderDeliveryCostErrorReducer from "./order-delivery-cost-error";
import showOrderNoteReducer from "./show-order-note";

const orderReducer = combineReducers({
    charges: chargesReducer,
    checkoutData: checkoutReducer,
    showordernote: showOrderNoteReducer,
    ordercostdata: orderCostDataReducer,
    deliverycosterror: orderDeliveryCostErrorReducer,
    deliverycost: deliveryCostReducer,
    orderData: orderDataReducer,
})

export { orderReducer }