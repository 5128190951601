import { CUSTOM } from "../../actions";

const customUrlReducerRes = (state = null, action) => {
    switch (action.type) {
        case CUSTOM.RES:
            return action.res;
        default:
            return state;
    }
}

export default customUrlReducerRes;