import { BRANCH } from "../../actions";

const typeReducer = (state = 'pickup', action) => {
    switch (action.type) {
        case BRANCH.TYPE:
            return action.type_
        default:
            return state;
    }
}

export default typeReducer;