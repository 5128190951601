import { CONFIG } from "./../config";

export const URL = {
  BRANCHLIST: () => `${CONFIG.BASE_URL}/client/order/branchlist`,
  BRANCHTIMEFILTER: () => `${CONFIG.BASE_URL}/client/order/branchtimefilter`,
  MENULIST: () => `${CONFIG.BASE_URL}/client/order/menulist`,
  GETCHARGES: () => `${CONFIG.BASE_URL}/client/charges`,
  CHECKOUT: () => `${CONFIG.BASE_URL}/client/order`,
  CHECKORDERNOTES: () => `${CONFIG.BASE_URL}/client/order/checkForNotes`,
  DELIVERYCOST: () => `${CONFIG.BASE_URL}/client/charges/delivery`,
  BRANCHDETAILS: (id) => `${CONFIG.BASE_URL}/client/order/branchDetails/${id}`,
  CUSTOMDATA: () => `${CONFIG.BASE_URL}/client/custom`,
  CMSDATA: () => `${CONFIG.BASE_URL}/client/cms/headerFooter`,
  CART: (id) => `${CONFIG.BASE_URL}/client/cart/itemCount/${id}`,
};
