import { CUSTOM } from "../../actions";

const cmsUrlReducer = (state = null, action) => {
    switch (action.type) {
        case CUSTOM.CMSURL:
            return action.url;
        default:
            return state;
    }
}

export default cmsUrlReducer;