import { BRANCH } from "../../actions";

const branchdetailsbuIdReducer = (state = null, action) => {
  switch (action.type) {
    case BRANCH.BRANCHDETAILSBYID:
      return action.BranchDetailsGetById;
    default:
      return state;
  }
};

export default branchdetailsbuIdReducer;
