export const getCurrencyLabel = (currencyCode) => {
  switch (currencyCode) {
    case 'INR':
      return 'INR';
    case 'CHF':
        return 'CHF';
    case 'EUR':
      return 'EUR';
    case 'USD':
      return 'USD';
    case 'AUD':
      return 'AUD';
    case 'NZD':
      return 'NZD';
    case 'GBP':
      return 'GBP';
    case 'JPY':
      return 'JPY';
    case 'CAD':
      return 'CAD';
    case 'CNY':
      return 'CNY';
    case 'IDR':
      return 'IDR';
    case 'IQD':
      return 'IQD';
    case 'KWD':
      return 'KWD';
    case 'OMR':
      return 'OMR';
    case 'QAR':
      return 'QAR';
    case 'SGD':
      return 'SGD';
    case 'THB':
      return 'THB';
    case 'AED':
      return 'AED';
    default:
      return 'CHF';
  }
};
