import React from 'react';
import "./NewFoodListPage.css";



function DescriptionFetcher({ description }) {

  // Strip HTML tags to get the text content
  const cleanedStr = description?.replace(/&nbsp;/g, ' ');
  const textContent = cleanedStr?.replace(/<[^>]*>/g, '');
  const textLength = textContent?.length;

  // Truncate the description if it's longer than 140 characters
  const truncatedDescription = textLength >= 80 
    ? `${textContent.substring(0, 80)}...` 
    : textContent;

  // console.log("textLength", textLength, textLength >= 140, truncatedDescription, textContent?.substring(0, 135));

  return (
    <div className="sc-7000b91a-0 cZXofG__">
      <div className="text-overflow-fetcher">{truncatedDescription}</div>
    </div>
  );
}

export default DescriptionFetcher;
