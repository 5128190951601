import { ORDER } from "../../actions";

const deliveryCostReducer = (state = '', action) => {
    switch (action.type) {
        case ORDER.SETDELIVERYCOST:
            return action.value;
        default:
            return state;
    }
}

export default deliveryCostReducer;