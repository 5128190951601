import React from 'react'
import { QRCode } from "react-qrcode-logo";


export default function QRCodeOrder({ OrderNumber }) {
      return (
            <div> <QRCode
                  value={OrderNumber && String(OrderNumber) }
                  // size={256}
                  style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                  }}
                  level={"H"}
                  id="BRANCH_QR"
                  includeMargin={true}
                   qrStyle="squares"
            /></div>
      )
}
