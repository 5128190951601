import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const handleClick = (link) => {
      // Check if the link starts with 'http://' or 'https://'
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        // If not, prepend 'https://' to the link
        link = 'https://' + link;
      }
      window.open(link, '_blank');
    };

export const getSrc = (type, link, color, index) => {
      const commonProps = {
          style: { color: color, cursor: "pointer" },
          key: index,
          className: "mx-1 fs-2",
          onClick: () => handleClick(link)
      };
      switch (type) {
          case 'facebook':
              return <FacebookOutlinedIcon {...commonProps} />;
          case 'instagram':
              return <InstagramIcon {...commonProps} />;
          case 'twitter':
              return <TwitterIcon {...commonProps} />;
          case 'linkedin':
              return <LinkedInIcon {...commonProps} />;
          default:
              return '';
      }
  };

  export const getColumnSize = (length) => {
      switch (length) {
        case 3:
          return 4;
        case 2:
          return 6;
        case 1:
          return 12;
        default:
          return "";
      }
    };