import { all, fork } from "redux-saga/effects";
import {
  watchBranchDetails,
  watchBranchList,
  watchBranchTimeFilter,
} from "./branch-saga";
import { watchCMSUrl, watchCustomUrl } from "./custom-saga";
import { watchMenuList } from "./menu-saga";
import {
  watchCharges,
  watchChckoutOrder,
  watchOrderDeliveryCost,
  watchOrderNotesRequired,
} from "./order-saga";
import { watchCartCount } from "./cart-saga";

export function* rootSaga() {
  yield all([
    fork(watchBranchList),
    fork(watchBranchTimeFilter),
    fork(watchMenuList),
    fork(watchCartCount),
    fork(watchCharges),
    fork(watchChckoutOrder),
    fork(watchOrderNotesRequired),
    fork(watchOrderDeliveryCost),
    fork(watchBranchDetails),
    fork(watchCustomUrl),
    fork(watchCMSUrl)
  ]);
}
