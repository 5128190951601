import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CODPage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import api from "../../CommonApi/axios";

import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import { useMediaQuery } from "react-responsive";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";

function CODPageDinein(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    customRes,
    branchDineInTimeData,
    orderData,
  } = props;

  
  // console.log("location", location.state);
  let history = useHistory();
  const location = useLocation();

  
  const storedData = JSON.parse(sessionStorage.getItem("checkOut_storeData"));
  
  // console.log(storedData )

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  var pathname = window.location.href;
  var pathnamesplits = pathname.replace(/(https?:\/\/)?(www.)?/i, "").split("/");
  var url = pathnamesplits[1];

  useEffect(()=>{
            window.history.pushState(null, null, location.href);
            window.onpopstate = function(event) {
            history.go(1);
          };
    },[url])


  const downloadFile = (value) => {

      api
      .get(`/client/order/downloadOrderInvoiceForDineIn/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        // window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });   
  };

  return (
    <React.Fragment>
      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Row className="justify-content-sm-center">
            <div
              className="text-center"
              style={{ width: "580px", backgroundColor: "#F0EEED" }}
            >
              <Container>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <div className="mt-3 mb-4">
                        <img
                          style={{ height: "80px" }}
                          src="/assets/media/my/check.png"
                          alt="success_png"
                        />
                      </div>
                    </Card.Title>

                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={8}>
                        <Card.Title className="header_success">
                     Thank you for your visit
                          
                        </Card.Title>
                      </Col>
                      <Col lg={2}></Col>
                    </Row>

                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={8}>
                        <Card.Title className="payment_order_id mt-2">
                          <strong>
                            Hope you had a great time.
                          </strong> 
                        </Card.Title>
                      </Col>
                      <Col lg={2}></Col>
                    </Row>
                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={8}>
                        <Card.Title className="payment_text_info mt-1">
                       Please pay at the counter  or call manager for the payment.
                        <div  className="mt-2">
                        To dine in again, please scan the QR Code.
                          </div>
                        </Card.Title>
                      </Col>
                      <Col lg={2}></Col>
                    </Row>
                    <Row>
                      <Col
                        lg={12}
                        xs={12}
                        md={12}
                        className={
                          isMobile == true  ? "text-center" : "text-center" 
                        }
                      >
                        <Button
                          type="button"
                          style={{
                            backgroundColor:
                              customRes?.button_colour ?? "#dc3545",
                            border: 0,
                          }}
                          className="my-4 custom_hover"
                          // onClick={() => setabcd(!abcd)}
                          onClick={() => {
                            downloadFile(storedData?.order_id);
                          }}
                        >
                          <strong
                            style={{
                              color: customRes?.non_highlighted_text ?? "white",
                            }}
                          >
                            Download Receipt{" "}
                          </strong>
                        </Button>
                      </Col>
                      <Col
                        lg={6}
                        xs={12}
                        md={6}
                        className={
                          isMobile == true ? "text-center" : "text-start"
                        }
                      >
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CODPageDinein);


