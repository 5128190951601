import { http } from "../core"
import { URL } from "./_urls"
import { get } from 'lodash';

const apiCustomData = async (data) => {
    const response = await http.post(URL.CUSTOMDATA(), data);
    if (response && response.status >= 400) {
        const errorMessage = get(response, "data.message", "");
        throw new Error(errorMessage || "Something went wrong!")
    }
    return response || null;
}


const apiCMDData = async (data) => {
    const response = await http.post(URL.CMSDATA(), data);
    if (response && response.status >= 400) {
        const errorMessage = get(response, "data.message", "");
        throw new Error(errorMessage || "Something went wrong!")
    }
    return response || null;
}

export { apiCustomData ,apiCMDData }