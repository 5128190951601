import React from 'react'


export default function LiftSvg({ color }) {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 120 552"
                        fill="none"
                  >
                        <path
                              style={{
                                    fill: `${color || "rgb(255, 151, 30)"
                                          }`,
                                    filter: "brightness(90%)",
                              }}
                              d="M57.1897 75.481C24.5435 53.6934 19.6097 16.3865 17.6522 1.20918C17.6258 1.02844 17.6592 0.844006 17.7472 0.684184C17.8352 0.524362 17.9729 0.39799 18.1393 0.324467C18.3057 0.250944 18.4914 0.234336 18.6681 0.277191C18.8447 0.320046 19.0025 0.419996 19.1172 0.561697C27.0221 10.331 36.9205 18.2814 48.1439 23.8761C100.56 48.5592 91.7957 104.958 88.1929 120.724C87.7038 122.894 86.9437 122.852 86.5706 120.648C83.0342 100.085 68.655 84.9405 57.1897 75.481ZM211.391 547.231C211.826 547.498 212.163 547.902 212.349 548.38C212.535 548.858 212.56 549.384 212.42 549.878C212.281 550.372 211.985 550.807 211.578 551.116C211.17 551.425 210.673 551.591 210.162 551.59C37.7433 553.936 -55.3617 411.083 35.6468 253.853C53.1915 223.443 76.2281 195.802 101.864 158.363C116.542 136.909 139.569 71.6509 101.76 34.3784C101.599 34.2068 101.496 33.9883 101.467 33.7545C101.438 33.5206 101.483 33.2834 101.596 33.077C101.709 32.8706 101.885 32.7056 102.097 32.6058C102.31 32.506 102.548 32.4765 102.778 32.5216C213.158 55.6906 253.391 143.513 252.001 216.876C251.256 256.834 243.165 289.652 233.073 308.361C232.986 308.52 232.948 308.703 232.966 308.883C232.985 309.064 233.058 309.235 233.175 309.373C233.294 309.511 233.451 309.609 233.627 309.654C233.802 309.698 233.987 309.687 234.156 309.623C262.981 299.016 281.006 268.29 285.698 259.42C285.804 259.167 285.982 258.951 286.211 258.802C286.44 258.652 286.709 258.575 286.982 258.582C287.255 258.588 287.519 258.677 287.742 258.836C287.964 258.995 288.133 259.218 288.228 259.476C292.093 267.651 303.346 295.91 299.146 340.479C291.569 421.027 251.071 440.693 208.034 479.087C169.72 513.255 195.563 537.199 211.391 547.231Z"
                              fill="#FF971E"
                        />
                  </svg>
            </>
      )
}
