import { ORDER } from "../../actions";

const chargesReducer = (state = [], action) => {
    switch (action.type) {
        case ORDER.CLEARCHARGES:
            return [];
        case ORDER.SETCHARGES:
            return action.data;
        default:
            return state;
    }
}

export default chargesReducer;