import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import "./Cookies.css"

function CookieManagementComponent({customRes}) {
      const [cookieAccepted, setCookieAccepted] = useState(Cookies.get('cookieAccepted'));

      let cookieAccepted_ =  sessionStorage.getItem('cookieAccepted_')

      const [showModal, setShowModal] = useState(cookieAccepted_ === null ? true : cookieAccepted_ === "false" ? false : true);
  
      const handleClose = () => {
          setShowModal(false);
          sessionStorage.setItem('cookieAccepted_', false);
      };

      // console.log("cookieAccepted_" , cookieAccepted , cookieAccepted_ === null )
  
      const acceptCookies = () => {
          Cookies.set('cookieAccepted', true, { expires: 365*5 }); // Accept cookies for 1 year
          setCookieAccepted(true);
          setShowModal(false);
      };

    return (
                  <>
                  {!cookieAccepted  &&
                  <Modal show={showModal} contentClassName="contentClassName" dialogClassName="custom-modal">
                  <Modal.Body  className='my-4 mx-4'>
                  <Row>
                        <Col>
                  <Modal.Title className='title_'>🍪 Our website uses cookies</Modal.Title>
                        <Row className='text-start my-3'>
                              <Col className='Discripotion'>
                              Our website uses cookies. By continuing, we assume your permission to deploy 
                              cookies as detailed in our  <span style={{color : customRes?.button_colour || "#E1A523" }}>Privacy Policy</span>.
                              </Col>
                        </Row>
                        </Col>
                  </Row>
                  
                  <Row className='mt-3'>
                        <Col lg={6} mg={6} xs={6} sm={6} className="d-flex justify-content-center">
                        <Button onClick={handleClose} className='_button_' >Decline</Button>
                        </Col>
                        <Col lg={6} mg={6} xs={6} sm={6} className="d-flex justify-content-center">
                        <Button className='Accept' 
                        style={{background : customRes?.button_colour || "#E1A523", 
                              color : customRes?.non_highlighted_text || "#FFFFFF" }} onClick={acceptCookies} variant="primary">Accept</Button>
                        </Col>
                  </Row>
                  </Modal.Body>
                  </Modal>
                  }
                  </>
    );
}

export default CookieManagementComponent;
