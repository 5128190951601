import { ORDER } from "../../actions";

const showOrderNoteReducer = (state = false, action) => {
    switch (action.type) {
        case ORDER.SHOWORDERNOTE:
            return action.value;
        default:
            return state;
    }
}

export default showOrderNoteReducer;