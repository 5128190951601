import { call, put, select, takeEvery } from "redux-saga/effects";
import { apiCartCount } from "../../api/cart-api";
import { cartCount } from "../../core";
import { CART, setCartId } from "../actions";

const branchId = (state) => state.branch.branchId;
const cartId = (state) => state.cart.cartCountData;

function* getCartCount() {
  try {
    const id = yield select(cartId);
    const data = cartCount(id);
    // console.log("cartId_redux", id);
    const response = yield call(apiCartCount, data);
    if (response?.data) {
      // console.log("cartCount", response?.data);
      yield put(setCartId(response.data));
    } else {
      // console.log("nodata")
      yield put(setCartId([]));
    }
  } catch (error) {}
}

export function* watchCartCount() {
  yield takeEvery(CART.GETCARTCOUNT, getCartCount);
}

// export function* watchMenuList() {
//   yield takeEvery(MENU.GETMENULIST, getMenuList);
// }
