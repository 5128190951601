import { BRANCH } from "./action-types";

const getBranch = () => ({
  type: BRANCH.GETBRANCH,
});

const getBranchTime = () => ({
  type: BRANCH.GETBRANCHTIME,
});

const getBranchDetails = () => ({
  type: BRANCH.BRANCHDETAILS,
});

const setLanguage = (language) => ({
  type: BRANCH.LANGUAGE,
  language,
});

const setType = (type_) => ({
  type: BRANCH.TYPE,
  type_,
});

const setBranchList = (branchlist) => ({
  type: BRANCH.BRANCHLIST,
  branchlist,
});

const setBranchId = (id) => ({
  type: BRANCH.BRANCHID,
  id,
});

const setBranchName = (name) => ({
  type: BRANCH.BRANCHNAME,
  name,
});

const setBranchTimeData = (timeData) => ({
  type: BRANCH.BRANCHTIMEDATA,
  timeData,
});

const setBranchLanguageData = (languagelist) => ({
  type: BRANCH.BRANCHLANGUAGES,
  languagelist,
});

const setDeliverySettingsData = (DeliverySettingsData) => ({
  type: BRANCH.DELIVERYSETTINGSDATA,
  DeliverySettingsData,
});

const setDineInTimeData = (DineInTimeData) => ({
  type: BRANCH.DINEINTIMEDATA,
  DineInTimeData,
});

const setWelcomeMessageData = (WelcomeMessageData) => ({
  type: BRANCH.WELCOMEMESSAGEDATA,
  WelcomeMessageData,
});

const setBranchPrimaryLanguage = (primarylangu) => ({
  type: BRANCH.BRANCHPRIMARYLANGU,
  primarylangu,
});

const setBranchDetailsGetById = (BranchDetailsGetById) => ({
  type: BRANCH.BRANCHDETAILSBYID,
  BranchDetailsGetById,
});

export {
  getBranch,
  setType,
  setLanguage,
  setBranchList,
  setBranchId,
  setBranchName,
  getBranchTime,
  setBranchTimeData,
  getBranchDetails,
  setBranchLanguageData,
  setBranchPrimaryLanguage,
  setDeliverySettingsData,
  setDineInTimeData,
  setWelcomeMessageData,
  setBranchDetailsGetById,
};
