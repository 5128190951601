import React from 'react';
import "./NewFoodListPage.css";

function DescriptionToggleProdcut({ description }) {

  // Strip HTML tags to get the text content
  const cleanedStr = description?.replace(/<[^>]*>/g, '')?.replace(/&nbsp;/g, ' ')?.replace(/&amp;/g, ' ');
  const textContent = cleanedStr
  const textLength = textContent?.length;

  // Truncate the description if it's longer than 140 characters
  const truncatedDescription = textLength >= 140 
    ? `${textContent?.substring(0, 140)}...` 
    : textContent;

  // console.log("cleanedStr", cleanedStr);

  return (
    <div className="sc-7000b91a-0 cZXofG__">
      <div className="text-overflow">{truncatedDescription}</div>
    </div>
  );
}

export default DescriptionToggleProdcut;
