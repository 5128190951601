import { MENU } from "../../actions";

const menuListReducer = (state = [], action) => {
  switch (action.type) {
    case MENU.SETMENULIST:
      return action.data;
    default:
      return state;
  }
};

export default menuListReducer;
