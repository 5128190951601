import { combineReducers } from "@reduxjs/toolkit";
import branchIdReducer from "./branch-id";
import branchNameReducer from "./branch-name";
import branchLanguagesDataReducer from "./branch-languages";
import branchListReducer from "./branch-list";
import branchPrimaryLanguageReducer from "./branch-primary-langu";
import branchTimeReducer from "./branch-time";
import languageReducer from "./language-reducer";
import branchdeliverysettingsdataDataReducer from "./branch-DELIVERYSETTINGSDATA";
import branchDineInTimeDataReducer from "./branch-dineintimedata";
import branchwelcomemessageDataReducer from "./branch-welcomemessagedata";
import branchdetailsbuIdReducer from "./branch-detailsby_id";
import typeReducer from "./type-reducer";

const branchReducer = combineReducers({
  language: languageReducer,
  type: typeReducer,
  branchList: branchListReducer,
  branchId: branchIdReducer,
  branchName: branchNameReducer,
  branchTime: branchTimeReducer,
  branchPrimaryLanguage: branchPrimaryLanguageReducer,
  branchLanguageData: branchLanguagesDataReducer,
  branchdeliverysettingsdataData: branchdeliverysettingsdataDataReducer,
  branchDineInTimeData: branchDineInTimeDataReducer,
  branchwelcomemessageData: branchwelcomemessageDataReducer,
  branchdetailsbyid: branchdetailsbuIdReducer,
});

export { branchReducer };
