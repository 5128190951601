import { PRODUCT } from "./action-types";

const setProductList = (productList) => ({
    type: PRODUCT.PRODUCTLIST,
    productList
})

const clearProductList = () => ({
    type: PRODUCT.CLEARPRODUCTLIST,
})

export {
    setProductList,
    clearProductList,
}