import { call, put, select, takeEvery } from "redux-saga/effects";
import { apiMenuList } from "../../api";
import { menuData } from "../../core";
import { MENU, setMenuList } from "../actions";

const branchId = (state) => state.branch.branchId;

function* getMenuList() {
  try {
    const id = yield select(branchId);
    const data = menuData(id);

    // console.log("sdgsgsdgsdg", id);
    const response = yield call(apiMenuList, data);
    if (response?.data) {
      // console.log("sdgwetwt", response?.data);
      yield put(setMenuList(response.data));
    } else {
      // console.log("nodata")
      yield put(setMenuList([]));
    }
  } catch (error) {}
}

export function* watchMenuList() {
  yield takeEvery(MENU.GETMENULIST, getMenuList);
}
