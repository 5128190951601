import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import "../../HomePage/hover.css";
import InputGroup from "react-bootstrap/InputGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ".././cartpage1.css";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "animate.css";
import {
      getCharges,
      setProductList,
      checkout,
      setCustome,
      getCustomData,
      setCheckoutData,
      checkOrderNotes,
      setOrderCostData,
      getOrderCost,
      setDeliveryError,
      setLanguage,
      getBranchDetails,
      setBranchPrimaryLanguage,
      getMenuList,
      setBranchId,
      setType,
      setBranchName,
} from "../../../Store";
import api from "../../../CommonApi/axios";
import moment from 'moment-timezone';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import $ from "jquery";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { showErrorCouponAndVoucher, showErrorNotificationCart, showSuccessNotification, showSuccessNotificationCustome } from "../../CommonComponet/Swal/Swal";
import { showToastCommon } from "../../CommonComponet/Toastify/Toastify";
import CommonHeader from "../../../Comman/CommonHeader.js/CommonHeader";
import Footer from "../../../Comman/Footer";
import { getCurrencyLabel } from "../../CommonComponet/currencyUtils/currencyUtils";
import { applyFilterMeHandlers } from "../../CommonComponet/jqueryUtils/jqueryUtils";
import "./RevampCardPage.css"
import ButtonMUI from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
      NotepadEditRegular, VehicleTruckBagRegular, CircleRegular, ShoppingBagRegular, FoodRegular, CheckmarkCircleFilled,
      PaymentRegular, MoneyRegular, EditRegular, LocationRegular, CallRegular, MailRegular
} from '@fluentui/react-icons';
import Fab from '@mui/material/Fab';
import DeliveryDetailsModel from "./Model/DeliveryDetailsModel";
import PickupDetailsModel from "./Model/PickupDetailsModel";
import { incrementQuantity, decrementQuantity, hexToRGBA, BranchDetails, RenderCustomerInfo } from './cartActions';
import DineInDetailsModel from "./Model/DineInDetailsModel";
import { Errormessage } from "../../CommonComponet/Error/Errormessage";
import { useMediaQuery } from "react-responsive";
import RightSideSvg from "../CommonPage/RightSideSvg";
import LeftSideSvg from "../CommonPage/LeftSideSvg";



window.jquery = window.$ = $;

function RevampCardPage(props) {
      let history = useHistory();
      let { search } = useLocation();

      const query = new URLSearchParams(search);
      const TableNo = query.get("no");
      const sessionId = query.get("sessionId");
      const typeDirect = query.get("type");
      const servicestaffid = query.get("servicestaffid");

      let deliveryInfo = JSON.parse(sessionStorage.getItem("deliveryInfo")) || null;
      let pickupInfo = JSON.parse(sessionStorage.getItem("pickupInfo")) || null;
      let dineinInfo = JSON.parse(sessionStorage.getItem("dineinInfo")) || null;
      const QrCodeScanAndRedirect = sessionStorage.getItem("QrCodeScanAndRedirect");
      const item_value = JSON.parse(sessionStorage.getItem("items"));
      let is_restricted = sessionStorage.getItem("is_restricted");

      const [method, setmethod] = useState("");

      useEffect(() => {
            applyFilterMeHandlers()
      }, [])
      const [BranchDetails_, setBranchDetails_] = useState("");
      const [ErrorCheckOutTimeComper, setErrorCheckOutTimeComper] = useState([]);

      // Callback function to update the error data
      const handleErrorCheckOutTimeComper = (errorData) => {
            setErrorCheckOutTimeComper(errorData);
      };

      const { checkout, setCheckoutData, checkOrderNotes, showordernote, setType, customRes, } = props;

      let DineInData = JSON.parse(sessionStorage.getItem("DineInData"))
            ? JSON.parse(sessionStorage.getItem("DineInData"))
            : { PreTime: 15 };
      // console.log("BranchDetails_", BranchDetails_)

      const cart_id = sessionStorage.getItem("cart_id");
      const { poId } = useParams();
      // const { tableId } = useParams();

      var BranchName_ = decodeURIComponent(poId);

      const [cartList_, setCartList_] = useState([]);
      const [CartProductItem_, setCartProductItem_] = useState([]);
      const [FinalAmout_BranchDetails, setFinalAmout_BranchDetails] = useState("");
      const [paymentmethod, setpaymentmethod] = useState(false);

      let sessionBranchID = sessionStorage.getItem("sessionBranchID");
      const [BranchID, setBranchID] = useState(sessionBranchID);
      const [Currency, setCurrency] = useState("")

      useEffect(() => {
            api
                  .get(`/client/order/getBranchId/${BranchName_}`)
                  .then((res) => {
                        setBranchID(res?.data?.data?._id);
                        sessionStorage.setItem("sessionBranchID", res?.data?.data?._id);
                        setCurrency(res?.data?.data?.currency)
                  })
                  .catch((err) => {
                        // console.log(err.response?.data?.error?.message);
                  });
      }, [BranchName_]);

      useEffect(() => {
            GetBranchDetails();
      }, [BranchID])

      function GetBranchDetails() {
            if (!TableNo) {
                  if (BranchID) {
                        api.get(`/client/order/branchDetails/${BranchID}`).then((res) => {
                              const tableData = res.data.data;
                              if (!typeDirect && !servicestaffid) {
                                    if (tableData?.pickupTimeData !== null && tableData?.pickupTimeData?.slot_management_type !== undefined) {
                                          sessionStorage.setItem("slot_type", tableData?.pickupTimeData?.slot_management_type);
                                    }
                              }
                              setBranchDetails_(tableData);
                        }).catch((err) => {
                              if (err?.response) {
                                    if (err?.response?.data?.error?.message === "This branch is not active.") {
                                          showSuccessNotificationCustome("We're sorry, but orders cannot be placed at this time as the branch is currently inactive.", history)
                                    }
                                    // console.log("It's an array");
                              }
                        });
                  }
            }
      }

      const handleIncrement = (event, item_id, quantity_) => {
            // console.log("sssss" ,cart_id)
            incrementQuantity(event, item_id, quantity_, cart_id, getCartList, getCartForDine);
      };

      const handleDecrement = (event, item_id, quantity_) => {
            decrementQuantity(event, item_id, quantity_, cart_id, getCartList, getCartForDine);
      };

      const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

      const isTablet = useMediaQuery({ minWidth: 761, maxWidth: 988 });

      const [orderedItems, setOrderedItems] = useState([]);
      const [totalCost, setTotalCost] = useState(0);

      const handleChange = (item, event) => {
            if (event.target.checked) {
                  setOrderedItems((cartItem) => [
                        ...cartItem,
                        {
                              pos_id: item.pos_id,
                              price: item.price,
                              name_EN: item.name_EN,
                              name_DE: item.name_DE,
                              name_FR: item.name_FR,
                              name_IT: item.name_IT,
                              _id: item._id,
                        },
                  ]);
                  setTotalCost((total) => total + parseInt(item.price));
                  // add item to orderedItems array
            } else {
                  // remove item from orderedItems array
                  setOrderedItems((cartItem) =>
                        cartItem.filter((i) => i.name_EN !== item.name_EN)
                  );
                  setTotalCost((total) => total - parseInt(item.price));
            }
      };

      /////////////////// Get cart List ///////////////////////

      useEffect(() => {
            getCartList();
      }, []);

      useEffect(() => {
            getCartForDine();
      }, [BranchID, TableNo]);

      useEffect(() => {
            setType(cartList_?.order_type === "delivery" ? "delivery" : "pickup");
            checkOrderNotes();
      }, []);


      const currencyLabel = getCurrencyLabel(Currency);

      function getCartList() {
            if (cart_id) {

                  api.get(`/client/cart/${cart_id}`) //  get(`/client/cart/getCart/${cart_id}`) get(`/client/cart/${cart_id}`) ///// 
                        .then((res) => {
                              const tableData = res.data.data;
                              setCartList_(tableData);
                              setpromocode(tableData?.promo_code);
                              setCartProductItem_(tableData?.items.length > 0 ? tableData?.items : []);
                              if (tableData?.items.length <= 0) {
                                    sessionStorage.removeItem("cart_id");
                                    sessionStorage.removeItem("pickupInfo");
                                    sessionStorage.removeItem("deliveryInfo");
                                    sessionStorage.removeItem("items");
                                    sessionStorage.removeItem("QrCodeScanAndRedirect");
                                    sessionStorage.removeItem("is_restricted");
                                    if (typeDirect && servicestaffid) {
                                          history.push(`/${BranchName_}/menu?type=${typeDirect}&servicestaffid=${servicestaffid}`)
                                    } else if (TableNo) {
                                          history.push(`/${BranchName_}/menu?no=${TableNo}&sessionId=${sessionId}`)
                                    } else {
                                          history.push(`/${BranchName_}/menu`)
                                    }

                              }
                              if (tableData.couponError === "order amount is not sufficient for this coupon.") {
                                    showToastCommon("order amount is not sufficient for this coupon.")
                              }
                        })
                        .catch((err) => {
                              showToastCommon(err?.response?.data?.error?.message)
                        });
            }
      }

      const scrollRef = useRef();

      useEffect(() => {
            if (promocode === "") {
                  if (scrollRef.current) {
                        window.scrollTo(0, 0);
                        scrollRef.current.scrollIntoView({ behavior: "smooth" });
                  }
            }
      }, [cartList_]);


      function getCartForDine() {
            const url = `/client/cart/getOrderForDineIn/${BranchID}/${TableNo}`;
            if (BranchID && TableNo) {
                  api
                        .get(url)
                        .then((res) => {
                              const tableData = res?.data?.data;
                              setFinalAmout_BranchDetails(res?.data);
                              console.log("tableData", res.data);
                              setCartList_(tableData?.length > 0 ? tableData : []);
                              setpromocode(res?.data?.promo_code_id);
                              setCartProductItem_(res?.data?.data?.length > 0 ? res?.data?.data : []);
                              // if (tableData?.length <= 0) {
                              //       sessionStorage.removeItem("cart_id");
                              // }
                              if (
                                    tableData.couponError ===
                                    "order amount is not sufficient for this coupon."
                              ) {
                                    showToastCommon("order amount is not sufficient for this coupon.")
                              }
                        })
                        .catch((err) => {
                              showToastCommon(err?.response?.data?.error?.message)
                        });
            }
      }

      /////////////////// Get cart List ///////////////////////

      /////////////////////// Promo code //////////////////////

      const [promocode, setpromocode] = useState("");
      const [errorpromo, setErrorPromo] = useState(null);
      const [ApplyPromocodeLoader, serApplyPromocodeLoader] = useState(false);

      function Check_Promocode(event) {
            event.preventDefault();
            // showToastCommon(null);
            serApplyPromocodeLoader(true);


            const item = TableNo
                  ? { branch_id: BranchID, promoCode: promocode }
                  : { promo_code: promocode };

            const apiEndpoint = TableNo
                  ? `/client/order/promoCodeForDineIn/${TableNo}`
                  : `/client/cart/promoCode/${cart_id}`;

            const fetchCart = TableNo ? getCartForDine : getCartList;

            if (TableNo || cart_id) {
                  api.post(apiEndpoint, item)
                        .then(() => {
                              serApplyPromocodeLoader(false);
                              setpaymentmethod(false);
                              setcheckOutEroor(null);
                              showSuccessNotification("Promo code applied successfully.");
                              fetchCart();
                              if (TableNo) {
                                    setTimeout(() => {
                                          getCartForDine();
                                    }, 1000);
                              }
                        })
                        .catch((err) => {
                              serApplyPromocodeLoader(false);
                              setpaymentmethod(false);
                              setcheckOutEroor(null);
                              setpromocode("")
                              if (err.response) {
                                    showErrorCouponAndVoucher(err?.response?.data?.error?.message);
                                    // setTimeout(() => {
                                    //       showErrorCouponAndVoucher(null);
                                    // }, 6000);
                              }
                        });
            }
      }

      const [RemovePromocodeLoader, serRemovePromocodeLoader] = useState(false);

      function Remove_Promocode(event) {
            event.preventDefault();
            // showToastCommon(null);
            serRemovePromocodeLoader(true);

            const handleSuccess = () => {
                  TableNo ? getCartForDine() : getCartList();
                  setpromocode("");
                  setpaymentmethod(false);
                  setcheckOutEroor(null);
                  serRemovePromocodeLoader(false);
            };

            const handleError = (err) => {
                  if (err.response) {
                        serRemovePromocodeLoader(false);
                        setcheckOutEroor(null);
                        showToastCommon(err.response?.data?.error?.message);
                        setTimeout(() => showToastCommon(null), 4000);
                  }
            };

            if (TableNo) {
                  const item = { branch_id: BranchID };
                  api
                        .delete(`/client/order/promoCode/${TableNo}`, { data: item })
                        .then(handleSuccess)
                        .catch(handleError);
            } else if (cart_id) {
                  api
                        .delete(`/client/cart/promoCode/${cart_id}`)
                        .then(handleSuccess)
                        .catch(handleError);
            }
      }

      /////////////////////// Promo code /////////////////////

      const [loader, setloader] = useState(false);
      const [Terms_and_conditions, setTerms_and_conditions] = useState(false);
      const [order_notes, setorder_notes] = useState("");
      const [full_name, setfull_name] = useState("");
      const [email, setemail] = useState("");
      const [phone_number, setphone_number] = useState("");
      const [want_to_recieve_offers, setwant_to_recieve_offers] = useState(true)

      const [checkOutEroor, setcheckOutEroor] = useState(null);

      useEffect(() => {
            if (QrCodeScanAndRedirect || is_restricted) {
                  if (item_value?.order_type === "delivery") {
                        const updatedItems = { ...item_value, ...deliveryInfo };
                        sessionStorage.setItem("deliveryInfo", JSON.stringify(updatedItems));
                  } else if (item_value?.order_type === "pickup") {
                        const updatedItems = { ...item_value, ...pickupInfo };
                        sessionStorage.setItem("pickupInfo", JSON.stringify(updatedItems));
                  }
                  sessionStorage.removeItem("Delivery_detailes");
                  sessionStorage.removeItem("Pickup_detailes");
            }
      }, [(QrCodeScanAndRedirect || is_restricted)]);

      useEffect(() => {
            if (deliveryInfo) {
                  setmethod("delivery");
                  setemail(deliveryInfo?.customerEmail || "");
                  setfull_name(deliveryInfo?.fullName || "");
                  setphone_number(deliveryInfo?.phone || "");
            } else if (pickupInfo) {
                  setmethod("pickup");
                  setemail(pickupInfo?.customerEmail || "");
                  setfull_name(pickupInfo?.fullName || "");
                  setphone_number(pickupInfo?.phone || "");
            } else if (dineinInfo) {
                  setmethod("dinein");
                  setemail(dineinInfo?.customerEmail || "");
                  setfull_name(dineinInfo?.fullName || "");
                  setphone_number(dineinInfo?.phone || "");
            } else {
                  setmethod("");
                  setemail("");
                  setfull_name("");
                  setphone_number("");
            }
      }, [deliveryInfo, pickupInfo]);



      ////////// Check for only one ordertype avalibal ////////
      // useEffect(() => {
      //       if (!TableNo) {
      //             if (BranchDetails_) {
      //                   if (BranchDetails_?.is_pickup === true && BranchDetails_?.is_delivery === false) {
      //                         if (pickupInfo === null) {
      //                               const formData = {
      //                                     order_type: "pickup",
      //                                     PreTime: "25",
      //                               };
      //                               sessionStorage.setItem("pickupInfo", JSON.stringify(formData));
      //                               sessionStorage.removeItem("deliveryInfo");
      //                         }
      //                   } else if (BranchDetails_?.is_pickup === false && BranchDetails_?.is_delivery === true) {
      //                         if (deliveryInfo === null) {
      //                         const formData = {
      //                               order_type: "delivery",
      //                               PreTime: "25",
      //                         };
      //                         sessionStorage.setItem("deliveryInfo", JSON.stringify(formData));
      //                         sessionStorage.removeItem("pickupInfo");
      //                   }
      //                   }

      //             }
      //       }
      // }, [!TableNo ,BranchDetails_])
      ////////// Check for only one ordertype avalibal ////////


      useEffect(() => {
            if (typeDirect && servicestaffid) {
                  setTerms_and_conditions(true);
                  setpaymentmethod("COD");
            }
      }, [typeDirect && servicestaffid]);

      useEffect(() => {
            if (typeDirect || servicestaffid) return; // Exit early if either condition is true

            const isAmountEqual = Number(cartList_?.total_amount_without_Discount) === Number(cartList_?.discount_amount);

            if (isAmountEqual) {
                  setpaymentmethod('COD');
                  return; // No need to continue if we set the payment method here
            }

            const currentItem = CartProductItem_[0];
            if (currentItem) {
                  if (currentItem.payment_method_online && !currentItem.payment_method_cash) {
                        setpaymentmethod('ONLINE');
                  } else if (!currentItem.payment_method_online && currentItem.payment_method_cash) {
                        setpaymentmethod('COD');
                  }
            }
      }, [typeDirect, servicestaffid, cartList_, CartProductItem_]);

      useEffect(() => {
            if (typeDirect || servicestaffid) return; // Exit early if either condition is true

            const { final_amount, discount_amount, payment_method_online, payment_method_cash } = FinalAmout_BranchDetails || {};

            if (Number(final_amount) === Number(discount_amount)) {
                  setpaymentmethod('COD');
                  return; // Exit early if payment method is set to 'COD'
            }

            if (payment_method_online && !payment_method_cash) {
                  setpaymentmethod('ONLINE');
            } else if (!payment_method_online && payment_method_cash) {
                  setpaymentmethod('COD');
            }
      }, [typeDirect, servicestaffid, FinalAmout_BranchDetails]);

      let slot_type = sessionStorage.getItem("slot_type")

      function handalCheckOut(event) {
            event.preventDefault();
            setcheckOutEroor(null);

            if (TableNo) {
                  if (paymentmethod === false) {
                        setcheckOutEroor("Please select payment method.");
                        return;
                  } else if (Terms_and_conditions === false) {
                        setcheckOutEroor("Please accept terms and conditions.");
                        return;
                  } else {
                        setloader(true);
                        let item = new FormData();

                        if (email) {
                              item.append("email", email);
                        }
                        // if (cart_id) {
                        //   item.append("cart_id", cart_id);
                        // }

                        item.append("table_number", TableNo);
                        item.append("want_to_recieve_offers", want_to_recieve_offers);
                        item.append(
                              "discount_amount",
                              Number(FinalAmout_BranchDetails?.discount_amount || 0)
                        );
                        if (paymentmethod) {
                              item.append("payment_method", String(paymentmethod));
                        }
                        item.append(
                              "total_amount",
                              Number(FinalAmout_BranchDetails?.remaining_amount || 0)
                        );
                        item.append("branch_id", FinalAmout_BranchDetails?.branchData?._id);
                        if (DineInData != null) {
                              item.append("order_prep_interval", DineInData?.PreTime);
                        }

                        // console.log("passdata", Object.fromEntries(item.entries()));
                        // return

                        if (paymentmethod === "ONLINE") {
                              api
                                    .post(`/client/order/addFinalDineInOrder`, item)
                                    .then((response) => {
                                          // console.log("response.data", response?.data?.data?.paymentRes?.RedirectUrl);
                                          const createdAt = cartList_?.[0]?.orderItemDetails?.[0]?.created_at;

                                          let checkOut_storeData = {
                                                date: createdAt ? moment(createdAt).format("DD-MM-YYYY") : "",
                                                time: createdAt ? moment(createdAt).format("h:mm a") : "",
                                                orderType: "dinein",
                                                total: FinalAmout_BranchDetails?.final_amount_after_discount || "0",
                                                orderId: response?.data?.data?.unique_code,
                                                order_id: response?.data?.data?.unique_code,
                                                BranchID: BranchID
                                          };

                                          let itemPrint = {
                                                order_id: FinalAmout_BranchDetails?.data?.[0]?._id || undefined,
                                          };

                                          localStorage.setItem('itemPrint', itemPrint?.order_id);

                                          // console.log("itemprinte" ,itemPrint)
                                          // return

                                          // const tableData = response?.data?.data?.paymentRes?.RedirectUrl;

                                          // console.log("response" ,response)
                                          const redirectUrl = response.data.data?.paymentRes?.redirectUrl || response.data.data?.paymentRes?.RedirectUrl;
                                          if (redirectUrl) {
                                                setCheckoutData(checkOut_storeData);
                                                checkout();
                                                setloader(false);
                                                setTimeout(() => {
                                                      sessionStorage.removeItem("cart_id");
                                                      sessionStorage.clear();
                                                }, 100);
                                                window.open(
                                                      `${redirectUrl}`,
                                                      "_self"
                                                );
                                          } else {
                                          }
                                    })
                                    .catch((err) => {
                                          setloader(false);
                                          setcheckOutEroor(err?.response?.data?.error?.message);
                                    });


                        } else {
                              setloader(true);
                              api
                                    .post(`/client/order/addFinalDineInOrder`, item)
                                    .then((response) => {

                                          let itemPrint = {
                                                order_id: FinalAmout_BranchDetails?.data?.[0]?._id || undefined,
                                          };

                                          api.post("/client/order/print-order-summary", itemPrint).then((res) => {
                                                // console.log("res" ,res);
                                          }).catch((err) => {

                                          })

                                          const createdAt = cartList_?.[0]?.orderItemDetails?.[0]?.created_at;

                                          let checkOut_storeData = {
                                                date: createdAt ? moment(createdAt).format("DD-MM-YYYY") : "",
                                                time: createdAt ? moment(createdAt).format("h:mm a") : "",
                                                orderType: "dinein",
                                                total: FinalAmout_BranchDetails?.final_amount_after_discount || "0",
                                                orderId: response?.data?.data?.unique_code,
                                                order_id: response?.data?.data?.unique_code,
                                                TableNo,
                                                branchName: BranchName_,
                                                BranchID: BranchID
                                          };

                                          setCheckoutData(checkOut_storeData);
                                          checkout();
                                          setloader(false);
                                          setcheckOutEroor(null);
                                          sessionStorage.removeItem("cart_id");
                                          sessionStorage.clear();
                                          console.log("checkOut_storeData", checkOut_storeData)
                                          history.push({
                                                pathname: `/success`,
                                                state: checkOut_storeData,
                                          });
                                          sessionStorage.setItem("checkOut_storeData", JSON.stringify(checkOut_storeData));
                                    })
                                    .catch((err) => {
                                          setloader(false);
                                          setcheckOutEroor(err?.response?.data?.error?.message);
                                    });
                        }
                  }
            } else {
                  if (slot_type === "direct") {
                        if (paymentmethod === false) {
                              setcheckOutEroor("Please select payment method.");
                              return;
                        } else if (Terms_and_conditions === false) {
                              setcheckOutEroor("Please accept terms and conditions.");
                              return;
                        } else {
                              if (paymentmethod === "ONLINE") {
                                    setloader(true);
                              }

                              let item = new FormData();

                              if (full_name) {
                                    item.append("full_name", full_name);
                              }
                              if (email) {
                                    item.append("email", email);
                              }
                              if (phone_number) {
                                    item.append("phone_number", phone_number);
                              }
                              item.append("want_to_recieve_offers", want_to_recieve_offers);
                              if (cart_id) {
                                    item.append("cart_id", cart_id);
                              }
                              item.append("table_number", 0)
                              if (order_notes) {
                                    item.append("order_notes", order_notes);
                              }
                              if (paymentmethod) {
                                    item.append("payment_method", String(paymentmethod));
                              }
                              item.append("total_amount", Number(cartList_?.total_amount_without_Discount || 0) + Number(totalCost || 0));

                              // }

                              // console.log("passdata", Object.fromEntries(item.entries()));
                              // return

                              if (paymentmethod === "ONLINE") {
                                    api
                                          .post(`/client/order/add`, item)
                                          .then((response) => {
                                                // console.log("response.data", response.data);
                                                let checkOut_storeData = {
                                                      date: cartList_?.order_date,
                                                      time: cartList_?.order_time,
                                                      orderType: "pickup",
                                                      total: Number(cartList_?.total_amount_without_Discount || 0) + Number(totalCost || 0),
                                                      orderId: response?.data?.data?.order_no,
                                                      order_id: response?.data?.data?.order_id,
                                                };
                                                // const tableData = response.data.data?.paymentRes?.redirectUrl;
                                                // console.log("response", tableData)
                                                // localStorage.setItem("hostedCheckoutId" ,tableData?.paymentRes?.hostedCheckoutId)
                                                const redirectUrl = response.data.data?.paymentRes?.redirectUrl || response.data.data?.paymentRes?.RedirectUrl;

                                                if (redirectUrl) {
                                                      console.log("check")
                                                      setCheckoutData(checkOut_storeData);
                                                      checkout();
                                                      setloader(false);
                                                      setTimeout(() => {
                                                            sessionStorage.removeItem("cart_id");
                                                            sessionStorage.clear();
                                                      }, 100);
                                                      window.open(`${redirectUrl}`, "_self");
                                                } else {
                                                }
                                          })
                                          .catch((err) => {
                                                setloader(false);
                                                setcheckOutEroor(err?.response?.data?.error?.message);
                                          });
                              } else {
                                    setloader(false);
                                    Swal.fire({
                                          text: `Are you sure you want to order from ${CartProductItem_[0]?.branch_name_EN} ?`,
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, Order it !",
                                    }).then((result) => {
                                          if (result.isConfirmed) {
                                                setloader(true);
                                                api
                                                      .post(`/client/order/add`, item)
                                                      .then((response) => {
                                                            // console.log("response.data", response.data.data);
                                                            let checkOut_storeData = {
                                                                  date: cartList_?.order_date,
                                                                  time: cartList_?.order_time,
                                                                  orderType: "pickup",
                                                                  total: Number(cartList_?.total_amount_without_Discount || 0) + Number(totalCost || 0),
                                                                  orderId: response?.data?.data?.order_no,
                                                                  order_id: response?.data?.data?.order_id,
                                                                  BranchID: BranchID
                                                            };
                                                            setCheckoutData(checkOut_storeData);
                                                            checkout();
                                                            setloader(false);
                                                            setcheckOutEroor(null);
                                                            sessionStorage.removeItem("cart_id");
                                                            sessionStorage.clear();
                                                            history.push({
                                                                  pathname: `/success`,
                                                                  state: checkOut_storeData,
                                                            });
                                                            sessionStorage.setItem("checkOut_storeData", JSON.stringify(checkOut_storeData));
                                                      })
                                                      .catch((err) => {
                                                            setloader(false);
                                                            setcheckOutEroor(err?.response?.data?.error?.message);
                                                      });
                                          } else {
                                                setloader(false);
                                                setcheckOutEroor(null);
                                          }
                                    });
                              }
                        }
                  } else {

                        if (method === "") {
                              setcheckOutEroor("Please select preferred method of order.");
                              return;
                        }

                        else if ((full_name || email || phone_number) === "") {
                              setcheckOutEroor(`Please enter ${method} details.`);
                              return;
                        }
                        else if (paymentmethod === false) {
                              setcheckOutEroor("Please select payment method.");
                              return;
                        } else if (Terms_and_conditions === false) {
                              setcheckOutEroor("Please accept terms and conditions.");
                              return;
                        } else {
                              if (paymentmethod === "ONLINE") {
                                    setloader(true);
                              }

                              let item = new FormData();

                              item.append("full_name", full_name);
                              item.append("email", email);
                              item.append("phone_number", phone_number);
                              item.append("want_to_recieve_offers", want_to_recieve_offers);
                              item.append("table_number", "0");

                              if (cart_id) item.append("cart_id", cart_id);
                              if (order_notes) item.append("order_notes", order_notes);
                              if (paymentmethod) item.append("payment_method", String(paymentmethod));
                              // if (cartList_?.delivery_cost) item.append("delivery_cost", Number(cartList_.delivery_cost));

                              if (cartList_?.delivery_cost) {
                                    item.append("delivery_cost", Number(cartList_?.delivery_cost));
                              }

                              // Calculate and append total_amount
                              const totalAmount = Number(cartList_?.total_amount_without_Discount) + Number(totalCost) || 0;
                              item.append("total_amount", totalAmount);

                              // console.log("passdata", Object.fromEntries(item.entries()));
                              // return

                              if (paymentmethod === "ONLINE") {
                                    api
                                          .post(`/client/order/add`, item)
                                          .then((response) => {
                                                // console.log("response.data", response.data);
                                                let checkOut_storeData = {
                                                      date: cartList_?.order_date,
                                                      time: cartList_?.order_time,
                                                      orderType: cartList_?.order_type,
                                                      total: Number(cartList_?.total_amount_without_Discount || 0) + Number(totalCost || 0),
                                                      orderId: response?.data?.data?.order_no,
                                                      order_id: response?.data?.data?.order_id,
                                                      BranchID: BranchID
                                                };

                                                // const tableData = response.data.data?.paymentRes?.redirectUrl;
                                                // console.log("response", tableData)
                                                // localStorage.setItem("hostedCheckoutId" ,tableData?.paymentRes?.hostedCheckoutId)
                                                const redirectUrl = response.data.data?.paymentRes?.redirectUrl || response.data.data?.paymentRes?.RedirectUrl;

                                                if (redirectUrl) {
                                                      setCheckoutData(checkOut_storeData);
                                                      checkout();
                                                      setloader(false);
                                                      setTimeout(() => {
                                                            sessionStorage.removeItem("cart_id");
                                                            sessionStorage.clear();
                                                      }, 100);
                                                      setTimeout(() => {
                                                            sessionStorage.setItem("orderForQr", cartList_?.order_type);
                                                      }, 200);
                                                      window.open(`${redirectUrl}`, "_self");
                                                } else {
                                                }
                                          })
                                          .catch((err) => {
                                                setloader(false);
                                                setcheckOutEroor(err?.response?.data?.error?.message);
                                          });
                              } else {
                                    setloader(false);
                                    Swal.fire({
                                          text: `Are you sure you want to order from ${CartProductItem_[0]?.branch_name_EN} ?`,
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, Order it !",
                                    }).then((result) => {
                                          if (result.isConfirmed) {
                                                setloader(true);
                                                api
                                                      .post(`/client/order/add`, item)
                                                      .then((response) => {
                                                            // console.log("response.data", response.data.data);
                                                            let checkOut_storeData = {
                                                                  date: cartList_?.order_date,
                                                                  time: cartList_?.order_time,
                                                                  orderType: cartList_?.order_type,
                                                                  total: Number(cartList_?.total_amount_without_Discount || 0) + Number(totalCost || 0),
                                                                  orderId: response?.data?.data?.order_no,
                                                                  order_id: response?.data?.data?.order_id,
                                                                  BranchID: BranchID
                                                            };
                                                            // setCheckoutData(checkOut_storeData);
                                                            checkout();
                                                            setloader(false);
                                                            setcheckOutEroor(null);
                                                            sessionStorage.removeItem("cart_id");
                                                            sessionStorage.clear();
                                                            history.push({
                                                                  pathname: `/success`,
                                                                  state: checkOut_storeData,
                                                            });
                                                            sessionStorage.setItem("checkOut_storeData", JSON.stringify(checkOut_storeData));

                                                      })
                                                      .catch((err) => {
                                                            setloader(false);
                                                            setcheckOutEroor(err?.response?.data?.error?.message);
                                                      });
                                          } else {
                                                setloader(false);
                                                setcheckOutEroor(null);
                                          }
                                    });
                              }
                        }
                  }
            }
      }

      useEffect(() => {
            if (!cartList_?.total_amount || slot_type !== "direct") return;

            const prepareOrderDetails = () => {
                  const baseItem = {
                        order_type: "pickup",
                        PreTime: "25",
                        order_date: moment().add(15, 'minutes').format(),
                        order_time: moment().add(15, 'minutes').format('LT'),
                        placingDate: moment().format("YYYY-MM-DD"),
                        placingTime: new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }),
                  };

                  if (cart_id) {
                        baseItem.cart_id = cart_id;
                  }

                  if (cartList_?.total_amount || totalCost) {
                        baseItem.total_amount = Number(Number(cartList_?.total_amount) + Number(totalCost || 0)).toFixed(2);
                  }

                  return baseItem;
            };

            const item = prepareOrderDetails();

            api
                  .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
                  .then(() => {
                        // Store data and manage session storage
                        sessionStorage.setItem("pickupInfo", JSON.stringify(item));
                        sessionStorage.removeItem("deliveryInfo");

                        // Refresh the cart list
                        getCartList();
                  })
                  .catch((err) => {
                        const errorMessage = err?.response?.data?.error?.message;

                        if (typeof errorMessage === "string") {
                              // Log or handle string errors (e.g., UI feedback)
                        } else if (Array.isArray(errorMessage)) {
                              showToastCommon("The selected products are not available for this order. Please remove unavailable items to proceed");
                              closePickup();
                        }
                  });
      }, [slot_type, cartList_?.total_amount]);

      //////////////////////   Customer Details Model ///////////////////////////////

      const [showDelivery, setshowDelivery] = useState(false);

      const openDelivery = () => {
            setshowDelivery(true);
            setcheckOutEroor(null);
      };

      const closeDelivery = () => {
            setshowDelivery(false);
            setcheckOutEroor(null);
      };

      const [showPickup, setshowPickup] = useState(false);

      const openPickup = () => {
            setshowPickup(true);
            setcheckOutEroor(null);
      };

      const closePickup = () => {
            setshowPickup(false);
            setcheckOutEroor(null);
      };

      const [showDineIn, setshowDineIn] = useState(false);

      const openDineIn = () => {
            setshowDineIn(true);
            setcheckOutEroor(null);
      };

      const closeDineIn = () => {
            setshowDineIn(false);
            setcheckOutEroor(null);
      };

      const methods = [
            {
                  id: 1,
                  name: 'Delivery',
                  icon: <VehicleTruckBagRegular style={{ color: deliveryInfo ? "#fff" : "#344054" }} className="Delivery_icon" />,
                  onClick: ((QrCodeScanAndRedirect || is_restricted) && !deliveryInfo) ? "" : openDelivery,
                  is_available: BranchDetails_?.is_delivery,
                  Info: deliveryInfo,
                  dateAndTime: deliveryInfo ? deliveryInfo.order_date ? `${moment(deliveryInfo?.order_date).format("DD/MM/YYYY")} ${deliveryInfo?.order_time}` : "" : "",
            },
            {
                  id: 2,
                  name: 'Pickup',
                  icon: <ShoppingBagRegular style={{ color: (slot_type === "direct" || pickupInfo) ? "#fff" : "#344054" }} className="Delivery_icon" />,
                  onClick: ((QrCodeScanAndRedirect || is_restricted) && !pickupInfo) ? "" : openPickup,
                  is_available: BranchDetails_?.is_pickup,
                  Info: pickupInfo,
                  dateAndTime: pickupInfo ? pickupInfo.order_date ? `${moment(pickupInfo?.order_date).format("DD/MM/YYYY")} ${pickupInfo?.order_time}` : "" : "",
            },
            {
                  id: 3,
                  name: 'Dine In',
                  icon: <FoodRegular style={{ color: "#fff" }} className="Delivery_icon" />,
                  onClick: openDineIn,
                  is_available: true,
                  Info: "",
                  dateAndTime: TableNo ? `${moment(FinalAmout_BranchDetails?.dine_in_start).format("DD/MM/YYYY")}  ${moment(FinalAmout_BranchDetails?.dine_in_start).format('h:mm A')}` : "",
            }
      ];




      const showCashOnly = TableNo ? Number(FinalAmout_BranchDetails?.remaining_amount) === 0
            : Number(cartList_?.total_amount) === 0
            || (typeDirect && servicestaffid);

      // console.log("showCashOnly", showCashOnly);
      const isOnlineAvailable = TableNo
            ? FinalAmout_BranchDetails?.payment_method_online
            : CartProductItem_?.[0]?.payment_method_online;

      const isCashAvailable = TableNo
            ? FinalAmout_BranchDetails?.payment_method_cash
            : CartProductItem_?.[0]?.payment_method_cash;

      const paymentMethods = [
            {
                  id: 1,
                  name: 'Online Payment',
                  value: "ONLINE",
                  icon: <PaymentRegular style={{ color: paymentmethod === "ONLINE" ? "#fff" : "#344054" }} className="Delivery_icon" />,
                  is_available: isOnlineAvailable
                  // onClick: openOnlinePayment, // replace with the actual function if needed
            },
            {
                  id: 2,
                  name: TableNo ? "Pay at Counter" : "Cash",
                  value: "COD",
                  icon: <MoneyRegular style={{ color: paymentmethod === "COD" ? "#fff" : "#344054" }} className="Delivery_icon" />,
                  is_available: isCashAvailable
                  // onClick: openCash, // replace with the actual function if needed
            },
      ];

      // Filter the paymentMethods based on the condition
      const filteredPaymentMethods = showCashOnly
            ? paymentMethods.filter((method) => method.value === "COD") // Show only Cash
            : paymentMethods;

      // console.log("filteredPaymentMethods", filteredPaymentMethods)

      return (
            <div ref={scrollRef}>
                  <CommonHeader />
                  <div style={{
                        backgroundColor: "rgb(240, 238, 237)",
                        // minHeight: "100vh"
                  }}>
                        <br />
                        <Container>
                              {/* <Row>
                              <Col lg={2} xs={0}>
                              {!isMobile && (
                                    <RightSideSvg />
                              )}
                              </Col>
                              <Col lg={8} xs={10}> */}

                              <Row className="justify-contentc-sm-center ">
                                    <Col xxl={{ span: 7, order: 1 }}
                                          xl={{ span: 7, order: 1 }}
                                          lg={{ span: 6, order: 1 }}
                                          md={{ span: 6, order: 1 }}
                                          sm={{ span: 6, order: 1 }}
                                          xs={{ span: 12, order: 2 }} className="mb-3">
                                          <Card className="card_style">
                                                <Card.Body>
                                                      <Row>
                                                            <BranchDetails
                                                                  branchName={TableNo ? FinalAmout_BranchDetails?.branchData?.branch_name_EN : CartProductItem_?.[0]?.branch_name_EN}
                                                                  branchAddress={TableNo ? FinalAmout_BranchDetails?.branchData?.address : CartProductItem_?.[0]?.branch_address}
                                                            />
                                                      </Row>
                                                      <hr className="hr_" />

                                                      <Row className="mb-2">
                                                            <Col lg={12} md={12} xs={12}>
                                                                  <div className="order_method mb-4">
                                                                        How do you want to get your food?
                                                                  </div>
                                                            </Col>
                                                            {TableNo ?
                                                                  methods?.filter((method) => method.name === "Dine In").map((method, index) => (
                                                                        <Col onClick={method.onClick} key={index} lg={6} md={12} xs={12}>
                                                                              <Card style={{
                                                                                    border: customRes ? `1px solid ${customRes?.button_colour}` : "1px solid #D0D5DD",
                                                                                    borderRadius: "8px",
                                                                                    marginBottom: "10px",
                                                                                    backgroundColor: customRes ? hexToRGBA(customRes?.button_colour, 0.07) : "#fff",
                                                                              }} className="method_card">
                                                                                    <Card.Body style={{ padding: 10 }}>
                                                                                          <Row>
                                                                                                <Col lg={2} xs={2} md={3}>
                                                                                                      <Fab style={{ background: customRes?.button_colour }}
                                                                                                            className="fad_icon" size="medium" aria-label="add">
                                                                                                            {method.icon}
                                                                                                      </Fab>
                                                                                                </Col>
                                                                                                <Col lg={10} xs={10} md={9} className="d-flex justify-content-between align-items-center">
                                                                                                      <div className="d-flex flex-column text-start">
                                                                                                            <div className="method_name">
                                                                                                                  {method.name}
                                                                                                                  {/* {method?.dateAndTime && (
                                                                                                                        <div className="method_date">
                                                                                                                              {method.dateAndTime}
                                                                                                                        </div>
                                                                                                                  )} */}
                                                                                                            </div>

                                                                                                      </div>
                                                                                                      <CheckmarkCircleFilled className="circle_icon text-end"
                                                                                                            style={{ color: customRes && customRes?.button_colour }} />
                                                                                                </Col>
                                                                                          </Row>
                                                                                    </Card.Body>
                                                                              </Card>
                                                                        </Col>
                                                                  ))
                                                                  :
                                                                  slot_type === "direct" ? methods?.filter((method) => method.name === "Pickup").map((method, index) => (
                                                                        <Col key={index} onClick={method.onClick} lg={6} md={12} xs={12}>
                                                                              <Card style={{
                                                                                    border: customRes ? `1px solid ${customRes?.button_colour}` : "1px solid #D0D5DD",
                                                                                    borderRadius: "8px",
                                                                                    marginBottom: "10px",
                                                                                    backgroundColor: customRes ? hexToRGBA(customRes?.button_colour, 0.07) : "#fff",
                                                                              }} className="method_card">
                                                                                    <Card.Body style={{ padding: 10 }}>
                                                                                          <Row>
                                                                                                <Col lg={2} xs={2} md={3}>
                                                                                                      <Fab style={{ background: customRes?.button_colour }}
                                                                                                            className="fad_icon" size="medium" aria-label="add">
                                                                                                            {method.icon}
                                                                                                      </Fab>
                                                                                                </Col>
                                                                                                <Col lg={10} xs={10} md={9} className="d-flex justify-content-between align-items-center">
                                                                                                      <div className="d-flex flex-column text-start">
                                                                                                            <div className="method_name">
                                                                                                                  {method.name}
                                                                                                                  {/* {method?.dateAndTime && (
                                                                                                                        <div className="method_date">
                                                                                                                              {method.dateAndTime}
                                                                                                                        </div>
                                                                                                                  )} */}
                                                                                                            </div>
                                                                                                      </div>


                                                                                                      <CheckmarkCircleFilled className="circle_icon text-end"
                                                                                                            style={{ color: customRes && customRes?.button_colour }} />


                                                                                                </Col>
                                                                                          </Row>
                                                                                    </Card.Body>
                                                                              </Card>
                                                                        </Col>
                                                                  )) :
                                                                        methods?.filter((method) => method.is_available && method.name !== "Dine In").map((method, index) => (
                                                                              <Col key={index} onClick={method.onClick} xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} >
                                                                                    <Card style={{
                                                                                          border: method?.Info ? `1px solid ${customRes?.button_colour}` : "1px solid #D0D5DD",
                                                                                          borderRadius: "8px",
                                                                                          marginBottom: "10px",
                                                                                          backgroundColor: method?.Info ? hexToRGBA(customRes?.button_colour, 0.07) : "#fff",
                                                                                    }} className="method_card">
                                                                                          <Card.Body style={{ padding: 10 }}>
                                                                                                <Row>
                                                                                                      <Col lg={2} xs={2} md={3}>
                                                                                                            <Fab style={{ background: method?.Info ? customRes?.button_colour : "#EAECF0" }}
                                                                                                                  className="fad_icon" size="medium" aria-label="add">
                                                                                                                  {method.icon}
                                                                                                            </Fab>
                                                                                                      </Col>
                                                                                                      <Col lg={10} xs={10} md={9} className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="d-flex flex-column text-start">
                                                                                                                  <div className="method_name">
                                                                                                                        {method.name}
                                                                                                                        {/* {method?.dateAndTime && (
                                                                                                                              <div className="method_date">
                                                                                                                                    {method.dateAndTime}
                                                                                                                              </div>
                                                                                                                        )} */}
                                                                                                                  </div>
                                                                                                            </div>

                                                                                                            {method?.Info ?
                                                                                                                  <CheckmarkCircleFilled className="circle_icon text-end"
                                                                                                                        style={{ color: method?.Info && customRes?.button_colour }} /> :
                                                                                                                  <CircleRegular style={{ color: "#D0D5DD" }} className="circle_icon text-end" />
                                                                                                            }
                                                                                                      </Col>
                                                                                                </Row>
                                                                                          </Card.Body>
                                                                                    </Card>
                                                                              </Col>
                                                                        ))}

                                                            {BranchDetails_?.is_delivery === false && BranchDetails_?.is_pickup === false && (
                                                                  <Row >
                                                                        <Col className="text-start error_message">
                                                                              Apologies, We aren’t providing delivery or pickup at the moment. We will start accepting orders after some time until then enjoy your meal at the restaurant.
                                                                        </Col>
                                                                  </Row>
                                                            )}
                                                      </Row>

                                                      {method &&
                                                            (method === "delivery" ? deliveryInfo : method === "pickup" ? pickupInfo : method === "dinein" ? dineinInfo : null)?.fullName ||
                                                            (method === "delivery" ? deliveryInfo : method === "pickup" ? pickupInfo : method === "dinein" ? dineinInfo : null)?.customerEmail ||
                                                            (method === "delivery" ? deliveryInfo : method === "pickup" ? pickupInfo : method === "dinein" ? dineinInfo : null)?.phone ? (
                                                            <Row className="mb-4 mt-3">
                                                                  <Col lg={12} xs={12} md={12}>
                                                                        <Card>
                                                                              <Card.Body>
                                                                                    <Row>
                                                                                          <Col lg={10} md={10} xs={10} className="mb-1 cus_title">
                                                                                                {method === "delivery"
                                                                                                      ? "Delivery Details: "
                                                                                                      : method === "pickup"
                                                                                                            ? "Pickup Details:"
                                                                                                            : method === "dinein"
                                                                                                                  ? "DineIn Details:"
                                                                                                                  : ""}
                                                                                          </Col>
                                                                                          <Col lg={2} md={2} xs={2} className="text-end">
                                                                                                <EditRegular
                                                                                                      onClick={() =>
                                                                                                            method === "delivery"
                                                                                                                  ? openDelivery()
                                                                                                                  : method === "pickup"
                                                                                                                        ? openPickup()
                                                                                                                        : openDineIn()
                                                                                                      }
                                                                                                      className="cus_edit_icon"
                                                                                                />
                                                                                          </Col>

                                                                                          <RenderCustomerInfo
                                                                                                info={
                                                                                                      method === "delivery"
                                                                                                            ? deliveryInfo
                                                                                                            : method === "pickup"
                                                                                                                  ? pickupInfo
                                                                                                                  : method === "dinein"
                                                                                                                        ? dineinInfo
                                                                                                                        : null
                                                                                                }
                                                                                                dataForDine={FinalAmout_BranchDetails}
                                                                                          />
                                                                                    </Row>
                                                                              </Card.Body>
                                                                        </Card>
                                                                  </Col>
                                                            </Row>
                                                      ) : null}

                                                      {/* <hr className="hr_ " /> */}
                                                      <Row>
                                                            {/* <Col lg={12} md={12} xs={12}>
                                                                  <div className="order_method mb-4">
                                                                        Promo Code
                                                                  </div>
                                                            </Col> */}

                                                            <Form className="mt-2" onSubmit={(TableNo ? FinalAmout_BranchDetails?.discount_amount : cartList_?.discount_amount) > 0
                                                                  ? Remove_Promocode : Check_Promocode}>
                                                                  <Row>
                                                                        <Col xxl={9} xl={9} lg={9} md={9} xs={9}>
                                                                              <Form.Control
                                                                                    required
                                                                                    id="Enter Promo/Coupon Code"
                                                                                    className="promo-input" // Moved inline styles to a class
                                                                                    placeholder="Enter Promo/Coupon Code"
                                                                                    disabled={(TableNo ? FinalAmout_BranchDetails?.discount_amount : cartList_?.discount_amount) > 0}
                                                                                    value={promocode}
                                                                                    onChange={(e) => setpromocode(e.target.value)}
                                                                                    style={{ height: 44 }}
                                                                              />

                                                                        </Col>
                                                                        <Col xxl={3} xl={3} lg={3} md={3} xs={3}>
                                                                              {RemovePromocodeLoader || ApplyPromocodeLoader ? (
                                                                                    <Box className="text-center">
                                                                                          <CircularProgress size={40} thickness={5} />
                                                                                    </Box>
                                                                              ) : (
                                                                                    <ButtonMUI style={{
                                                                                          backgroundColor: customRes?.button_colour ? hexToRGBA(customRes?.button_colour, 0.07) : "#dc3545",
                                                                                          color: customRes?.button_colour || "#fff"
                                                                                    }} className="coupon_button" id="promocode" type="submit">
                                                                                          {(TableNo ? FinalAmout_BranchDetails?.discount_amount : cartList_?.discount_amount) > 0 ? 'Remove' : 'Apply'}
                                                                                    </ButtonMUI>
                                                                              )}
                                                                        </Col>
                                                                        {/* <div className="mt-3">
                                                                              <Errormessage error={errorpromo} />
                                                                        </div> */}
                                                                  </Row>
                                                            </Form>
                                                      </Row>

                                                      <hr className="hr_ mt-4" />
                                                      <form onSubmit={handalCheckOut}>
                                                            <h4 className="billing_title mb-3">
                                                                  Billing Information
                                                            </h4>
                                                            {cartList_?.chargeInfo?.charges?.length > 0 && (
                                                                  <Row>
                                                                        <Col xs={12} md={12} lg={12}>
                                                                              <h6 className="order_summery mb-4">
                                                                                    <b>
                                                                                          Extra Charges
                                                                                    </b>
                                                                              </h6>
                                                                        </Col>
                                                                        <Col xs={12} md={12} lg={12}>
                                                                              <h6 className="order_summery mb-4">
                                                                                    <b>{cartList_?.chargeInfo && cartList_?.chargeInfo?.charges_heading_name_EN}</b>
                                                                              </h6>
                                                                        </Col>
                                                                        <Col xs={7} md={8} lg={8}>
                                                                              {cartList_?.chargeInfo?.charges?.map((main, index) => (
                                                                                    <div key={index} className="d-flex align-items-center">

                                                                                          {/* <Checkbox
                                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 34 } }}
                                                                                                style={{ padding: "0px", marginRight: "12px", color: "#F25042" }}
                                                                                                // disabled={main?.status === "collected"} // Disable checkbox if status is 'collected'
                                                                                                // checked={main?.is_active} // Assuming `isChecked` is a boolean value in the row data
                                                                                                onChange={(event) => handleChange(main, event)}// Assuming you're passing `index` as the row's key
                                                                                          />
                                                                                          <div className='extra_charges_label'>{main?.name_EN}</div> */}
                                                                                          <input
                                                                                                className="wp-comment-cookies-consent custom-checkbox"
                                                                                                type="checkbox"
                                                                                                onChange={(event) => handleChange(main, event)}
                                                                                                id="wp-comment-cookies-consent"
                                                                                                name="wp-comment-cookies-consent"
                                                                                          />
                                                                                          <label className="mx-1 mb-0 extra_charges_label">
                                                                                                {main?.name_EN}
                                                                                          </label>
                                                                                    </div>

                                                                              ))}

                                                                        </Col>
                                                                        <Col xs={5} md={4} lg={4} className="text-end">
                                                                              {cartList_?.chargeInfo?.charges?.map(
                                                                                    (main, index) => (
                                                                                          <div className="extra_charges_price notranslate" key={index}>
                                                                                                {currencyLabel} {Number(main.price).toFixed(2)}
                                                                                          </div>
                                                                                    )
                                                                              )}
                                                                        </Col>
                                                                  </Row>
                                                            )}
                                                            <hr className="mt-2 hr_" />

                                                            <Row>
                                                                  <Col xs={12} lg={12} md={12}>
                                                                        <h6 className="order_summery_re mb-3">
                                                                              <b>
                                                                                    Order Summary
                                                                              </b>
                                                                        </h6>
                                                                  </Col>
                                                                  <Col xs={6} lg={6} md={6}>
                                                                        <span className="order_summery">Subtotal</span>
                                                                  </Col>
                                                                  <Col xs={6} lg={6} md={6}>
                                                                        <span style={{ float: "right" }} className="notranslate extra_charges_price" >
                                                                              {currencyLabel}{" "}
                                                                              {Number(TableNo ? FinalAmout_BranchDetails?.final_amount || 0 : cartList_?.total_amount_without_Discount || 0).toFixed(2)}
                                                                        </span>
                                                                  </Col>
                                                                  {cartList_?.VAT_charges !== null &&
                                                                        cartList_?.VAT_charges !== undefined ? (
                                                                        <React.Fragment>
                                                                              <Col className="vat_charges" xs={6} lg={6}>
                                                                                    <span>Tax Charges</span>
                                                                              </Col>
                                                                              <Col xs={6} lg={6}>
                                                                                    <span className="vat_charges" style={{ float: "right" }}>
                                                                                          {currencyLabel} {Number(cartList_?.VAT_charges || 0).toFixed(2)}
                                                                                    </span>
                                                                              </Col>
                                                                        </React.Fragment>
                                                                  ) : null}

{(TableNo ? FinalAmout_BranchDetails?.discount_amount : cartList_?.discount_amount) > 0 && (
                                                                        <React.Fragment>
                                                                              <Col xs={6} lg={6}>
                                                                                    <span className="discont__" >
                                                                                          <b>Discount amount</b>
                                                                                    </span>
                                                                              </Col>
                                                                              <Col xs={6} lg={6}>
                                                                                    <span className="discont__" style={{ float: "right", }}>
                                                                                          <b>
                                                                                                {currencyLabel}{" "}
                                                                                                {Number(TableNo ? FinalAmout_BranchDetails?.discount_amount || 0 : cartList_?.discount_amount || 0).toFixed(2)}
                                                                                          </b>
                                                                                    </span>
                                                                              </Col>
                                                                        </React.Fragment>
                                                                  )}
                                                                  {cartList_?.delivery_cost > 0 && (
                                                                        <React.Fragment>
                                                                              <Col className="delivery_fees" xs={6} lg={6}>
                                                                                    <span>Delivery Fees</span>
                                                                              </Col>
                                                                              <Col xs={6} lg={6}>
                                                                                    <span className="delivery_fees" style={{ float: "right" }}>
                                                                                          {currencyLabel}{" "}
                                                                                          {Number(cartList_?.delivery_cost || 0).toFixed(2)}
                                                                                    </span>
                                                                              </Col>
                                                                        </React.Fragment>
                                                                  )}

                                                                 
                                                            </Row>
                                                            <hr className="mt-2 hr_" />


                                                            <Row>
                                                                  <Col xs={6} lg={6}>
                                                                        <h6 className="to_pay">
                                                                              <b>
                                                                                    Total Amount
                                                                              </b>
                                                                        </h6>
                                                                  </Col>
                                                                  <Col xs={6} lg={6}>
                                                                        <h5 className="to_pay notranslate" style={{ float: "right" }} >
                                                                              <b>
                                                                                    {currencyLabel}{" "}

                                                                                    {TableNo ? Number(FinalAmout_BranchDetails?.remaining_amount || 0).toFixed(2) : Number(Number(cartList_?.total_amount || 0) + Number(totalCost || 0)).toFixed(2)}
                                                                              </b>
                                                                        </h5>
                                                                  </Col>
                                                            </Row>


                                                            {filteredPaymentMethods?.filter((method) => method.is_available)?.length > 0 &&
                                                                  <Row>
                                                                        <Col lg={12} md={12} xs={12}>
                                                                              <div className="payment_method mb-4">
                                                                                    Payment Method
                                                                              </div>
                                                                        </Col>
                                                                        {filteredPaymentMethods?.filter((method) => method.is_available)?.map((method) => (
                                                                              <Col key={method.id} lg={6} md={12} xs={12} onClick={(e) => setpaymentmethod(method?.value)}>
                                                                                    <Card style={{
                                                                                          border: paymentmethod === method?.value ? `1px solid ${customRes?.button_colour}` : "1px solid #D0D5DD",
                                                                                          borderRadius: "8px",
                                                                                          marginBottom: "10px",
                                                                                          backgroundColor: paymentmethod === method?.value ? hexToRGBA(customRes?.button_colour, 0.07) : "#fff",
                                                                                    }} className="method_card">
                                                                                          <Card.Body style={{ padding: 10 }}>
                                                                                                <Row>
                                                                                                      <Col lg={2} xs={2} md={2}>
                                                                                                            <Fab style={{ background: paymentmethod === method?.value ? customRes?.button_colour : "#EAECF0" }}
                                                                                                                  className="fad_icon" size="medium" aria-label="add">
                                                                                                                  {method.icon}
                                                                                                            </Fab>

                                                                                                      </Col>
                                                                                                      <Col lg={10} xs={10} md={10} className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="method_name text-start">
                                                                                                                  {method.name}
                                                                                                            </div>
                                                                                                            {paymentmethod === method?.value ?
                                                                                                                  <CheckmarkCircleFilled className="circle_icon text-end"
                                                                                                                        style={{ color: paymentmethod === method?.value && customRes?.button_colour }} /> :
                                                                                                                  <CircleRegular style={{ color: "#D0D5DD" }} className="circle_icon text-end" />
                                                                                                            }
                                                                                                      </Col>
                                                                                                </Row>
                                                                                          </Card.Body>
                                                                                    </Card>
                                                                              </Col>
                                                                        ))}
                                                                  </Row>}

                                                            <hr className="hr_" />
                                                            <Row>
                                                                  <Col>


                                                                        <div className="d-flex align-items-center mb-2">
                                                                              <input
                                                                                    className="custom-checkbox"
                                                                                    type="checkbox"
                                                                                    checked={Terms_and_conditions}
                                                                                    value={Terms_and_conditions}
                                                                                    onChange={(e) => setTerms_and_conditions(!Terms_and_conditions)}
                                                                                    id="wp-comment-cookies-consent"
                                                                                    name="wp-comment-cookies-consent"
                                                                              />
                                                                              <label className="mx-1 mb-0"> {
                                                                                    (
                                                                                          <span className="extra_charges_label">
                                                                                                I confirm that I am 16 years or older and accept{" "}
                                                                                                <Link
                                                                                                      target="_blank"
                                                                                                      rel="noreferrer"
                                                                                                      onClick={() => window.open(`${cartList_?.items?.length > 0 && cartList_?.items[0]?.doc_url}`)}
                                                                                                >
                                                                                                      terms and conditions.
                                                                                                </Link>
                                                                                          </span>
                                                                                    )
                                                                              }</label>
                                                                        </div>


                                                                        <div className="d-flex align-items-center mb-4">
                                                                              <input
                                                                                    className="custom-checkbox"
                                                                                    type="checkbox"
                                                                                    checked={want_to_recieve_offers}
                                                                                    value={want_to_recieve_offers}
                                                                                    onChange={(e) => setwant_to_recieve_offers(!want_to_recieve_offers)}
                                                                                    id="wp-comment-cookies-consent"
                                                                                    name="wp-comment-cookies-consent"
                                                                              />
                                                                              <label className="mx-1 mb-0 _label">Email me with news and offers.</label>
                                                                        </div>


                                                                        <Errormessage error={checkOutEroor} />
                                                                        <div className="text-center">
                                                                              {loader === false ? (
                                                                                    <Button
                                                                                          variant="dark"
                                                                                          type="submit"
                                                                                          size="lg"
                                                                                          style={{
                                                                                                width: "100%",
                                                                                                backgroundColor: customRes?.button_colour ?? "#dc3545",
                                                                                                border: 0,
                                                                                          }}
                                                                                          className="custom_hover"
                                                                                    >
                                                                                          <strong
                                                                                                style={{ color: customRes?.non_highlighted_text ?? "white", }}
                                                                                          >
                                                                                                Pay {" "}
                                                                                                {TableNo ?
                                                                                                      <span className="notranslate">
                                                                                                            {currencyLabel} {Number(FinalAmout_BranchDetails?.remaining_amount || 0).toFixed(2)}
                                                                                                      </span> : <span className="notranslate">
                                                                                                            {currencyLabel} {Number(Number(cartList_?.total_amount || 0) + Number(totalCost || 0)).toFixed(2)}
                                                                                                      </span>}
                                                                                          </strong>
                                                                                    </Button>
                                                                              ) : (
                                                                                    <Box>
                                                                                          <CircularProgress
                                                                                                size={50}
                                                                                                thickness={5}
                                                                                          />
                                                                                    </Box>
                                                                              )}
                                                                        </div>
                                                                  </Col>
                                                            </Row>
                                                      </form>
                                                </Card.Body>
                                          </Card>
                                    </Col>
                                    <Col xxl={{ span: 5, order: 1 }}
                                          xl={{ span: 5, order: 1 }}
                                          lg={{ span: 6, order: 1 }}
                                          md={{ span: 6, order: 1 }}
                                          sm={{ span: 6, order: 1 }}
                                          xs={{ span: 12, order: 1 }} className="mb-2">
                                          <Card className="card_style mb-3">
                                                <Card.Body className="proDuct" >
                                                      {TableNo ?
                                                            CartProductItem_.map((main1) =>
                                                                  main1?.orderItemDetails?.map((main, index) => (
                                                                        <React.Fragment>
                                                                              <Row key={index}>
                                                                                    <Col xs={2} lg={2} md={2} className="">
                                                                                          <img
                                                                                                loading="lazy"
                                                                                                alt="Product_image"
                                                                                                src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                                                                                                className="product_img"
                                                                                          />
                                                                                    </Col>
                                                                                    <Col className="dis_p" xs={6} lg={6} md={6}>
                                                                                          <div className="product_name_cart">
                                                                                                {main?.quantity}x {main?.name_EN}

                                                                                                <div
                                                                                                      className="cart_product_chf_dd notranslate"
                                                                                                      style={{ marginBottom: "0px" }}
                                                                                                >
                                                                                                      {currencyLabel} {Number(main?.base_price).toFixed(2)}
                                                                                                </div>

                                                                                                {main?.options?.length > 0 &&
                                                                                                      <div className="cart_product_discription">
                                                                                                            {main?.options?.map((main22, index22) => (
                                                                                                                  <div className="options_Re" key={index22}>
                                                                                                                        + {main22?.name}
                                                                                                                  </div>
                                                                                                            ))}
                                                                                                      </div>
                                                                                                }


                                                                                                <div >
                                                                                                      {ErrorCheckOutTimeComper?.length > 0 && ErrorCheckOutTimeComper?.includes(main?.product_id)
                                                                                                            &&
                                                                                                            <React.Fragment>
                                                                                                                  <ErrorOutlineIcon style={{ color: "#facea8" }} />
                                                                                                                  <span className="Check_error_compare mx-1" >
                                                                                                                        {"This item is not available"}
                                                                                                                  </span>
                                                                                                            </React.Fragment>
                                                                                                      }
                                                                                                </div>

                                                                                                <React.Fragment>
                                                                                                      {main?.custom_property?.length > 0 &&
                                                                                                            main?.custom_property?.map(
                                                                                                                  (main2, index) => (
                                                                                                                        <div
                                                                                                                              className="cart_product_discription"
                                                                                                                              key={index}
                                                                                                                        >
                                                                                                                              {main2.ingredient}
                                                                                                                        </div>
                                                                                                                  )
                                                                                                            )
                                                                                                      }
                                                                                                </React.Fragment>
                                                                                          </div>

                                                                                    </Col>

                                                                                    <Col xs={4} lg={4} md={4} className="text-end" >
                                                                                          <p
                                                                                                className="cart_product_chf notranslate text-end"
                                                                                                style={{ marginBottom: "0px" }}
                                                                                          >
                                                                                                {currencyLabel} {Number(main?.total_price || 0).toFixed(2)}
                                                                                          </p>
                                                                                    </Col>
                                                                              </Row>
                                                                              <hr className="hr_" />


                                                                        </React.Fragment>
                                                                  )))
                                                            :
                                                            CartProductItem_.length > 0
                                                                  ? CartProductItem_.map((main, index) => (
                                                                        <React.Fragment>
                                                                              <Row key={index}>
                                                                                    <Col xs={2} lg={2} md={2} className="">
                                                                                          <img
                                                                                                loading="lazy"
                                                                                                alt="Product_image"
                                                                                                src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                                                                                                className="product_img"
                                                                                          />
                                                                                    </Col>
                                                                                    <Col xs={6} lg={6} md={6}>
                                                                                          <div className="product_name_cart">
                                                                                                {main?.name_EN}
                                                                                                {main?.options?.length > 0 &&
                                                                                                      <div className="cart_product_discription">
                                                                                                            {main?.options?.map((main22, index22) => (
                                                                                                                  <div className="options_Re" key={index22}>
                                                                                                                        + {main22?.name}
                                                                                                                  </div>
                                                                                                            ))}
                                                                                                      </div>}

                                                                                                <div >
                                                                                                      {ErrorCheckOutTimeComper?.length > 0 && ErrorCheckOutTimeComper?.includes(main?.product_id)
                                                                                                            &&
                                                                                                            <React.Fragment>
                                                                                                                  <ErrorOutlineIcon style={{ color: "#facea8" }} />
                                                                                                                  <span className="Check_error_compare mx-1" >
                                                                                                                        {"This item is not available"}
                                                                                                                  </span>
                                                                                                            </React.Fragment>
                                                                                                      }
                                                                                                </div>
                                                                                                <React.Fragment>
                                                                                                      {main?.custom_property?.length > 0 &&
                                                                                                            main?.custom_property?.map(
                                                                                                                  (main2, index) => (
                                                                                                                        <div
                                                                                                                              className="cart_product_discription"
                                                                                                                              key={index}
                                                                                                                        >
                                                                                                                              {main2.ingredient}
                                                                                                                        </div>
                                                                                                                  )
                                                                                                            )
                                                                                                      }
                                                                                                </React.Fragment>
                                                                                          </div>
                                                                                    </Col>
                                                                                    <Col xs={4} lg={4} md={4} className="text-end" >
                                                                                          <p
                                                                                                className="cart_product_chf notranslate text-end"
                                                                                                style={{ marginBottom: "0px" }}
                                                                                          >
                                                                                                {currencyLabel} {Number(main?.total_price).toFixed(2)}
                                                                                          </p>
                                                                                          {/* <Col
                                                                                          xs={12}
                                                                                          lg={12}
                                                                                          md={12}
                                                                                          className="text-end"
                                                                                    > */}
                                                                                          <ButtonGroup className="mt-2" size="small" variant="outlined" aria-label="outlined button group">
                                                                                                <ButtonMUI disabled={main.quantity === 0} className='ButtonGroup_Left'
                                                                                                      onClick={(e) => handleDecrement(e, main?._id, main?.quantity)}
                                                                                                >
                                                                                                      <RemoveIcon className='fs-6' />
                                                                                                </ButtonMUI>
                                                                                                <ButtonMUI className='ButtonGroup_center'><span className="ButtonGroup_Qut">{main?.quantity}</span></ButtonMUI>
                                                                                                <ButtonMUI className='ButtonGroup_Right'
                                                                                                      onClick={(e) => handleIncrement(e, main?._id, main?.quantity)}
                                                                                                >
                                                                                                      <AddIcon className='fs-6' />
                                                                                                </ButtonMUI>
                                                                                          </ButtonGroup>
                                                                                          {/* </Col> */}
                                                                                    </Col>
                                                                              </Row>
                                                                              <hr className="hr_" />
                                                                        </React.Fragment>
                                                                  ))
                                                                  : <h4 className="no_pro"> No product item found.</h4>}
                                                </Card.Body>

                                                <Card.Body style={{ marginTop: "-28px" }}>
                                                      <div className="text-center mt-2">
                                                            <ButtonMUI className="add_more_button" variant="outlined"
                                                                  onClick={() => {
                                                                        if (typeDirect && servicestaffid) {
                                                                              history.push(`/${BranchName_}/menu?type=${typeDirect}&servicestaffid=${servicestaffid}`)
                                                                        } else if (TableNo) {
                                                                              history.push(`/${BranchName_}/menu?no=${TableNo}&sessionId=${sessionId}`)
                                                                        } else {
                                                                              history.push(`/${BranchName_}/menu`)
                                                                        }
                                                                  }
                                                                  } startIcon={<AddCircleOutlineIcon />}>
                                                                  Add More Items
                                                            </ButtonMUI>
                                                      </div>
                                                      {!TableNo && showordernote && (
                                                            <hr className="hr_" />
                                                      )}

                                                      {!TableNo && showordernote && (
                                                            <Row >
                                                                  <InputGroup className="input_group">
                                                                        <InputGroup.Text className="Order_note_">
                                                                              <NotepadEditRegular className="Order_note_icon" />
                                                                        </InputGroup.Text>

                                                                        <Form.Control
                                                                              className="Order_notes_input"
                                                                              placeholder="Order Notes  (Optional)"
                                                                              aria-label="Order Comments"
                                                                              value={order_notes}
                                                                              onChange={(e) => setorder_notes(e.target.value)}
                                                                        />
                                                                  </InputGroup>
                                                            </Row>
                                                      )}
                                                </Card.Body>

                                          </Card>
                                    </Col>
                              </Row>

                              {/* </Col>
                              <Col lg={2} xs={1}>
                              {!isMobile && (
                                    <LeftSideSvg />
                              )}
                              </Col>

                             
                        </Row> */}
                        </Container>
                        <Footer />
                  </div>
                  <ToastContainer />

                  <DeliveryDetailsModel
                        showDelivery={showDelivery}
                        closeDelivery={closeDelivery}
                        onErrorCheckOutTimeComper={handleErrorCheckOutTimeComper}
                        totalAmount={Number(Number(cartList_?.total_amount_without_Discount) + Number(totalCost || 0)).toFixed(2)}
                        getCartList={getCartList}
                  />

                  <PickupDetailsModel
                        showPickup={showPickup}
                        closePickup={closePickup}
                        onErrorCheckOutTimeComper={handleErrorCheckOutTimeComper}
                        totalAmount={Number(Number(cartList_?.total_amount_without_Discount) + Number(totalCost || 0)).toFixed(2)}
                        getCartList={getCartList}
                  />

                  <DineInDetailsModel
                        showDineIn={showDineIn}
                        closeDineIn={closeDineIn}
                        onErrorCheckOutTimeComper={handleErrorCheckOutTimeComper}
                        totalAmount={Number(Number(cartList_?.total_amount_without_Discount) + Number(totalCost || 0)).toFixed(2)}
                        getCartList={getCartForDine}
                  />
            </div >
      );
}

const mapStateToProps = (state) => ({
      productList: state.product.productList,
      menuList: state.menu.menuList,
      branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
      branchDineInTimeData: state.branch.branchDineInTimeData,
      branchdetailsbyid: state.branch.branchdetailsbyid,
      charges: state.order.charges,
      branchId: state.branch.branchId,
      branchName: state.branch.branchName,
      type: state.branch.type,
      showordernote: state.order.showordernote,
      deliverycosterror: state.order.deliverycosterror,
      deliverycost: state.order.deliverycost,
      language: state.branch.language,
      branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
      branchLanguageData: state.branch.branchLanguageData,
      customRes: state.custom.customRes,
});

const mapDispatchToProps = (dispatch) => {
      return {
            setProductList: (data) => dispatch(setProductList(data)),
            checkout: () => dispatch(checkout()),
            getCharges: () => dispatch(getCharges()),
            setBranchId: (id) => dispatch(setBranchId(id)),
            setCustome: (url) => dispatch(setCustome(url)),
            getCustomData: () => dispatch(getCustomData()),
            setBranchName: (name) => dispatch(setBranchName(name)),
            setType: (type) => dispatch(setType(type)),
            setCheckoutData: (data) => dispatch(setCheckoutData(data)),
            checkOrderNotes: () => dispatch(checkOrderNotes()),
            setOrderCostData: (data) => dispatch(setOrderCostData(data)),
            getOrderCost: () => dispatch(getOrderCost()),
            setDeliveryError: (error) => dispatch(setDeliveryError(error)),
            setLanguage: (language) => dispatch(setLanguage(language)),
            getBranchDetails: () => dispatch(getBranchDetails()),
            setBranchPrimaryLanguage: (language) =>
                  dispatch(setBranchPrimaryLanguage(language)),
            getMenuList: () => dispatch(getMenuList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(RevampCardPage);
