import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./paymentsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import ButtonMUI from '@mui/material/Button';
import api from "../../CommonApi/axios";
import { connect } from "react-redux";
import Badge from '@mui/material/Badge';
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import QRCodeOrder from "../QRCODE/QRCodeOrder";
import { CheckmarkCircleRegular, ReceiptRegular } from '@fluentui/react-icons';
import { getCurrencyLabel } from "../CommonComponet/currencyUtils/currencyUtils";
import moment from "moment";
import OrderDetailsComponent from "./OrderDetailsComponent";
import PaymentProcessingCard from "./PaymentProcessingCard";


function PaymentSuccess(props) {
  const { customRes } = props;
  let history = useHistory();

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const TableNo = query.get("no");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const pathname = window.location.href;
  const pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  const url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  const orderID = pathnamesplits[5];
  const branchId = pathnamesplits[1];


  const [extractedString, setExtractedString] = useState('');
  let orderForQr = sessionStorage.getItem("orderForQr");


  useEffect(() => {
    const pay_unicID = pathnamesplits[6];
    const myString = pay_unicID;
    const extractedString = myString?.split('#')[0];

    // Update the state with the extracted string
    setExtractedString(extractedString);
  }, [pathnamesplits]);
  const Payment = sessionStorage.getItem("Payment");
  const [OrderDetails ,setOrderDetails] = useState("")

  const [abcd, setabcd] = useState(Payment ? Payment : false);

  const location = useLocation();

  // console.log("2" ,orderID)
  // console.log("1" ,orderID1)


  useEffect(() => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, [])


  useEffect(() => {
    if (orderID && Payment === null) {
      handleSubmit();
    }
  }, [orderID && Payment === null]);

  // console.log("paymenr" ,Payment === "true");
  // sessionStorage.setItem("Payment", true);

  useEffect(() => {
    if (Payment === "true" && (extractedString && branchId)) {
      getOrderDetails(extractedString , branchId);
    }
  }, [Payment === "true" && (extractedString && branchId)]);

  function handleSubmit() {
    if (orderID) {
      let item = {
        order_id: orderID,
        status: "success",
      };
      api
        .patch(`/client/order/updateOrderStatus`, item)
        .then((res) => {
          // console.log("success", res.data);
          sessionStorage.setItem("Payment", true);
          setabcd(true);
          // getOrderDetails(extractedString , branchId);
        })
        .catch((err) => {
          if (err.response) {
            // setabcd(true);
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
  }

  function getOrderDetails(extractedString , branchId) {
      api
        .get(`/client/order/getOrderDetails/${extractedString}/${orderForQr !== null ? orderForQr : "delivery"}/${branchId}`)
        .then((res) => {
          console.log("success", res?.data?.data[0]);
          setOrderDetails(res?.data?.data[0])
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
  }


  const downloadFile = (value) => {
    api
      .get(`/client/order/downloadOrderInvoice/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        // window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };


  return (
    <React.Fragment>
      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container fluid>
          {abcd === false ? (
            <PaymentProcessingCard />
            // <Row className="justify-content-sm-center my-5">
            //   <Col lg={4} md={4} xs={12} className=""></Col>
            //   <Col lg={4} md={4} xs={12} className="text-center">
            //     <Card style={card}>
            //       <Card.Body>
            //         <div>
            //           <img
            //             style={{ height: "100px", width: "100px" }}
            //             src="/assets/media/my/loadnig.gif"
            //             alt="loader"
            //           />
            //         </div>
            //         <Row className="text-center">
            //           <Card.Title className="header_success mt-3">
            //             Payment Processing
            //           </Card.Title>
            //         </Row>

            //         <Row>
            //           <Col lg={2}></Col>
            //           <Col lg={8}>
            //             <Card.Title className="payment_text_info mt-2">
            //               Please wait , we are confirming your payment and
            //               please donot press back & close window.
            //             </Card.Title>
            //           </Col>
            //           <Col lg={2}></Col>
            //         </Row>
            //       </Card.Body>
            //     </Card>
            //   </Col>
            //   <Col lg={4} md={4} xs={12} className=""></Col>
            // </Row>
          ) : (
            <OrderDetailsComponent
            OrderDetails={OrderDetails}
            extractedString={extractedString}
            TableNo={TableNo}
            // downloadFile={downloadFile}
            orderID={orderID}
            customRes={customRes}
            history={history}
            PaymentMethod={"Online"}
          />
            // <Row className="justify-content-sm-center my-2">

            //   {/* <div
            //     className="text-center"
            //     style={{ width: "580px" }}
            //   >
            //     <Container>
            //       <Card style={card}>
            //         <Card.Body>
            //           <Card.Title>
            //             <div lassName="mt-3 mb-4">
            //               <img
            //                 style={{ height: "80px" }}
            //                 src="/assets/media/my/check.png"
            //                 alt="success_png"
            //               />
            //             </div>
            //           </Card.Title>

            //           <Row className="text-center">
            //             <Card.Title className="header_success">
            //               Your order is confirmed
            //             </Card.Title>
            //           </Row>
            //           <Row className="text-center">
            //             <Card.Title className="payment_order_id mt-2">
            //               <strong>
            //                 Your order number : #{extractedString || ""}
            //               </strong>
            //             </Card.Title>
            //           </Row>
            //           <Row>
            //             <Col lg={2} xs={0} sm={0} md={2}></Col>
            //             <Col lg={8} xs={12}>
            //               <Card.Title className="payment_text_info mt-1">
            //                 The order confirmation invoice will be shared on
            //                 your registered email.
            //               </Card.Title>
            //             </Col>
            //             <Col lg={2} xs={0} sm={0} md={2}></Col>
            //           </Row>

            //           {orderForQr === "pickup" &&
            //             <QRCodeOrder OrderNumber={extractedString} />
            //           }
            //           <Row className="mx-4">
            //             <Col
            //               lg={6}
            //               xs={12}
            //               md={6}
            //               className={
            //                 isMobile == true ? "text-center" : "text-end"
            //               }
            //             >
            //               <Button
            //                 type="button"
            //                 style={commonButtonStyles}
            //                 className="my-4 custom_hover"
            //                 // onClick={() => setabcd(!abcd)}
            //                 onClick={() => {
            //                   downloadFile(orderID);
            //                 }}
            //               >
            //                 Download Receipt
            //               </Button>
            //             </Col>
            //             <Col
            //               lg={6}
            //               xs={12}
            //               md={6}
            //               className={
            //                 isMobile == true ? "text-center" : "text-start"
            //               }
            //             >
            //               <Button
            //                 style={{ ...commonButtonStyles }}
            //                 type="button"
            //                 className={
            //                   isMobile == true
            //                     ? "my-1 custom_hover"
            //                     : "my-4 custom_hover"
            //                 }
            //                 onClick={() => {
            //                   sessionStorage.removeItem("Payment");
            //                   history.push(`/`);
            //                   sessionStorage.removeItem("orderForQr");
            //                 }
            //                 }
            //               >
            //                 Back to Home
            //               </Button>
            //             </Col>
            //           </Row>
            //         </Card.Body>
            //       </Card>
            //     </Container>
            //   </div> */}

            //   <Col lg={8} md={8} xs={12} className="mb-3">
            //     <Card className="pay_card">
            //       <Card.Body>
            //         <Row>
            //           <Col lg={9} md={9} xs={8}>

            //             <div className="branch_name_pay"> {OrderDetails?.branch?.branch_name_EN} </div>
            //             <div className="branch_add_pay">{OrderDetails?.branch?.address}</div>
            //           </Col>
            //           <Col lg={3} md={3} xs={4} className="text-center">
            //             <QRCodeOrder
            //               OrderNumber={extractedString} />
            //           </Col>

            //         </Row>

            //         <div className="dettt">
            //           <CheckmarkCircleRegular className="icon_tic" style={{ marginRight: "10px" }} />
            //           <div>
            //             <div className="name_customer">Thank you, {OrderDetails?.name || "---"}</div>
            //             <div className="order_num_pay">Order number #{extractedString}</div>
            //           </div>
            //         </div>

            //         <hr />
            //         <Row>
            //           <div className="branch_name_pay mb-2"> Order Details </div>

            //           <Col lg={6} md={6} xs={6} className="mb-4">
            //             <div className="title_hed">Contact Details</div>
            //             <div className="cus_deteails">{OrderDetails?.name || "---"}</div>
            //             <div className="cus_deteails">{OrderDetails?.email || "---"}</div>
            //             <div className="cus_deteails">{OrderDetails?.phone_number || "---"}</div>

            //           </Col>
            //           <Col lg={6} md={6} xs={6} className="mb-4">
            //             <div className="title_hed">Payment Method</div>
            //             <div className="cus_deteails"> Online</div>

            //           </Col>

            //           <Col lg={6} md={6} xs={6} >
            //             <div className="title_hed">Delivery Address</div>
            //             <div className="cus_deteails"></div>
            //             <div className="cus_deteails">Leonemouth, Mexico</div>


            //           </Col>
            //           <Col lg={6} md={6} xs={6} >
            //             <div className="title_hed">Order Type & Time</div>
            //             <div style={{textTransform : "capitalize"}} className="cus_deteails">{OrderDetails?.order_type}</div>
            //             <div className="cus_deteails">{`${moment(OrderDetails?.order_date).format('MMM Do YY')} , ${OrderDetails?.order_time}`}
            //               {/* Nov 19th 24 10:36 AM */}
            //               </div>
            //           </Col>

            //         </Row>
            //         <hr />
            //         <Row >
            //           <Col lg={3} md={4} xs={12}>
            //             <ButtonMUI onClick={() => {
            //               downloadFile(orderID);
            //             }} className="download_rec_button" constiant="outlined"
            //               startIcon={<ReceiptRegular style={{ fontSize: 22 }} />}>
            //               Download Receipt
            //             </ButtonMUI>
            //           </Col>


            //           <Col lg={6} md={4} xs={12}></Col>
            //           <Col lg={3} md={4} xs={12} className="text-end">

            //             <ButtonMUI
            //               style={{
            //                 background: customRes ? customRes?.button_colour : "#F25042",
            //                 color: customRes ? customRes?.non_highlighted_text : "#fff"
            //               }}  onClick={() => {
            //                 sessionStorage.removeItem("Payment");
            //                 history.push(`/`);
            //                 sessionStorage.removeItem("orderForQr");
            //               }} className="back_to_home" constiant="outlined"
            //             >
            //               Back To Home 
            //             </ButtonMUI>
            //           </Col>
            //         </Row>

            //       </Card.Body>
            //     </Card>


            //   </Col>

            //   <Col lg={4} md={4} xs={12} className="mb-3">
            //     <Card className="card_style mb-3">
            //       <Card.Body className="proDuct" >
            //         {TableNo ?
            //           CartProductItem_.map((main1) =>
            //             main1?.orderItemDetails?.map((main, index) => (
            //               <React.Fragment>
            //                 <Row key={index}>
            //                   <Col xs={2} lg={2} md={2} className="">
            //                     <Badge badgeContent={4} color="primary">
            //                       <img
            //                         loading="lazy"
            //                         alt="Product_image"
            //                         src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
            //                         className="product_img"
            //                       />
            //                     </Badge>

            //                   </Col>
            //                   <Col className="" xs={6} lg={6} md={6}>
            //                     <h6 className="product_name_cart">
            //                       {main?.quantity}x {main?.name_EN}
            //                     </h6>
            //                     <p
            //                       className="cart_product_chf_dd notranslate"
            //                       style={{ marginBottom: "0px" }}
            //                     >
            //                       {currencyLabel} {Number(main?.base_price).toFixed(2)}
            //                     </p>
            //                     <div className="cart_product_discription">
            //                       {main?.options?.length > 0 && main?.options?.map((main22, index22) => (
            //                         <div className="options_Re" key={index22}>
            //                           + {main22?.name}
            //                         </div>
            //                       ))}
            //                     </div>



            //                     <React.Fragment>
            //                       {main?.custom_property?.length > 0 &&
            //                         main?.custom_property?.map(
            //                           (main2, index) => (
            //                             <div
            //                               className="cart_product_discription"
            //                               key={index}
            //                             >
            //                               {main2.ingredient}
            //                             </div>
            //                           )
            //                         )
            //                       }
            //                     </React.Fragment>
            //                   </Col>

            //                   <Col xs={4} lg={4} md={4} className="text-end" >
            //                     <p
            //                       className="cart_product_chf notranslate text-end"
            //                       style={{ marginBottom: "0px" }}
            //                     >
            //                       {currencyLabel} {Number(main?.total_price).toFixed(2)}
            //                     </p>
            //                   </Col>
            //                 </Row>
            //                 <hr className="hr_" />


            //               </React.Fragment>
            //             )))
            //           :
            //           OrderDetails && OrderDetails?.order_items.length > 0
            //             ? OrderDetails?.order_items.map((main, index) => (
            //               <React.Fragment>
            //                 <Row key={index}>
            //                   <Col xs={2} lg={2} md={2} className="">
            //                     <img
            //                       loading="lazy"
            //                       alt="Product_image"
            //                       src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
            //                       className="product_img"
            //                     />
            //                   </Col>
            //                   <Col className="" xs={6} lg={6} md={6}>
            //                     <h6 className="product_name_cart">
            //                       {main?.quantity}x {main?.name_EN}
            //                     </h6>
            //                     <p
            //                       className="cart_product_chf_dd notranslate"
            //                       style={{ marginBottom: "0px" }}
            //                     >
            //                       {currencyLabel} {Number(main?.base_price).toFixed(2)}
            //                     </p>
            //                     <div className="cart_product_discription">
            //                       {main?.options?.length > 0 && main?.options?.map((main22, index22) => (
            //                         <div className="options_Re" key={index22}>
            //                           + {main22?.name}
            //                         </div>
            //                       ))}
            //                     </div>



            //                     <React.Fragment>
            //                       {main?.custom_property?.length > 0 &&
            //                         main?.custom_property?.map(
            //                           (main2, index) => (
            //                             <div
            //                               className="cart_product_discription"
            //                               key={index}
            //                             >
            //                               {main2.ingredient}
            //                             </div>
            //                           )
            //                         )
            //                       }
            //                     </React.Fragment>
            //                   </Col>

            //                   <Col xs={4} lg={4} md={4} className="text-end" >
            //                     <p
            //                       className="cart_product_chf notranslate text-end"
            //                       style={{ marginBottom: "0px" }}
            //                     >
            //                       {currencyLabel} {Number(main?.total_price).toFixed(2)}
            //                     </p>

            //                   </Col>
            //                 </Row>
            //                 <hr className="hr_" />


            //               </React.Fragment>
            //             ))
            //             : <h4 className="no_pro"> No product item found.</h4>}

            //         <Row>
            //           <Col xs={12} lg={12} md={12}>
            //             <h6 className="order_summery_re mb-3">
            //               <b>
            //                 Order Summary
            //               </b>
            //             </h6>
            //           </Col>
            //           <Col xs={6} lg={6} md={6}>
            //             <span className="order_summery">Sub total amount</span>
            //           </Col>
            //           <Col xs={6} lg={6} md={6}>
            //             <span style={{ float: "right" }} className="notranslate extra_charges_price" >
            //               {currencyLabel}{" "}
            //               {Number(OrderDetails?.total_amount || 0).toFixed(2)}
            //             </span>
            //           </Col>

            //           {/* {(OrderDetails?.discount_amount) > 0 && ( */}
            //             <React.Fragment>
            //               <Col xs={6} lg={6}>
            //                 <span className="discont__pay" >
            //                   <b>Discount amount</b>
            //                 </span>
            //               </Col>
            //               <Col xs={6} lg={6}>
            //                 <span className="discont__amount" style={{ float: "right" }}>
            //                   <b>
            //                     {currencyLabel}{" "}
            //                     {Number(OrderDetails?.discount_amount || 0).toFixed(2)}
            //                   </b>
            //                 </span>
            //               </Col>
            //             </React.Fragment>
            //           {/* )} */}

            //             <Col xs={6} lg={6} md={6}>
            //             <span className="order_summery">Total amount</span>
            //           </Col>
            //           <Col xs={6} lg={6} md={6}>
            //             <span style={{ float: "right" }} className="notranslate extra_charges_price" >
            //               {currencyLabel}{" "}
            //               {Number(Number(OrderDetails?.total_amount) - Number(OrderDetails?.discount_amount)  || 0).toFixed(2)}
            //             </span>
            //           </Col>
            //         </Row>

            //       </Card.Body>
            //     </Card>
            //   </Col>
            // </Row>
          )}

        </Container>



      </div>

      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);