import { ORDER } from "../../actions";

const checkoutReducer = (state = null, action) => {
    switch (action.type) {
        case ORDER.SETCHECKOUTDATA:
            return action.data;
        default:
            return state;
    }
}

export default checkoutReducer;