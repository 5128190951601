import { BRANCH } from "../../actions";

const branchIdReducer = (state = null, action) => {
    switch (action.type) {
        case BRANCH.BRANCHID:
            return action.id;
        default:
            return state;
    }
}

export default branchIdReducer;