import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./giftsuccess.css";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import api from "../../CommonApi/axios";
import { showToastCommon } from "../CommonComponet/Toastify/Toastify";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";

function GiftVoucherANZpayment(props) {
      const { customRes } = props;

      const history = useHistory();
      const location = useLocation();
      const pathname = window.location.href;
      const pathnamesplits = pathname.replace(/(https?:\/\/)?(www.)?/i, "").split("/");
      const orderID = pathnamesplits[3]?.split('#')[0];
      const Payment = sessionStorage.getItem("Payment");

      // console.log("orderID", orderID)

      useEffect(() => {
            window.history.pushState(null, null, location.href);
            window.onpopstate = function (event) {
                  history.go(1);
            };
      }, [])

      const [loader, setloader] = useState(false);
      const [hostedCheckoutId, setHostedCheckoutId] = useState("");

      useEffect(() => {
            const queryParams = new URLSearchParams(location.search);
            const hostedCheckoutId = queryParams.get("hostedCheckoutId");
            if (hostedCheckoutId) {
                  setHostedCheckoutId(hostedCheckoutId);
            }
      }, [location.search]);

      // console.log("Payment", Payment);


      const [extractedString, setExtractedString] = useState('');
      const [abcd, setabcd] = useState(false);

      useEffect(() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            const pay_unicID = pathnamesplits[6];
            setExtractedString(pay_unicID?.split('#')[0]);
            window.history.pushState(null, null, location.href);
            window.onpopstate = function (event) {
                  history.go(1);
            };
      }, [orderID, Payment]);

      useEffect(() => {
            if (hostedCheckoutId) {
                  if (Payment === "success") {

                  } else {
                        handleSubmit();
                  }
            }
      }, [hostedCheckoutId])

      function handleSubmit() {
            setabcd(true);
            if (orderID) {
                  let item = { hosted_checkout_id: hostedCheckoutId, voucher_id: orderID };
                  api.patch(`/client/purchase_voucher/updateVoucherANZPaymentStatus`, item)
                        .then(() => {
                              sessionStorage.setItem("Payment", "success");
                              setabcd(false);
                        })
                        .catch((err) => {
                              if (err.response) {
                                    console.log("err", err?.response?.data?.error?.message)
                                    setabcd(false);
                                    showToastCommon(err?.response?.data?.error?.message);
                                    sessionStorage.setItem("Payment", "fail");
                              }
                        });
            }
      }


      function handleSubmitRetry() {
            setloader(true);
            let item = {
                  voucher_id: orderID
            };
            // console.log(item);
            api
                  .post(`/client/purchase_voucher/anzpaymentReInitialize`, item)
                  .then((res) => {
                        setloader(false);
                            console.log("res1", res?.data?.data?.redirectUrl);
                        if (res?.data?.data?.redirectUrl !== "") {
                              window.open(`${res?.data?.data?.redirectUrl}`, "_self");
                        }
                  })
                  .catch((err) => {
                        setloader(false);
                        if (err.response) {
                              showToastCommon(err?.response?.data?.error?.message)
                        }
                  });
      }

      const card = { border: 0, borderRadius: 0 };

      return (
            <React.Fragment>
                  <CommonHeader />
                  <div
                        style={{
                              backgroundColor: "#F0EEED",
                              minHeight: "100vh",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflowX: "hidden"
                        }}
                  >
                        <Container>
                              {abcd === true && (
                                    <Row className="justify-content-sm-center">
                                          <div className="text-center" style={{ width: "600px" }}>
                                                <Container>
                                                      <Card style={card}>
                                                            <Card.Body>
                                                                  <div>
                                                                        <img
                                                                              style={{ height: "100px", width: "100px" }}
                                                                              src="/assets/media/my/loadnig.gif"
                                                                              alt="loader"
                                                                        />
                                                                  </div>
                                                                  <Row className="text-center">
                                                                        <Card.Title className="header_success mt-3">
                                                                              Payment Processing
                                                                        </Card.Title>
                                                                  </Row>
                                                                  <Row>
                                                                        <Col lg={2}></Col>
                                                                        <Col lg={8}>
                                                                              <Card.Title className="payment_text_info mt-2">
                                                                                    Please wait, we are confirming your payment and please do not press back & close window.
                                                                              </Card.Title>
                                                                        </Col>
                                                                        <Col lg={2}></Col>
                                                                  </Row>
                                                            </Card.Body>
                                                      </Card>
                                                </Container>
                                          </div>
                                    </Row>
                              )}
                              {abcd === false && Payment === "success" &&
                                    (
                                          <Row className="justify-content-sm-center">
                                                <div
                                                      className="text-center"
                                                      style={{ width: "580px", backgroundColor: "#F0EEED" }}
                                                >
                                                      <Container>
                                                            <Card>
                                                                  <Card.Body>
                                                                        <Card.Title>
                                                                              <div lassName="mt-3 mb-4">
                                                                                    <img
                                                                                          style={{ height: "80px" }}
                                                                                          src="/assets/media/my/check.png"
                                                                                          alt="success_png"
                                                                                    />
                                                                              </div>
                                                                        </Card.Title>

                                                                        <Row>
                                                                              <Col lg={2}></Col>
                                                                              <Col lg={8}>
                                                                                    <Card.Title className="header_success">
                                                                                          Payment Successful
                                                                                    </Card.Title>
                                                                              </Col>
                                                                              <Col lg={2}></Col>
                                                                        </Row>
                                                                        <Row>
                                                                              <Col lg={2}></Col>
                                                                              <Col lg={8}>
                                                                                    <Card.Title className="payment_text_info mt-1">
                                                                                          Your Gift Voucher is on your way. Please check your email for more details.
                                                                                    </Card.Title>
                                                                              </Col>
                                                                              <Col lg={2}></Col>
                                                                        </Row>
                                                                        <Button
                                                                              type="button"
                                                                              style={{
                                                                                    backgroundColor:
                                                                                          customRes?.button_colour ?? "#dc3545",
                                                                                    border: 0,
                                                                              }}
                                                                              className="my-4 custom_hover"
                                                                              // onClick={() => setabcd(!abcd)}
                                                                              onClick={() => history.push(`/`)}
                                                                        >
                                                                              <strong
                                                                                    style={{
                                                                                          color: customRes?.non_highlighted_text ?? "white",
                                                                                    }}
                                                                              >
                                                                                    Back to Home{" "}
                                                                              </strong>
                                                                        </Button>
                                                                  </Card.Body>
                                                            </Card>
                                                      </Container>
                                                </div>
                                          </Row>
                                    )}
                              {abcd === false && Payment === "fail" &&
                                    <Row className="justify-content-sm-center">
                                    <div
                                      className="text-center"
                                      style={{ width: "600px", backgroundColor: "#F0EEED" }}
                                    >
                                      <Card>
                                        <Container>
                                          <Card.Body>
                                            <Card.Title>
                                              <div>
                                                <img
                                                  style={{ height: "80px" }}
                                                  src="/assets/media/my/cancel.png"
                                                  alt="payment_fail"
                                                />
                                              </div>
                                            </Card.Title>
                                            <Row>
                                              <Col lg={2}></Col>
                                              <Col lg={8}>
                                                <Card.Title className="header_success mt-3">
                                                  Payment Failed
                                                </Card.Title>
                                              </Col>
                                              <Col lg={2}></Col>
                                            </Row>
                        
                                            <Row>
                                              <Col lg={2}></Col>
                                              <Col lg={8}>
                                                <Card.Title className="payment_text_info mt-2">
                                                  We are sorry that you transaction failed please try
                                                  again.
                                                </Card.Title>
                                              </Col>
                                              <Col lg={2}></Col>
                                            </Row>
                        
                                            <Card.Text>
                                              {loader == true ? (
                                                <Box>
                                                  <CircularProgress size={50} thickness={5} />
                                                </Box>
                                              ) : (
                                                <Button
                                                  type="button"
                                                  style={{
                                                    backgroundColor:
                                                      customRes?.button_colour ?? "#dc3545",
                                                    border: 0,
                                                  }}
                                                  className="custom_hover my-4"
                                                  onClick={handleSubmitRetry}
                                                >
                                                  <strong
                                                    style={{
                                                      color: customRes?.non_highlighted_text ?? "white",
                                                    }}
                                                  >
                                                    Retry
                                                  </strong>
                                                </Button>
                                              )}
                        
                                              <Button
                                                type="button"
                                                style={{
                                                  backgroundColor:
                                                    customRes?.button_colour ?? "#dc3545",
                                                  border: 0,
                                                }}
                                                className="custom_hover my-4 mx-2"
                                                onClick={() => history.push(`/`)}
                                              >
                                                <strong
                                                  style={{
                                                    color: customRes?.non_highlighted_text ?? "white",
                                                  }}
                                                >
                                                  Go Back
                                                </strong>
                                              </Button>
                                            </Card.Text>
                                          </Card.Body>
                                        </Container>
                                      </Card>
                                    </div>
                                  </Row>
                  }
                        </Container>
                  </div>
                  <ToastContainer />
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      customRes: state.custom.customRes,
});

const mapDispatchToProps = (dispatch) => {
      return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftVoucherANZpayment);
