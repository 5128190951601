import { ORDER } from "../../actions";

const orderDataReducer = (state = [], action) => {
    switch (action.type) {
        case ORDER.SETORDERDATA:
            return [action.data, ...state]
        default:
            return state;
    }
}

export default orderDataReducer;