import React from 'react'

export default function LeftSideSvg() {
  return (
      <div
      style={{ overflow: "hidden" }}
      className="sc-86d55903-0 bcEVxw"
    >
        <svg
          enableBackground="new 0 0 24 24"
          viewBox="0 0 544 958"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          name="carouselFlame"
          // style={{
          //   position: "absolute",
          //   left: "194px",
          //   fill: "rgb(255, 233, 35)",
          //   height: "82%",
          //   width: "auto",
          //   top: "105px",
          // }}

          style={{
            position: "absolute",
            left: "194px",
            fill: "rgb(255, 233, 35)",
            height: "82%",
            width: "100%",
            top: "-67px",
          }}
        >
          <path d="M440.545 130.083C499.237 93.3976 509.641 28.2288 513.791 1.71369C513.84 1.39394 513.791 1.07418 513.643 0.791319C513.495 0.508458 513.261 0.27479 512.966 0.139509C512.67 0.00422755 512.35 -0.0326674 512.03 0.0288242C511.71 0.0903157 511.427 0.262492 511.217 0.508458C496.8 17.2711 478.947 30.75 458.853 40.0352C365.083 80.8902 378.023 180.15 383.687 207.944C384.45 211.769 385.804 211.732 386.555 207.883C393.734 172.008 419.836 146.12 440.533 130.071L440.545 130.083ZM146.663 949.876C145.875 950.331 145.272 951.02 144.915 951.844C144.57 952.68 144.496 953.59 144.718 954.464C144.939 955.337 145.444 956.112 146.146 956.677C146.848 957.243 147.722 957.551 148.621 957.575C453.3 969.627 624.33 723.514 470.55 443.727C440.914 389.615 401.441 340.102 357.806 273.297C332.825 235.013 295.075 119.568 363.593 55.9861C363.889 55.691 364.073 55.3097 364.135 54.9039C364.196 54.498 364.135 54.0799 363.938 53.7109C363.753 53.342 363.445 53.0468 363.076 52.8624C362.707 52.6779 362.288 52.6164 361.869 52.6779C165.735 88.2077 90.6544 240.301 89.7925 368.953C89.3 439.029 102.117 496.93 119.12 530.184C119.268 530.467 119.33 530.787 119.293 531.107C119.256 531.426 119.12 531.722 118.899 531.955C118.689 532.189 118.406 532.361 118.086 532.423C117.778 532.496 117.446 532.472 117.15 532.349C66.6701 512.438 36.1972 457.747 28.305 441.981C28.1326 441.526 27.8248 441.145 27.4308 440.874C27.0368 440.603 26.5566 440.456 26.0765 440.456C25.5963 440.456 25.1161 440.591 24.7221 440.862C24.3281 441.132 24.008 441.514 23.8356 441.956C16.6329 456.112 -4.53187 505.12 0.873226 583.436C10.6246 724.965 81.3217 761.306 155.676 830.583C221.866 892.234 175.092 933.015 146.663 949.864V949.876Z" />
        </svg>
    </div>
  )
}
