import React, { useState } from 'react'
import "./Header.css"
import { Dropdown, Nav } from 'react-bootstrap'
import { getCMSData, getCustomData, setCMS, setCustome } from "../Store";
import { connect } from "react-redux";
import { useIsMobile } from '../Customer/CommonComponet/MediaQueries/mediaQueries';

function Header({cmsRes , customRes}) {

  // console.log("customRes" ,customRes)
  const isMobile = useIsMobile();
  

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndexTitel, setHoveredIndexTitel] = useState(null);


  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const getMenuColor = (index) => {
    // Define your logic to determine the color dynamically based on index or other properties
    // For example:
    if (index === hoveredIndex) {
      return customRes?.menu_hover_colour || "#E1A523" ;
    } else {
      return  customRes?.menu_text_colour || "#212529";
    }
  };


  const getMenuColorTitle = (index) => {
    // Define your logic to determine the color dynamically based on index or other properties
    // For example:
    if (index === hoveredIndexTitel) {
      return customRes?.menu_hover_colour || "#E1A523" ;
    } else {
      return  customRes?.menu_text_colour || "#212529";
    }
  };

  const handleMouseEnterTitel = (index) => {
    setHoveredIndexTitel(index);
  };

  const handleMouseLeaveTitel = () => {
    setHoveredIndexTitel(null);
  };

  function formatLink(link) {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return 'http://' + link;
    }
    return link;
  }



  return (
    <React.Fragment>
      {cmsRes?.headerData?.length > 0 &&
        <Nav style={{display : isMobile && "none"}} className="me-auto justify-content-start flex-grow-1 pe-3">
            {cmsRes?.headerData?.length > 0 && cmsRes?.headerData?.map((main, index) => (
                <React.Fragment key={index}>
                  {main.sub_menu?.length > 0 ? (
                    <Dropdown drop="down-centered">
                    <Dropdown.Toggle className="MenuName mx-2" id={`dropdown-${index}`}
                     onMouseEnter={() => handleMouseEnterTitel(index)}
                     onMouseLeave={handleMouseLeaveTitel}
                    style={{ 
                      color: getMenuColorTitle(index) , 
                      background : "transparent" || "#fff",
                      border : 0
                    }}>
                      {main.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {main.sub_menu.map((subItem, subIndex) => (
                        <Dropdown.Item
                          key={subIndex}
                          href={formatLink(subItem.link)}
                          target={subItem.is_new_tab ? "_blank" : "_self"}
                          onMouseEnter={() => handleMouseEnter(subIndex)}
                          onMouseLeave={handleMouseLeave}
                          style={{ color: getMenuColor(subIndex) ,width: "300px" }}
                        >
                          {subItem.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  ) : (
                    <Nav.Link 
                    style={{ color: getMenuColorTitle(index) , marginTop : "-2px"}} 
                    onMouseEnter={() => handleMouseEnterTitel(index)} 
                    onMouseLeave={handleMouseLeaveTitel}
                    href={formatLink(main.link)}
                    target={main.is_new_tab ? "_blank" : "_self"} 
                    className="MenuName mx-3">
                      {main.name}
                    </Nav.Link>
                  )}
                </React.Fragment>
              ))}
        </Nav>
        }
    </React.Fragment>
  )
}


const mapStateToProps = (state) => ({
  cmsRes: state.custom.cmsRes,
  cmsUrl: state.custom.cmsUrl,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setCMS: (url) => dispatch(setCMS(url)),
    getCMSData: () => dispatch(getCMSData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
