import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
import "./Footer.css"
import { getCMSData, getCustomData, setCMS, setCustome } from "../Store";
import { connect } from "react-redux";
import { useHover } from "./FooterCommon/hoverStyles"; 
import { getColumnSize, getSrc } from "./FooterCommon/commonFile";

 function Footer({cmsRes, customRes}) {

  // console.log("cmsRes" ,cmsRes)
  const { hoveredIndex, handleMouseEnter, handleMouseLeave, getLinkStyle ,
    hoveredIndexSection3,
    handleMouseEnter3,
    handleMouseLeave3,
    getLinkStyle3 } = useHover();

  const [section1 ,setsection1] = useState([]);
  const [section2 ,setsection2] = useState([]);
  const [section3 ,setsection3] = useState([]);
  const [activeData ,setactiveData] = useState([])

  useEffect(()=>{
    const activeData = cmsRes?.footerData?.filter(item => item.is_active === true);
    setactiveData(activeData)
  },[cmsRes])

  useEffect(()=>{
    const activeData_ = cmsRes?.footerData?.filter(item => item.is_active === true);
    if (activeData_?.length > 0) {
      setsection1([]);
      setsection2([]);
      setsection3([]);
      activeData_?.forEach(item => {
        switch (item.section_number) {
          case 1:
            setsection1(item);
            break;
          case 2:
            setsection2(item);
            break;
          case 3:
            setsection3(item);
            break;
          default:
            break;
        }
      });
    }
  },[cmsRes]);


  const [textColor, setTextColor] = useState(customRes?.menu_text_colour || "#212529");

  const handleMouseEnterS2 = () => {
    setTextColor(customRes?.menu_hover_colour || "#E1A523"); // Example: Change text color to blue
  };

  const handleMouseLeaveS2 = () => {
    setTextColor(customRes?.menu_text_colour || "#212529");
  };


  const [textColors3, setTextColors3] = useState(customRes?.menu_text_colour || "#212529");

  const handleMouseEnterS3 = () => {
    setTextColors3(customRes?.menu_hover_colour || "#E1A523"); // Example: Change text color to blue
  };

  const handleMouseLeaveS3 = () => {
    setTextColors3(customRes?.menu_text_colour || "#212529");
  };



  return (
    <React.Fragment>
        {activeData?.length > 0 && 
        <div style={{overflowX : "hidden" , background : customRes?.header_footer_section_colour || "#fff"}}>
        <Row className="mt-5 mb-5">
          <Col lg={2} md={2} xs={1} sm={1}></Col>
          <Col lg={8} md={8} xs={10} sm={10}>
            <Row className="text-start" >
              {section1?.socials?.length > 0 &&
              <Col 
              lg={getColumnSize(activeData.length)}
               md={getColumnSize(activeData.length)} xs={12} sm={getColumnSize(activeData.length)}>
              <img
                className="image-input-wrapper w-125px h-125px"
                src={section1?.logo_url || "/assets/media/logos/Logo_Dark.png"}
                alt=""
                style={{borderRadius : "7px" , border  : "1px solid #e2dede"}}
                height={110}
                width={200}/>
                <Row className="mt-3 text-start">
                  <Col lg={9} md={9} xs={12} sm={12}>
                  <div className="mt-4 text-start footer_text"
                  style={{color : customRes?.menu_text_colour || "#212529" }}>
                  {section1?.description}
                  </div>
                  </Col>
                </Row>
                  <Row className="mt-3 text-start">
                    <Col lg={12} md={12} xs={12} sm={12}>
                    {section1?.socials?.length > 0 && section1?.socials.map((icon, index) => (
                        getSrc(icon.type , icon.link , customRes?.menu_text_colour , index)
                      ))}
                    </Col>
                  </Row>
              </Col>}

                {section2?.menu?.length > 0 &&
              <Col lg={getColumnSize(activeData.length)} md={getColumnSize(activeData.length)} xs={12} sm={getColumnSize(activeData.length)} className="text-start mobile_">
                <h5 className="Section2H"  
                onMouseEnter={() => handleMouseEnterS2()}
                  onMouseLeave={handleMouseLeaveS2} 
                style={{ color: textColor }}>
                    {section2?.title}
                </h5>
                {section2?.menu?.length > 0 && 
                   section2?.menu?.sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no))?.map((value, index) => (
                      <div key={index} className="Section_Div my-3">
                        {value.is_new_tab ? (
                          <a href={value.link} 
                          style={getLinkStyle(index, hoveredIndex, customRes?.menu_hover_colour || "#E1A523" ,customRes?.menu_text_colour || "#212529")}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          className="Section_Div" target="_blank" rel="noopener noreferrer">
                            {value.name}
                          </a>
                        ) : (
                          <a 
                          style={getLinkStyle(index, hoveredIndex, customRes?.menu_hover_colour || "#E1A523" ,customRes?.menu_text_colour || "#212529")}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                          className="Section_Div" href={value.link}>{value.name}</a>
                        )}
                      </div>
                    ))}
              </Col>}
              {section3?.menu?.length > 0 && 
              <Col lg={getColumnSize(activeData.length)} md={getColumnSize(activeData.length)} xs={12} sm={getColumnSize(activeData.length)} className="text-start mobile_">  
              <h5 onMouseEnter={() => handleMouseEnterS3()}
                  onMouseLeave={handleMouseLeaveS3} 
                style={{ color: textColors3 }} className="Section2H">{section3?.title}</h5>
              {section3?.menu?.length > 0 && 
                    section3?.menu?.sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no))?.map((value, index) => (
                      <div key={index} className="Section_Div my-3">
                        {value.is_new_tab ? (
                          <a  
                          style={getLinkStyle3(index, hoveredIndexSection3, customRes?.menu_hover_colour || "#E1A523" ,customRes?.menu_text_colour || "#212529")}
                          onMouseEnter={() => handleMouseEnter3(index)}
                          onMouseLeave={handleMouseLeave3}  
                          href={value.link} className="Section_Div" target="_blank" rel="noopener noreferrer">
                            {value.name}
                          </a>
                        ) : (
                          <a  
                          style={getLinkStyle3(index, hoveredIndexSection3, customRes?.menu_hover_colour || "#E1A523" ,customRes?.menu_text_colour || "#212529")}
                          onMouseEnter={() => handleMouseEnter3(index)}
                          onMouseLeave={handleMouseLeave3} 
                          className="Section_Div" href={value.link}>{value.name}</a>
                        )}
                      </div>
                ))}
                </Col>}
            </Row>
          </Col>
          <Col lg={2} md={2} xs={1} sm={1}></Col>
        </Row>
          </div>
        }
    <ScrollToTop smooth className="scrollToTopButton"  />
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  cmsRes: state.custom.cmsRes,
  cmsUrl: state.custom.cmsUrl,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,

});

const mapDispatchToProps = (dispatch) => {
  return {
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setCMS: (url) => dispatch(setCMS(url)),
    getCMSData: () => dispatch(getCMSData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
