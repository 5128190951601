import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DownloadIcon from "@mui/icons-material/Download";

import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import "./orderhistory.css";
import api from "../../CommonApi/axios";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import Footer from "../../Comman/Footer";

function OrderHistory(props) {
  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    customRes,
    branchDineInTimeData,
    orderData,
  } = props;

  const [OrderHistoryData, setOrderHistoryData] = useState([]);

  useEffect(() => {
    setOrderHistoryData(orderData);
  }, [orderData.length]);

  // console.log("OrderHistoryData", OrderHistoryData);

  const downloadFile = (value) => {
    // console.log("value", value);
    api
      .get(`/client/order/downloadOrderInvoice/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        // console.log("as", tableData);
        window.open(`${tableData}`);
        // window.location.href = `${tableData}`;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  ////////////////////////////// Drewer ////////////////////////////////

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "#F0EEED" ,minHeight: "100vh" }}>
      <CommonHeader/>
        <Container >
          <div>
            <h1 className="main_header my-4">Your Order Receipt</h1>
          </div>
          <Card style={{border :0 ,borderRadius  :0}}>
            <Container>
              <br />
              <Container className="mt-3">
                <Row>
                  {OrderHistoryData.length > 0 ? (
                    OrderHistoryData.map((main, index) => (
                      <Col lg={6} md={6}>
                        <Card style={{borderRadius  :0}} key={index} className="mb-4">
                          <Card.Body>
                            <Row className="mt-1">
                              <Col xs={9}>
                                <Card.Text>
                                  <strong className="order_type">
                                    OrderID#: {main?.orderId}
                                  </strong>
                                  <Card.Text className="date_and_time">
                                    {moment(main?.date).format("DD-MM-YYYY")}{" "}
                                    {main.time}
                                    <br />
                                    {main?.orderType === "pickup"
                                      ? "PickUp"
                                      : "Delivery"}
                                  </Card.Text>
                                </Card.Text>
                              </Col>
                              <Col xs={3}>
                                <Card.Text className="chf_text text-end">
                                  {/* CHF {main.total} */}
                                  <Button
                                    style={{
                                      backgroundColor:
                                        customRes?.button_colour ?? "#dc3545",
                                      border: 0,
                                    }}
                                    download
                                    onClick={() => downloadFile(main?.order_id)}
                                    type="button"
                                    className={"my-1 custom_hover"}
                                  >
                                    <strong
                                      style={{
                                        color:
                                          customRes?.non_highlighted_text ??
                                          "white",
                                      }}
                                    >
                                      <DownloadIcon />
                                    </strong>
                                  </Button>
                                </Card.Text>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col sx={12}>
                                <Card.Text className="date_and_time justify-content-sm-center text-start">
                                  {moment(main.date).format("DD-MM-YYYY")}{" "}
                                  {main.time}
                                </Card.Text>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}></Col>
                              <Card.Text className="id_order">
                                {main.orderType === "pickup"
                                  ? "PickUp"
                                  : "Delivery"}
                              </Card.Text>
                            </Row> */}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Container className="mb-5">
                      <br />
                      <Card
                        style={{
                          height: "130px",
                        }}
                      >
                        <Card.Title className="no_data_found text-center">
                          <h3>No receipt available.</h3>
                        </Card.Title>
                      </Card>
                    </Container>
                  )}
                </Row>
              </Container>
            </Container>
          </Card>
        </Container>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <Footer/>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
