import { PRODUCT } from "../../actions";

const productListReducer = (state = [], action) => {
    switch (action.type) {
        case PRODUCT.CLEARPRODUCTLIST:
            return [];
        case PRODUCT.PRODUCTLIST:
            return [...state, ...action.productList];
        default:
            return state;
    }
}

export default productListReducer