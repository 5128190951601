import React from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import moment from 'moment';
import { CheckmarkCircleRegular, ReceiptRegular } from '@fluentui/react-icons';
import QRCodeOrder from '../QRCODE/QRCodeOrder'; // Import your QRCode component
import "./paymentsuccess.css"
import { getCurrencyLabel } from '../CommonComponet/currencyUtils/currencyUtils';
import ButtonMUI from '@mui/material/Button';
import "./OrderDetailsComponent.css"
import api from '../../CommonApi/axios';
import { toast, ToastContainer } from 'react-toastify';
import Badge from '@mui/material/Badge';
import { useIsMobile } from '../CommonComponet/MediaQueries/mediaQueries';


const OrderDetailsComponent = ({

  OrderDetails,
  extractedString,
  orderID,
  customRes,
  history,
  PaymentMethod
}) => {

  const isMobile = useIsMobile();


  const renderOrderItems = () => {


    const items = OrderDetails?.order_items || [];
    if (!items.length) {
      return <h4 className="no_pro">No product item found.</h4>;
    }

    return items.map((main1, idx1) => (
      (main1?.orderItemDetails || [main1]).map((main, idx2) => (
        <React.Fragment key={`${idx1}-${idx2}`}>
          <Row>
            <Col xs={3} lg={3} md={2}>
              <div className="image_container">
                <img
                  loading="lazy"
                  alt="product_img_payment"
                  src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                  className="product_img_payment"
                />
                {Number(main?.quantity) &&
                  <Badge
                    badgeContent={main?.quantity}
                    max={99}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    overlap="circular"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: customRes?.button_colour || "#F25042",
                        color: customRes?.non_highlighted_text || "#fff",
                        top: isMobile ? "-26px" : "-32px",
                        right: 2,
                        fontSize: 12,
                        fontFamily: '"Calibri", "Arial", sans-serif',
                        height: isMobile ? 20 : 22,
                        maxWidth: 55
                      },
                    }}
                  />}
              </div>
            </Col>
            <Col className='dis_payment' xs={5} lg={5} md={6}>
              <div className="product_name_cart_">
                {main?.name_EN}
                {main?.options?.length > 0 && (
                  <div className="cart_product_discription">
                    <div className="options_Re">Addons : {main?.options?.length}</div>
                  </div>
                )}

                {main?.custom_property?.length > 0 && (
                  <div className="cart_product_discription">
                    {main.custom_property.map((prop, i) => (
                      <div key={i}>{prop.ingredient}</div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={4} lg={4} md={4} className="dis_payment1 text-end">
              <div className="cart_product_chf_pay notranslate">
                {getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(main?.total_price).toFixed(2)}
              </div>
            </Col>
          </Row>
          <hr className="hr_" />
        </React.Fragment>
      ))
    ));
  };

  const downloadFile = (value) => {

    const invoiceEndpoint = OrderDetails?.order_type === "dineIn"
      ? "downloadOrderInvoiceForDineIn"
      : "downloadOrderInvoice";

    api
      .get(`/client/order/${invoiceEndpoint}/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        // window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });

  };

  return (
    <>
      <ToastContainer />
      <Container >
        <Row className="justify-content-sm-center my-2">
          <Col xxl={7} xl={7} lg={7} md={7} xs={12} className="mb-3">
            <Card className="pay_card">
              <Card.Body>
                {OrderDetails &&
                  OrderDetails?.order_type === "dineIn" ?
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <div className="branch_name_pay">{OrderDetails?.branch?.branch_name_EN}</div>
                      <div className="branch_add_pay mb-4">{OrderDetails?.branch?.address}</div>
                    </Col>
                  </Row> :
                  <Row>
                    <Col lg={9} md={9} xs={8}>
                      <div className="branch_name_pay">{OrderDetails?.branch?.branch_name_EN}</div>
                      <div className="branch_add_pay">{OrderDetails?.branch?.address}</div>
                    </Col>
                    <Col lg={3} md={3} xs={4} className="text-center">
                      {OrderDetails &&
                        <QRCodeOrder OrderNumber={extractedString} />
                      }
                    </Col>
                  </Row>}
                {/* <Row 
                // className="dettt"
                >
                  <Col lg={2} md={2} xs={3}> <CheckmarkCircleRegular className="icon_tic" /></Col>
                  <Col lg={7} md={7} xs={9}>
                    <div className="name_customer">Thank you, {OrderDetails?.name ? `${OrderDetails?.name}!` : ""}</div>
                    <div className="order_num_pay">Order number {extractedString}</div>
                  </Col>

                </Row> */}
                <div className="dettt mt-1">
                  <CheckmarkCircleRegular className="icon_tic" style={{ marginRight: "10px" }} />
                  <div>
                    <div className="name_customer">Thank you, {OrderDetails?.name ? `${OrderDetails?.name}!` : ""}</div>
                    <div className="order_num_pay">Order number {extractedString}</div>
                  </div>
                </div>
                <hr />
                <Row>
                  <div className="branch_name_pay mb-2">Order Details</div>
                  <Col lg={6} md={6} xs={6} className="mb-4">
                    <div className="title_hed">Contact Details</div>
                    <div className="cus_deteails">{OrderDetails?.name || "---"}</div>
                    <div className="cus_deteails">{OrderDetails?.email || "---"}</div>
                    <div className="cus_deteails">{OrderDetails?.phone_number || "---"}</div>
                  </Col>
                  <Col lg={6} md={6} xs={6} className="mb-4">
                    <div className="title_hed">Payment Method</div>
                    <div className="cus_deteails">{PaymentMethod}</div>
                  </Col>
                  <Col lg={6} md={6} xs={6}>
                    <div className='title_hed'>
                      {`${OrderDetails?.order_type === "delivery" ? "Delivery"
                        : OrderDetails?.order_type === "pickup" ? "Pickup"
                          : "Dinein"} Address`}
                    </div>
                    {OrderDetails?.order_type === "delivery" ?
                      <>
                        <div className="cus_deteails">{OrderDetails?.address_line1} </div>
                        {OrderDetails?.address_line2 &&
                          <div className="cus_deteails">{OrderDetails?.address_line2} </div>
                        }
                      </>
                      :
                      <div className="cus_deteails">{OrderDetails?.branch?.address} </div>}
                  </Col>
                  <Col lg={6} md={6} xs={6}>
                    <div className="title_hed">Order Type & Time</div>
                    <div style={{ textTransform: "capitalize" }} className="cus_deteails">
                      {OrderDetails?.order_type}
                    </div>
                    <div className="cus_deteails">
                      {`${moment(OrderDetails?.order_date).format('MMM Do YY')} , ${OrderDetails?.order_time}`}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xxl={4} xl={4} lg={5} md={4} xs={12}>
                    <ButtonMUI
                      onClick={() => downloadFile(orderID)}
                      className="download_rec_button"
                    >
                      <ReceiptRegular style={{ fontSize: 22 }} />
                      <span className='mx-2' style={{ fontWeight: 700 }}>Download Receipt </span>
                    </ButtonMUI>
                  </Col>
                  <Col xxl={4} xl={4} lg={2} md={4} xs={12}></Col>
                  {OrderDetails?.order_type !== "dineIn" &&
                    <Col xxl={4} xl={4} lg={5} md={4} xs={12} className="text-end">
                      <ButtonMUI
                        style={{
                          background: customRes?.button_colour || "#F25042",
                          color: customRes?.non_highlighted_text || "#fff",
                        }}
                        onClick={() => {
                          sessionStorage.removeItem("Payment");
                          history.push(`/`);
                          sessionStorage.removeItem("orderForQr");
                        }}
                        className="back_to_home"
                      >
                        <span style={{ fontWeight: 800 }}>Back To Home </span>
                      </ButtonMUI>
                    </Col>}
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={5} xl={5} lg={5} md={5} xs={12} className="mb-3">
            <Card className="card_style mb-3">
              <Card.Body className="proDuctPayment">
                {renderOrderItems()}
              </Card.Body>
              <Card.Body style={{ marginTop: "-25px" }} >
                {OrderDetails &&
                 OrderDetails?.order_type === "delivery" ?
                  <Row className='mt-2'>
                    <Col xs={12} lg={12} md={12}>
                      <h6 className="order_summery_re mb-3"><b>Order Summary</b></h6>
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <span className="order_summery">Subtotal</span>
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <span className="notranslate extra_charges_price" style={{ float: "right" }}>
                        {getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(OrderDetails?.total_amount || 0).toFixed(2)}
                      </span>
                    </Col>
                    {OrderDetails?.discount_amount > 0 &&
                      <>
                        <Col xs={6} lg={6} md={6}>
                          <span className="discont__pay"><b>Discount Amount</b></span>
                        </Col>
                        <Col xs={6} lg={6} md={6}>
                          <span className="discont__amount" style={{ float: "right" }}>
                            <b>{getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(OrderDetails?.discount_amount || 0).toFixed(2)}</b>
                          </span>
                        </Col>
                      </>
                    }

                    {Number(OrderDetails?.delivery_cost) > 0 &&
                      <>
                        <Col xs={6} lg={6} md={6}>
                          <span className="order_summery">Delivery Cost</span>
                        </Col>
                        <Col xs={6} lg={6} md={6}>
                          <span className="extra_charges_price" style={{ float: "right" }}>
                            {getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(OrderDetails?.delivery_cost || 0).toFixed(2)}
                          </span>
                        </Col>
                      </>
                    }
                    <Col xs={6} lg={6} md={6}>
                      <span className="order_summery"><b>Total Amount</b></span>
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <span className="notranslate extra_charges_price" style={{ float: "right" }}>
                        {/* <b>
                          {getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(Number(OrderDetails?.total_amount - Number(OrderDetails?.discount_amount || 0)) + (OrderDetails?.delivery_cost || 0) || 0).toFixed(2)}
                        </b> */}
                        <b>
                        {getCurrencyLabel(OrderDetails?.branch?.currency)}{" "}
                        {Number(
                          (OrderDetails?.total_amount || 0) -
                          (OrderDetails?.discount_amount || 0) +
                          Number(OrderDetails?.delivery_cost || 0)
                        ).toFixed(2)}
                          </b>
                      </span>
                    </Col>
                  </Row> :

                  <Row className='mt-2'>
                    <Col xs={12} lg={12} md={12}>
                      <h6 className="order_summery_re mb-3"><b>Order Summary</b></h6>
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <span className="order_summery">Subtotal</span>
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <span className="notranslate extra_charges_price" style={{ float: "right" }}>
                        {getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(OrderDetails?.total_amount - Number(OrderDetails?.delivery_cost || 0)).toFixed(2)}
                      </span>
                    </Col>
                    {OrderDetails?.discount_amount > 0 &&
                      <>
                        <Col xs={6} lg={6} md={6}>
                          <span className="discont__pay"><b>Discount amount</b></span>
                        </Col>
                        <Col xs={6} lg={6} md={6}>
                          <span className="discont__amount" style={{ float: "right" }}>
                            <b>{getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(OrderDetails?.discount_amount || 0).toFixed(2)}</b>
                          </span>
                        </Col>
                      </>
                    }
                    <Col xs={6} lg={6} md={6}>
                      <span className="order_summery"><b>Total Amount</b></span>
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <span className="notranslate extra_charges_price" style={{ float: "right" }}>
                        <b>
                          {getCurrencyLabel(OrderDetails?.branch?.currency)} {Number(OrderDetails?.total_amount - (OrderDetails?.discount_amount || 0)).toFixed(2)}
                        </b>
                      </span>
                    </Col>
                  </Row>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetailsComponent;
