import { BRANCH } from "../../actions";

const branchListReducer = (state = [], action) => {
    switch (action.type) {
        case BRANCH.CLEARBRANCHLIST:
            return [];
        case BRANCH.BRANCHLIST:
            return [...action.branchlist];
        default:
            return state;
    }
}

export default branchListReducer