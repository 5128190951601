import { http, headerOptions } from "../core";
import { URL } from "./_urls";
import { get } from "lodash";

const apiAppBranchList = async (data) => {
  // console.log('url');
  const response = await http.post(URL.BRANCHLIST(), data);
  // console.log("apiAppBranchList", response);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiAppBranchTimeFilter = async (data) => {
  // console.log('url', URL.BRANCHTIMEFILTER());
  const response = await http.post(URL.BRANCHTIMEFILTER(), data);
  // console.log("res91", response);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiAppBranchDetails = async (id) => {
  // console.log('url', URL.BRANCHDETAILS(id));
  const response = await http.get(URL.BRANCHDETAILS(id), {});
  // console.log("res91", response);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

export { apiAppBranchList, apiAppBranchTimeFilter, apiAppBranchDetails };
