import { http } from "../core";
import { URL } from "./_urls";
import { get } from "lodash";

const apiCartCount = async (id) => {
  const response = await http.get(URL.CART(id));
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

export { apiCartCount };
