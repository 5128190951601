import React from 'react'

export default function ProductSideSvg({color}) {
  return (
    <React.Fragment> 
      <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flame"
    viewBox="0 0 356 916"
    aria-hidden="true"
    role="graphics-object"
    style={{
      position: "absolute",
      height: "50%",
      maxWidthdth: "50%",
      top: "25%",
      left: "0%",
      fill: color
       ||
      "rgb(244, 31, 139)",
      filter: "brightness(80%)",
    }}
  >
    <desc>
      A flame symbol. It does not have colours,
      needs to be passed a `fill`
    </desc>
    <mask
      id="mask0_3048_35351"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={356}
      height={916}
    >
      <rect
        width={356}
        height={916}
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_3048_35351)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8758 3.35527C213.555 58.1546 271.959 214.899 259.608 341.117C252.903 409.868 234.332 465.323 214.173 496.2C213.813 496.736 213.829 497.438 214.214 497.957C214.599 498.475 215.27 498.7 215.893 498.518C267.68 484.129 303.322 433.616 312.718 418.965C313.166 418.101 314.1 417.597 315.074 417.694C316.048 417.79 316.863 418.468 317.129 419.402C322.776 434.009 338.612 484.217 325.246 560.446C301.117 698.21 227.715 726.637 147.344 786.991C75.795 840.697 117.701 885.437 143.992 904.855C145.48 905.925 146.069 907.846 145.432 909.557C144.796 911.267 143.089 912.348 141.257 912.203C-160.246 893.003 -303.564 634.313 -123.325 375.667C-88.5646 325.635 -44.5854 281.113 5.26068 220.06C33.8009 185.072 82.8529 75.7359 21.8466 6.41669C21.3182 5.76589 21.2737 4.85141 21.7365 4.15321C22.1993 3.45501 23.0636 3.13262 23.8758 3.35527ZM-117.943 -62.99C-105.456 -45.0917 -89.2398 -30.0585 -70.3907 -18.9056C17.8325 30.6894 -5.10553 126.687 -13.5308 153.365C-14.6777 157.038 -16.0011 156.864 -16.354 153.015C-19.4675 120.101 -39.1125 93.8019 -56.8053 75.9032L-61.5684 71.2313C-115.649 29.2899 -119.222 -35.6559 -120.59 -62.0716C-120.632 -62.7179 -120.234 -63.3128 -119.618 -63.5267C-119.001 -63.7406 -118.317 -63.5212 -117.943 -62.99Z"
      />
    </g>
  </svg></React.Fragment>
  )
}
