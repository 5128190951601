import { BRANCH } from "../../actions";

const branchdeliverysettingsdataDataReducer = (state = null, action) => {
  switch (action.type) {
    case BRANCH.DELIVERYSETTINGSDATA:
      return action.DeliverySettingsData;
    default:
      return state;
  }
};

export default branchdeliverysettingsdataDataReducer;
