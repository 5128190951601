import React from "react";
import { Row, Col, Card } from "react-bootstrap";

const PaymentProcessingCard = () => {
  const cardStyle = {
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "20px",
  };

  return (
    <Row className="justify-content-sm-center my-5">
      <Col lg={4} md={4} xs={12}></Col>
      <Col lg={4} md={4} xs={12} className="text-center">
        <Card style={cardStyle}>
          <Card.Body>
            <div>
              <img
                style={{ height: "100px", width: "100px" }}
                src="/assets/media/my/loadnig.gif"
                alt="loader"
              />
            </div>
            <Row className="text-center">
              <Card.Title className="header_success mt-3">
                Payment Processing
              </Card.Title>
            </Row>
            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <Card.Title className="payment_text_info mt-2">
                  Please wait, we are confirming your payment. Please do not
                  press back or close the window.
                </Card.Title>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} md={4} xs={12}></Col>
    </Row>
  );
};

export default PaymentProcessingCard;
