// import { configureStore } from '@reduxjs/toolkit'
// import { rootReducer } from './reducers/root-reducer'
// export * from './actions';

// export default configureStore({
//   reducer: rootReducer,
// })
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { logger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { rootReducer } from "./reducers/root-reducer";
import { rootSaga } from "./saga";
export * from "./actions";

const persistConfig = {
  key: "root",
  storage,
};

const enhancers = [];
// Dev tools are helpful
const reduxDevTool = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === "function") {
  enhancers.push(reduxDevTool());
}

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);
const allMiddlewares = applyMiddleware(sagaMiddleware);
// const allMiddlewares = applyMiddleware(sagaMiddleware, logger);
let store = createStore(
  persistedReducer,
  !!enhancers.length ? compose(allMiddlewares, ...enhancers) : allMiddlewares
);
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor };
