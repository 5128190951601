import { MENU } from "./action-types";

const setMenuList = (data) => ({
  type: MENU.SETMENULIST,
  data,
});

const getMenuList = () => ({
  type: MENU.GETMENULIST,
});

export { setMenuList, getMenuList };
