export const branchData = (language, url) => {
  return {
    language: language,
    url: url,
  };
};

export const branchTimeData = (language, type, id) => {
  return {
    type: type,
    language: language,
    branch_id: id,
  };
};

export const menuData = (id) => {
  return {
    branch_id: id,
  };
};

export const cartCount = (id) => {
  return {
    cart_id: id,
  };
};

export const customData = (url) => {
  return {
    url: url,
  };
};

export const cmsData = (url) => {
  return {
    url: url,
  };
};

export const chargesData = (id, type) => {
  return {
    branch_id: id,
    type: type,
  };
};

export const orderNotesCheckData = (id, type) => {
  return {
    branch_id: id,
    order_type: type,
  };
};
