import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import "./paymentsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageIcon from "@mui/icons-material/Language";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Logo from "../../Comman/Logo/Logo";
import api from "../../CommonApi/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import QRCodeOrder from "../QRCODE/QRCodeOrder";
import { CheckmarkCircleRegular, ReceiptRegular } from '@fluentui/react-icons';
import { getCurrencyLabel } from "../CommonComponet/currencyUtils/currencyUtils";
import ButtonMUI from '@mui/material/Button';
import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import moment from "moment";
import OrderDetailsComponent from "./OrderDetailsComponent";
import PaymentProcessingCard from "./PaymentProcessingCard";



function PaymentSuccessDineIn(props) {
  const { customRes } = props;
  let history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let orderForQr = sessionStorage.getItem("orderForQr");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { poId } = useParams();
  const { tableId } = useParams();

  const pathname = window.location.href;
  const pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  const url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  const Branch_id = String(pathnamesplits[1])
  const TableNo = String(pathnamesplits[2])
  const unique_code = String(pathnamesplits[5]);
  const [OrderDetails, setOrderDetails] = useState("")


  // console.log("orderID" ,TableNo , unique_code ,Branch_id) 

  const Payment = sessionStorage.getItem("Payment");

  const [abcd, setabcd] = useState(Payment ? Payment : false);
  const [Button1, setButton1] = useState(false);

  const myString = unique_code;
  const extractedString = myString?.split('#')[0];

  console.log(extractedString);

  // useEffect(() => {
  //   handleSubmit();
  // }, []);

  useEffect(() => {
    if (extractedString && Payment === null) {
      handleSubmit();
    }
  }, [extractedString && Payment === null]);

  let storedItemPrintJSON = localStorage.getItem('itemPrint');

  // console.log("storedItemPrintJSON", storedItemPrintJSON)
  // sessionStorage.setItem("Payment", true);

  function handleSubmit() {
    let item = {
      unique_code: extractedString,
      table_number: TableNo,
      status: "success",
      branch_id: Branch_id
    };
    api
      .patch(`/client/order/updateDineInOrderStatus`, item)
      .then((res) => {

        setabcd(true);
        sessionStorage.setItem("Payment", true);
        //   console.log("success", res.data);
        if (storedItemPrintJSON !== null) {
          let itemPrint = {
            order_id: storedItemPrintJSON
          }

          // console.log("itemPrint", itemPrint)
          // getOrderDetails(extractedString && Branch_id);
          api.post("/client/order/print-order-summary", itemPrint).then((res) => {
            // console.log("res" ,res);
            localStorage.removeItem('itemPrint')
          }).catch((err) => {

          })
        }
      })
      .catch((err) => {
        if (err.response) {
          // console.log("err.response.message", err.response.data.error.message);
          setButton1(true);
          toast.error(err.response.data.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }


  useEffect(() => {
    if (Payment === "true" && (extractedString && Branch_id)) {
      getOrderDetails(extractedString , Branch_id);
    }
  }, [Payment === "true" && (extractedString && Branch_id)]);

  
  function getOrderDetails(extractedString ,Branch_id) {
      api
        .get(`/client/order/getOrderDetails/${extractedString}/dineIn/${Branch_id}`)
        .then((res) => {
          // console.log("success", res?.data?.data[0]);
          setOrderDetails(res?.data?.data[0])
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
  }

  const downloadFile = (value) => {
    api
      .get(`/client/order/downloadOrderInvoiceForDineIn/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        // window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const location = useLocation();

  useEffect(() => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, [])



  return (
    <React.Fragment>
      {/* <Navbar
        style={{height :75}}
        className="shadow-lg p-2 bg-body"
        collapseOnSelect
        expand="lg"
        constiant="dark"
      >
        <Container fluid>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <div className="my-2"></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container fluid>
          {abcd === false ? (
            <PaymentProcessingCard />
            // <Row className="justify-content-sm-center my-5">
            //   <Col lg={4} md={4} xs={12} className=""></Col>
            //   <Col lg={4} md={4} xs={12} className="text-center">
            //     <Card style={card}>
            //       <Card.Body>
            //         <div>
            //           <img
            //             style={{ height: "100px", width: "100px" }}
            //             src="/assets/media/my/loadnig.gif"
            //             alt="loader"
            //           />
            //         </div>
            //         <Row className="text-center">
            //           <Card.Title className="header_success mt-3">
            //             Payment Processing
            //           </Card.Title>
            //         </Row>

            //         <Row>
            //           <Col lg={2}></Col>
            //           <Col lg={8}>
            //             <Card.Title className="payment_text_info mt-2">
            //               Please wait , we are confirming your payment and
            //               please donot press back & close window.
            //             </Card.Title>
            //           </Col>
            //           <Col lg={2}></Col>
            //         </Row>
            //       </Card.Body>
            //     </Card>
            //   </Col>
            //   <Col lg={4} md={4} xs={12} className=""></Col>
            // </Row>
          ) : (
            <OrderDetailsComponent
              OrderDetails={OrderDetails}
              extractedString={extractedString}
              TableNo={TableNo}
              downloadFile={downloadFile}
              orderID={extractedString}
              PaymentMethod={"Online"}
              customRes={customRes}
              history={history}
            />
            // <Row className="justify-content-sm-center my-2">

            //   {/* <div
            //     className="text-center"
            //     style={{ width: "580px", backgroundColor: "#F0EEED" }}
            //   >
            //     <Container>
            //       <Card>
            //         <Card.Body>
            //           <Card.Title>
            //             <div lassName="mt-3 mb-4">
            //               <img
            //                 style={{ height: "80px" }}
            //                 src="/assets/media/my/check.png"
            //                 alt="success_png"
            //               />
            //             </div>
            //           </Card.Title>

            //           <Row>
            //             <Col lg={2}></Col>
            //             <Col lg={8}>
            //               <Card.Title className="header_success">
            //                 Thank you for your visit
            //               </Card.Title>
            //             </Col>
            //             <Col lg={2}></Col>
            //           </Row>
            //           <Row>
            //             <Col lg={2}></Col>
            //             <Col lg={8}>
            //               <Card.Title className="payment_order_id mt-2">
            //                 <strong>
            //                   Hope you had a great time.
            //                 </strong>
            //               </Card.Title>
            //             </Col>
            //             <Col lg={2}></Col>
            //           </Row>
            //           <Row>
            //             <Col lg={2}></Col>
            //             <Col lg={8}>
            //               <Card.Title className="payment_text_info mt-1">
            //                 To dine in again, please scan the QR Code. We look forward to serving you once more!
            //               </Card.Title>
            //             </Col>
            //             <Col lg={2}></Col>
            //           </Row>

            //           <Row>
            //             <Col
            //               lg={12}
            //               xs={12}
            //               md={12}
            //               className="text-center"
            //             >
            //               <Button
            //                 type="button"
            //                 style={{
            //                   backgroundColor:
            //                     customRes?.button_colour ?? "#dc3545",
            //                   border: 0,
            //                 }}
            //                 className="my-4 custom_hover"
            //                 // onClick={() => setabcd(!abcd)}
            //                 onClick={() => {
            //                   downloadFile(extractedString);
            //                 }}
            //               >
            //                 <strong
            //                   style={{
            //                     color:
            //                       customRes?.non_highlighted_text ?? "white",
            //                   }}
            //                 >
            //                   Download Receipt{" "}
            //                 </strong>
            //               </Button>
            //             </Col>

            //           </Row>
            //         </Card.Body>
            //       </Card>
            //     </Container>
            //   </div> */}

            //   <Col lg={8} md={8} xs={12} className="mb-3">
            //     <Card className="pay_card">
            //       <Card.Body>
            //         <Row>
            //           <Col lg={9} md={9} xs={8}>

            //             <div className="branch_name_pay"> {OrderDetails?.branch?.branch_name_EN} </div>
            //             <div className="branch_add_pay">{OrderDetails?.branch?.address}</div>
            //           </Col>
            //           <Col lg={3} md={3} xs={4} className="text-center">
            //             <QRCodeOrder
            //               OrderNumber={extractedString} />
            //           </Col>

            //         </Row>

            //         <div className="dettt">
            //           <CheckmarkCircleRegular className="icon_tic" style={{ marginRight: "10px" }} />
            //           <div>
            //             <div className="name_customer">Thank you, {OrderDetails?.name || "---"}</div>
            //             <div className="order_num_pay">Order number #{extractedString}</div>
            //           </div>
            //         </div>

            //         <hr />
            //         <Row>
            //           <div className="branch_name_pay mb-2"> Order Details </div>

            //           <Col lg={6} md={6} xs={6} className="mb-4">
            //             <div className="title_hed">Contact Details</div>
            //             <div className="cus_deteails">{OrderDetails?.name || "---"}</div>
            //             <div className="cus_deteails">{OrderDetails?.email || "---"}</div>
            //             <div className="cus_deteails">{OrderDetails?.phone_number || "---"}</div>

            //           </Col>
            //           <Col lg={6} md={6} xs={6} className="mb-4">
            //             <div className="title_hed">Payment Method</div>
            //             <div className="cus_deteails"> Online</div>

            //           </Col>

            //           <Col lg={6} md={6} xs={6} >
            //             <div className="title_hed">Delivery Address</div>
            //             <div className="cus_deteails"></div>
            //             <div className="cus_deteails">Leonemouth, Mexico</div>


            //           </Col>
            //           <Col lg={6} md={6} xs={6} >
            //             <div className="title_hed">Order Type & Time</div>
            //             <div style={{ textTransform: "capitalize" }} className="cus_deteails">{OrderDetails?.order_type}</div>
            //             <div className="cus_deteails">{`${moment(OrderDetails?.order_date).format('MMM Do YY')} , ${OrderDetails?.order_time}`}
            //               {/* Nov 19th 24 10:36 AM */}
            //             </div>
            //           </Col>

            //         </Row>
            //         <hr />
            //         <Row >
            //           <Col lg={3} md={4} xs={12}>
            //             <ButtonMUI onClick={() => {
            //               // downloadFile(orderID);
            //             }} className="download_rec_button" constiant="outlined"
            //               startIcon={<ReceiptRegular style={{ fontSize: 22 }} />}>
            //               Download Receipt
            //             </ButtonMUI>
            //           </Col>


            //           <Col lg={6} md={4} xs={12}></Col>
            //           <Col lg={3} md={4} xs={12} className="text-end">

            //             <ButtonMUI
            //               style={{
            //                 background: customRes ? customRes?.button_colour : "#F25042",
            //                 color: customRes ? customRes?.non_highlighted_text : "#fff"
            //               }} onClick={() => {
            //                 sessionStorage.removeItem("Payment");
            //                 history.push(`/`);
            //                 sessionStorage.removeItem("orderForQr");
            //               }} className="back_to_home" constiant="outlined"
            //             >
            //               Back To Home
            //             </ButtonMUI>
            //           </Col>
            //         </Row>

            //       </Card.Body>
            //     </Card>


            //   </Col>

            //   <Col lg={4} md={4} xs={12} className="mb-3">
            //     <Card className="card_style mb-3">
            //       <Card.Body className="proDuct" >
            //         {
            //           OrderDetails && OrderDetails?.order_items.length > 0
            //             ? OrderDetails?.order_items.map((main, index) => (
            //               <React.Fragment>
            //                 <Row key={index}>
            //                   <Col xs={2} lg={2} md={2} className="">
            //                     <img
            //                       loading="lazy"
            //                       alt="Product_image"
            //                       src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
            //                       className="product_img"
            //                     />
            //                   </Col>
            //                   <Col className="" xs={6} lg={6} md={6}>
            //                     <h6 className="product_name_cart">
            //                       {main?.quantity}x {main?.name_EN}
            //                     </h6>
            //                     <p
            //                       className="cart_product_chf_dd notranslate"
            //                       style={{ marginBottom: "0px" }}
            //                     >
            //                       {currencyLabel} {Number(main?.base_price).toFixed(2)}
            //                     </p>
            //                     <div className="cart_product_discription">
            //                       {main?.options?.length > 0 && main?.options?.map((main22, index22) => (
            //                         <div className="options_Re" key={index22}>
            //                           + {main22?.name}
            //                         </div>
            //                       ))}
            //                     </div>



            //                     <React.Fragment>
            //                       {main?.custom_property?.length > 0 &&
            //                         main?.custom_property?.map(
            //                           (main2, index) => (
            //                             <div
            //                               className="cart_product_discription"
            //                               key={index}
            //                             >
            //                               {main2.ingredient}
            //                             </div>
            //                           )
            //                         )
            //                       }
            //                     </React.Fragment>
            //                   </Col>

            //                   <Col xs={4} lg={4} md={4} className="text-end" >
            //                     <p
            //                       className="cart_product_chf notranslate text-end"
            //                       style={{ marginBottom: "0px" }}
            //                     >
            //                       {currencyLabel} {Number(main?.total_price).toFixed(2)}
            //                     </p>

            //                   </Col>
            //                 </Row>
            //                 <hr className="hr_" />


            //               </React.Fragment>
            //             ))
            //             : <h4 className="no_pro"> No product item found.</h4>}

            //         <Row>
            //           <Col xs={12} lg={12} md={12}>
            //             <h6 className="order_summery_re mb-3">
            //               <b>
            //                 Order Summary
            //               </b>
            //             </h6>
            //           </Col>
            //           <Col xs={6} lg={6} md={6}>
            //             <span className="order_summery">Sub total amount</span>
            //           </Col>
            //           <Col xs={6} lg={6} md={6}>
            //             <span style={{ float: "right" }} className="notranslate extra_charges_price" >
            //               {currencyLabel}{" "}
            //               {Number(OrderDetails?.total_amount || 0).toFixed(2)}
            //             </span>
            //           </Col>

            //           {/* {(OrderDetails?.discount_amount) > 0 && ( */}
            //           <React.Fragment>
            //             <Col xs={6} lg={6}>
            //               <span className="discont__pay" >
            //                 <b>Discount amount</b>
            //               </span>
            //             </Col>
            //             <Col xs={6} lg={6}>
            //               <span className="discont__amount" style={{ float: "right" }}>
            //                 <b>
            //                   {currencyLabel}{" "}
            //                   {Number(OrderDetails?.discount_amount || 0).toFixed(2)}
            //                 </b>
            //               </span>
            //             </Col>
            //           </React.Fragment>
            //           {/* )} */}

            //           <Col xs={6} lg={6} md={6}>
            //             <span className="order_summery">Total amount</span>
            //           </Col>
            //           <Col xs={6} lg={6} md={6}>
            //             <span style={{ float: "right" }} className="notranslate extra_charges_price" >
            //               {currencyLabel}{" "}
            //               {Number(Number(OrderDetails?.total_amount) - Number(OrderDetails?.discount_amount) || 0).toFixed(2)}
            //             </span>
            //           </Col>
            //         </Row>

            //       </Card.Body>
            //     </Card>
            //   </Col>
            // </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessDineIn);
