import { http } from "../core";
import { URL } from "./_urls";
import { get } from "lodash";

const apiGetCharges = async (data) => {
  const response = await http.post(URL.GETCHARGES(), data);
  // console.log("res", response);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.error.message", "");
    //     console"Response", response);
    // console.log("SGDSGERROR", errorMessage);
    // return errorMessage
    // throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiCheckout = async (data) => {
  // console.log("urlcheck", URL.CHECKOUT());
  const response = await http.post(URL.CHECKOUT(), data);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.error.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiGetCheckOrderNote = async (data) => {
  const response = await http.post(URL.CHECKORDERNOTES(), data);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.error.message", "");
    // console.log("ERRRORR", errorMessage);
    // throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiGetDeliveryCost = async (data) => {
  const response = await http.post(URL.DELIVERYCOST(), data);
  // console.log("responsekkk", response);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.error.message", "");
    //     console"Response", response);
    //     console"SGDSGERROR", errorMessage);
    // return errorMessage
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
export { apiGetCharges, apiCheckout, apiGetCheckOrderNote, apiGetDeliveryCost };
