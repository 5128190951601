import { Col } from "react-bootstrap";
import api from "../../../CommonApi/axios";
import { LocationRegular, CallRegular, MailRegular ,ClockRegular } from '@fluentui/react-icons';
import moment from "moment";


// cartActions.js
export const incrementQuantity = (event, item_id, quantity_, cart_id, getCartList, getCartForDine) => {
  event.preventDefault();

  const item = {
    item_id: item_id,
    quantity: Number(quantity_ + 1),
  };

  console.log("item", item, cart_id)

  if (cart_id) {
    api
      .patch(`/client/cart/item/${cart_id}`, item)
      .then((res) => {
        getCartList();
        getCartForDine();
        // setpromocode_discount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          // Handle error if needed
        }
      });
  }
};

export const decrementQuantity = (event, item_id, quantity_, cart_id, getCartList, getCartForDine) => {
  event.preventDefault();

  if (quantity_ === 1) {
    const item = {
      item_id: item_id,
    };

    api
      .delete(`/client/cart/item/${cart_id}`, { data: item })
      .then((res) => {
        getCartList();
        getCartForDine();

      })
      .catch((err) => {
        if (err.response) {
          // Handle error if needed
        }
      });
  } else {
    const item = {
      item_id: item_id,
      quantity: Number(quantity_ - 1),
    };

    if (cart_id) {
      api
        .patch(`/client/cart/item/${cart_id}`, item)
        .then((res) => {
          getCartList();
          getCartForDine();
          // Uncomment the line below if needed
          // setpromocode_discount(res.data.data);
        })
        .catch((err) => {
          if (err.response) {
            // Handle error if needed
          }
        });
    }
  }
};

export const hexToRGBA = (hex, alpha = 0.2) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const BranchDetails = ({ branchName, branchAddress }) => (
  <Col>
    {branchName && (
      <h4 className="branch_name" style={{ marginBottom: 0 }}>
        {branchName}
      </h4>
    )}
    {branchAddress && (
      <small className="branch_address">
        {branchAddress}
      </small>
    )}
  </Col>
);


export const RenderCustomerInfo = ({ info ,dataForDine }) => (
  <Col lg={12} md={12} xs={12}>
    {info?.type === "dinein" ?
     (
      <div className="method_date mt-1 mb-2">
        <ClockRegular className="cus_icon me-2" />
        {moment(dataForDine?.dine_in_start).format("DD/MM/YYYY")} {moment(dataForDine?.dine_in_start).format('h:mm A')}
      </div>) :
    info?.order_date && (
      <div className="method_date mt-1 mb-2">
        <ClockRegular className="cus_icon me-2" />
        {moment(info?.order_date).format("DD/MM/YYYY")} {info?.order_time}
      </div>
    )}
    {info?.fullName &&
      <span className="cus_name m-1 me-3">{info?.fullName}</span>
    }
    {info?.customerEmail &&
      <span className="cus_email me-3">
        <MailRegular className="cus_icon me-2" />
        {info?.customerEmail}
      </span>}
    {info?.phone &&
      <span className="cus_email">
        <CallRegular className="cus_icon me-2" />
        {info?.phone}
      </span>}
    {info?.address_line1 && (
      <div className="my-2 cus_email">
        <LocationRegular className="cus_icon me-2" />
        {info?.address_line1}
        {info?.address_line2 && `, ${info?.address_line2}`}
      </div>
    )}
  </Col>
);
