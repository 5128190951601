export const colourStylesHeader = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: 0,
  }),
  option: (base, state) => ({
    ...base,
    color: "#1e2022",
    backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
    cursor: "pointer",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const Start = <span className="mx-1" style={{color: "red", fontSize: 20 }}> * </span>