import React from 'react';
import "./NewFoodListPage.css";

function DescriptionToggleProdcutMobile({ description }) {

  // Strip HTML tags to get the text content
  const cleanedStr = description?.replace(/<[^>]*>/g, '')?.replace(/&nbsp;/g, ' ')?.replace(/&amp;/g, ' ');
  const textContent = cleanedStr
  const textLength = textContent?.length;

  // Truncate the description if it's longer than 140 characters
  const truncatedDescription = textLength >= 80 
    ? `${textContent?.substring(0, 80)}... more` 
    : textContent;


  // console.log("textLength", textLength === undefined);

  return (
    <div className= { textLength === undefined ?  "cZXofG__11_blank" : "sc-7000b91a-0 cZXofG__11_"} 
    // style={{margin : textLength === undefined ? "0px" : "7px 3px 13px -9px;"}}
    >
      
      <div className="text-overflow-mobile">{truncatedDescription}</div>
    </div>
  );
}

export default DescriptionToggleProdcutMobile;

