import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ButtonGroup, Collapse, ListItemButton, ListItemText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useHistory, useLocation } from "react-router-dom";
import api from "../CommonApi/axios";
import { List, ListItem } from "material-ui-core";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import "./SideBar.css"
import { getCMSData, getCustomData, setCMS, setCustome } from "../Store";
import { connect } from "react-redux";
import { generateStyles } from "./DaynamicSideBarColor";


function SideBar({ cmsRes ,customRes }) {
  let history = useHistory();
 
  // console.log("customRes", customRes)

  // const downloadFile = (value) => {
  //   window.open(`${value}`);
  // };

  const [terms_and_conditions, setterms_and_conditions] = useState("");
  const [privacy_policy, setprivacy_policy] = useState("");

  useEffect(() => {
    // Terms_and_conditions();
    // Privacy_policy();
  }, []);

  function Terms_and_conditions() {
    api
      .get(`/client/cms/terms_and_conditions`)
      .then((res) => {
        const tableData = res.data.data;
        setterms_and_conditions(tableData?.image);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  function Privacy_policy() {
    api
      .get(`/client/cms/privacy_policy`)
      .then((res) => {
        const tableData = res.data.data;
        setprivacy_policy(tableData?.image);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  let { search } = useLocation();

  const query = new URLSearchParams(search);

  //////////////////////////// Drewer ////////////////////////////////
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setOpenSubMenus([]);
    setSelectedIndex("");
  };

  const [openSubMenus, setOpenSubMenus] = useState(Array(3).fill(false));
  const [selectedIndex, setSelectedIndex] = React.useState("");


  const handleClick = (index) => {
    const updatedOpenSubMenus = [...openSubMenus];
    updatedOpenSubMenus[index] = !updatedOpenSubMenus[index];
    setOpenSubMenus(updatedOpenSubMenus);
    setSelectedIndex(index);
  };



  const dynamicProps = {
    menuHoverColor: customRes?.menu_hover_colour,
    drawerBackgroundColor: customRes?.header_footer_section_colour,
  };
  const useStyles = generateStyles(dynamicProps);
  const classes = useStyles();

  const [HoverIndex ,setHoverIndex] = useState(null);
  const [HoverIndexSubmenu ,setHoverIndexSubmenu] = useState(null);
  const [HoverIndexDefault ,setHoverIndexDefault] = useState(null);

  const getTextColor = (index) => {
    return HoverIndex === index ? customRes?.menu_hover_colour || '#D3921F' : customRes?.menu_text_colour || "black";
  };

  const getTextColorSubmenu = (subIndex) => {
    return HoverIndexSubmenu === subIndex  ? customRes?.menu_hover_colour || '#D3921F' : customRes?.menu_text_colour || "black";
  };

  const getTextColorDefault = (subIndex) => {
    return HoverIndexDefault === subIndex  ? customRes?.menu_hover_colour || '#D3921F' : customRes?.menu_text_colour || "black";
  };

  const [HoverIndexMenu ,setHoverIndexMenu] = useState(false)
  const getTextColorMenu = () => {
    return HoverIndexMenu ? customRes?.menu_hover_colour || '#D3921F' : customRes?.menu_text_colour || "black";
  };

  function formatLink(link) {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return 'http://' + link;
    }
    return link;
  }


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ overflowX: "hidden" }}
    >
      <Container className="mt-3">
        <Row  onMouseEnter={() => setHoverIndexMenu(true)}
                onMouseLeave={() => setHoverIndexMenu(null)} >
          <Col lg={8} md={8} xs={8} sm={8}>
            <h5  style={{color : getTextColorMenu()}} className="mt-1 me-1">Menu</h5>
          </Col>
          <Col lg={4} md={4} xs={4} sm={4} className="text-end">
            <ClearIcon onClick={
              toggleDrawer(anchor, false)
            } 
            style={{ cursor: "pointer" ,color : getTextColorMenu() }} />
          </Col>
        </Row>
      </Container>


      <List>
        {cmsRes?.headerData?.length > 0 && cmsRes?.headerData?.map((menuItem, index) => (
          <React.Fragment key={index}>
            <ListItem disablePadding>
              {menuItem.sub_menu.length > 0 ? (
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() => handleClick(index)}>
                  <ListItemText>
                  <Row onMouseEnter={() => setHoverIndex(index)} 
                  onMouseLeave={() => setHoverIndex(null)}>
                  <Col
                  className={"SideTitle"} 
                  style={{ color: getTextColor(index) }} 
                  lg={10} md={10} sm={10} xs={10}>{menuItem.name}
                  </Col>
                    <Col lg={2} md={2} sm={2} xs={2} 
                    style={{ color: getTextColor(index) }}>{menuItem.sub_menu.length > 0 ? (
                    openSubMenus[index] ? <ExpandLess  /> : <ExpandMore />
                  ) : null}</Col>
                  </Row>                  
                    </ListItemText>
                </ListItemButton>
              ) : (
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() => handleClick(index)}
                  component="a"
                  href={formatLink(menuItem.link)}
                  target={menuItem.is_new_tab ? "_blank" : "_self"}
                >
                <ListItemText>
                  <Row onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
                  <Col className={"SideTitle__"} 
                  style={{ color: getTextColor(index) }} 
                  lg={10} md={10} sm={10} xs={10}>{menuItem.name}
                  </Col>
                    <Col lg={2} md={2} sm={2} xs={2} 
                    style={{ color: getTextColor(index) }}>{menuItem.sub_menu.length > 0 ? (
                    openSubMenus[index] ? <ExpandLess  /> : <ExpandMore />
                  ) : null}</Col>
                  </Row>                  
                  </ListItemText>
              </ListItemButton>
              )}
            </ListItem>
            <Collapse in={openSubMenus[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              {menuItem.sub_menu
                    .sort((a, b) => a.seq_no - b.seq_no)
                    .map((subMenuItem, subIndex) => (
                      <ListItemButton
                        key={subIndex}
                        sx={{ pl: 4 }}
                        component="a"
                        href={formatLink(subMenuItem.link)}
                        target={subMenuItem.is_new_tab ? "_blank" : "_self"}
                      >
                        <ListItemText>
                        <Row className="Side_sub_menu"
                          onMouseEnter={() => setHoverIndexSubmenu(subIndex)}
                          onMouseLeave={() => setHoverIndexSubmenu(null)}
                          style={{ color: getTextColorSubmenu(subIndex) }}>
                          {subMenuItem.name}
                        </Row>
                         </ListItemText >
                      </ListItemButton>
                    ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
      <hr />
    </Box>
  );

  return (
    <React.Fragment>
      <MenuIcon
        style={{
          fontSize: 30,
          color: customRes?.menu_text_colour || "grey",
          display: "inline-block",
          cursor: "pointer",
          marginLeft : "7"
        }}
        onClick={toggleDrawer("left", true)}
      />
      <SwipeableDrawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        classes={{
          paper: classes.drawer, // Apply custom styles to the paper element
        }}
      >
        {list("left")}
      </SwipeableDrawer>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  cmsRes: state.custom.cmsRes,
  cmsUrl: state.custom.cmsUrl,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setCMS: (url) => dispatch(setCMS(url)),
    getCMSData: () => dispatch(getCMSData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
