import { ORDER } from "./action-types";

const getCharges = () => ({
    type: ORDER.GETCHARGES
});

const checkout = () => ({
    type: ORDER.CHECKOUT
});

const checkOrderNotes = () => ({
    type: ORDER.GETCHECKORDERNOTERS
});

const getOrderCost = () => ({
    type: ORDER.GETORDERDELIVERYCOST
});

const setChargesData = (data) => ({
    type: ORDER.SETCHARGES,
    data
});

const clearChargesData = () => ({
    type: ORDER.CLEARCHARGES
});

const setCheckoutData = (data) => ({
    type: ORDER.SETCHECKOUTDATA,
    data
});

const setOrderCostData = (ordercostdata) => ({
    type: ORDER.SETORDERCOSTDATA,
    ordercostdata
});

const setShowOrderNotes = (value) => ({
    type: ORDER.SHOWORDERNOTE,
    value
});

const setDeliveryError = (error) => ({
    type: ORDER.SETDELIVERYERROR,
    error
});

const setDeliveryCost = (value) => ({
    type: ORDER.SETDELIVERYCOST,
    value
});

const setOrderData = (data) => ({
    type: ORDER.SETORDERDATA,
    data
});

export {
    getCharges,
    checkout,
    setChargesData,
    setCheckoutData,
    checkOrderNotes,
    getOrderCost,
    setOrderCostData,
    setShowOrderNotes,
    clearChargesData,
    setDeliveryError,
    setDeliveryCost,
    setOrderData
}