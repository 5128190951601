import { call, put, select, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import {
  apiCheckout,
  apiGetCharges,
  apiGetCheckOrderNote,
  apiGetDeliveryCost,
} from "../../api";
import { chargesData, orderNotesCheckData } from "../../core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  clearProductList,
  ORDER,
  setChargesData,
  setDeliveryCost,
  setDeliveryError,
  setShowOrderNotes,
  clearChargesData,
  setOrderData,
} from "../actions";
import $ from "jquery";
import NewWindow from "react-new-window";
window.jquery = window.$ = $;

const id = (state) => state.branch.branchId;
const type = (state) => state.branch.type;
const checkoutData = (state) => state.order.checkoutData;
const ordercostdata = (state) => state.order.ordercostdata;

function* checkoutOrder() {
  try {
    const data = yield select(checkoutData);
    // console.log("checkdataRedux", data);
    yield put(setOrderData(data));
    $(".loader").show();
    // yield Swal.fire({
    //   title: "Please wait a momnet.",
    //   showConfirmButton: false,
    // });
    return;
    const response = yield call(apiCheckout, data);
    // console.log("Response", response.data);
    if (response) {
      yield put(clearProductList());
      yield put(setDeliveryCost(""));
      let data_ = {
        date: data?.order_date,
        time: data?.order_time,
        orderType: data?.order_type,
        total: data?.total_amount,
        orderId: response?.data?.order_id,
      };
      // console.log("orderData", data_);
      yield put(setOrderData(data_));
      if (response.data?.paymentRes?.RedirectUrl != undefined) {
        // yield Swal.fire({
        //   title: "Please wait a momnet.",
        //   showConfirmButton: true,
        // });
        // window.location.replace(`${response.data?.paymentRes?.RedirectUrl}`);
        window.open(`${response.data?.paymentRes?.RedirectUrl}`, "_self");
      } else {
        $(".loader").hide();
        yield Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Order placed succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  } catch (error) {
    $(".loader").hide();
    toast.error(error.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // console.log("checkoutorder", error);
  }
}

function* getCharges() {
  try {
    const id_ = yield select(id);
    const type_ = yield select(type);
    // console.log("id456", id_);
    // console.log("type456", type_);
    const data = chargesData(id_, type_);
    const response = yield call(apiGetCharges, data);
    // console.log("responsecharge", response);
    if (response?.data) {
      // console.log("SDGsgsdgdsgsgs", response);
      yield put(setChargesData(response.data));
    } else {
      yield put(setChargesData({}));
      // console.log("SDGsgsdgdsgsgs2", response);
    }
  } catch (error) {
    // console.log("checkoutorder", error);
    if (error === "Error: No Data found.") {
      // console.log("checkoutorder", error);
      yield put(setChargesData([]));
    }
  }
}

function* getCheckOrderNotes() {
  try {
    const id_ = yield select(id);
    const type_ = yield select(type);
    // console.log("id457", id_);
    // console.log("type457", type_);
    const data = yield orderNotesCheckData(id_, type_);
    const response = yield call(apiGetCheckOrderNote, data);
    // console.log("responsenote", response);
    if (response?.data) {
      // console.log("dgfdg", response.data);
      yield put(setShowOrderNotes(true));
    } else {
      yield put(setShowOrderNotes(false));
    }
  } catch (error) {
    // console.log("checkordernote", error);
  }
}

function* getOrderDeliveryCost() {
  try {
    const data = yield select(ordercostdata);
    // console.log("checkdataordercost", data);
    // $('.loader').show();
    const response = yield call(apiGetDeliveryCost, data);
    yield put(setDeliveryError(""));
    // console.log("checkdataordercost1", response);
    if (response) {
      yield put(setDeliveryCost(response?.data?.delivery_cost));
    }
  } catch (error) {
    if (error == "Error: No zone found.") {
      // console.log("checkordercost3", error);
      yield put(setDeliveryCost(""));
      yield put(setDeliveryError("Does not deliver to this location."));
    }
  }
}

export function* watchChckoutOrder() {
  yield takeEvery(ORDER.CHECKOUT, checkoutOrder);
}

export function* watchCharges() {
  yield takeEvery(ORDER.GETCHARGES, getCharges);
}

export function* watchOrderNotesRequired() {
  yield takeEvery(ORDER.GETCHECKORDERNOTERS, getCheckOrderNotes);
}

export function* watchOrderDeliveryCost() {
  yield takeEvery(ORDER.GETORDERDELIVERYCOST, getOrderDeliveryCost);
}
