import { BRANCH } from "../../actions";

const branchLanguagesDataReducer = (state = ["en", "it", "de", "fr"], action) => {
    switch (action.type) {
        case BRANCH.BRANCHLANGUAGES:
            return action.languagelist;
        default:
            return state;
    }
}

export default branchLanguagesDataReducer;