import { CART } from "./action-types";

const setCartId = (cart_id) => ({
  type: CART.CARTID,
  cart_id,
});

const cartCountCall = () => ({
  type: CART.GETCARTCOUNT,
});
export { setCartId, cartCountCall };
