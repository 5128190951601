import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./giftsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Logo from "../../Comman/Logo/Logo";
import api from "../../CommonApi/axios";
import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";

function GiftSuccessPage(props) {
  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    customRes,
    branchDineInTimeData,
    orderData,
  } = props;
  let history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { poId } = useParams();
  const { tableId } = useParams();

  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  var url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  var Voucher_id = pathnamesplits[3];

  const myString = Voucher_id;
  const extractedString = myString?.split('#')[0];

  // console.log("Voucher_id", Voucher_id);

  const [abcd, setabcd] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, [extractedString]);

  function handleSubmit() {
    let item = {
      voucher_id: extractedString,
      status: "success",
    };
    api
      .patch(`/client/purchase_voucher/updateVoucherPaymentStatus`, item)
      .then((res) => {
        // console.log("success", res.data);
        setabcd(true);
        // history.goBack();
      })
      .catch((err) => {
        if (err.response) {
          setabcd(true);
          toast.success(
            "Your Transaction has been done successfully please back to home",
            // toast.error(err?.response?.data?.error?.message,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  }

  return (
    <React.Fragment>
      {/* <Navbar
        style={{height :75}}
        className="shadow-lg p-2 bg-body"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <div className="my-2"></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <CommonHeader/>
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          {abcd == false ? (
            <Row className="justify-content-sm-center">
              <div
                className="text-center"
                style={{ width: "600px", backgroundColor: "#F0EEED" }}
              >
                <Container>
                  <Card>
                    <Card.Body>
                      <div>
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src="/assets/media/my/loadnig.gif"
                          className=""
                          alt="loader"
                        />
                      </div>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="header_success mt-3">
                            Payment Processing
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="payment_text_info mt-2">
                            Please wait , we are confirming your payment and
                            please donot press back & close window.
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </div>
            </Row>
          ) : (
            <Row className="justify-content-sm-center">
              <div
                className="text-center"
                style={{ width: "580px", backgroundColor: "#F0EEED" }}
              >
                <Container>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        <div lassName="mt-3 mb-4">
                          <img
                            style={{ height: "80px" }}
                            src="/assets/media/my/check.png"
                            alt="success_png"
                          />
                        </div>
                      </Card.Title>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="header_success">
                          Payment Successful
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="payment_text_info mt-1">
                          Your Gift Voucher is on your way. Please check your email for more details.
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                      <Button
                        type="button"
                        style={{
                          backgroundColor:
                            customRes?.button_colour ?? "#dc3545",
                          border: 0,
                        }}
                        className="my-4 custom_hover"
                        // onClick={() => setabcd(!abcd)}
                        onClick={() => history.push(`/`)}
                      >
                        <strong
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          Back to Home{" "}
                        </strong>
                      </Button>
                    </Card.Body>
                  </Card>
                </Container>
              </div>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftSuccessPage);
