import { makeStyles } from "material-ui-core";

export const generateStyles = (dynamicProps) => {
  return makeStyles((theme) => ({
    listItemTextFocused: {
      '&:hover': {
        color: `${dynamicProps.menuHoverColor} !important` || "#D3921F !important",
      },
      SideTitle: {
        '&:hover': {
          // Add your hover styles here
          color: `${dynamicProps.menuHoverColor} !important` || "red !important", // Example hover color
        },
      },
      // fontSize: "26px !important",
    },
    drawer: {
      backgroundColor: `${dynamicProps.drawerBackgroundColor} !important` || "#fff !important",
    },
  }));
};