import { CUSTOM } from "../../actions";

const cmsUrlReducerRes = (state = null, action) => {
    switch (action.type) {
        case CUSTOM.CMSRES:
            return action.res;
        default:
            return state;
    }
}

export default cmsUrlReducerRes;