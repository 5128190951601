import { ORDER } from "../../actions";

const orderCostDataReducer = (state = null, action) => {
    switch (action.type) {
        case ORDER.CLEAR:
            return {};
        case ORDER.SETORDERCOSTDATA:
            return action.ordercostdata;
        default:
            return state;
    }
}

export default orderCostDataReducer;