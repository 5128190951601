import { useState } from 'react';

export const useHover = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [hoveredIndexSection3, setHoveredIndexSection3] = useState(null);


    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    const handleMouseEnter3 = (index) => {
      setHoveredIndexSection3(index);
  };

  const handleMouseLeave3 = () => {
      setHoveredIndexSection3(null);
  };

    const getLinkStyle = (index, hoveredIndex, defaultColor, hoverColor) => ({
        color: index === hoveredIndex ? defaultColor : hoverColor,
        cursor: 'pointer',
    });

    const getLinkStyle3 = (index, hoveredIndexSection3, defaultColor, hoverColor) => (
      // console.log(index, hoveredIndexSection3, defaultColor, hoverColor),
      {
      color: index === hoveredIndexSection3 ? defaultColor : hoverColor ,
      cursor: 'pointer',
  });

    return {
        hoveredIndex,
        handleMouseEnter,
        handleMouseLeave,
        getLinkStyle,
        hoveredIndexSection3,
        handleMouseEnter3,
        handleMouseLeave3,
        getLinkStyle3
    };
};