import { BRANCH } from "../../actions";

const branchTimeReducer = (state = [], action) => {
    switch (action.type) {
        case BRANCH.BRANCHTIMEDATACLEAR:
            return [];
        case BRANCH.BRANCHTIMEDATA:
            return action.timeData;
        default:
            return state;
    }
}

export default branchTimeReducer;