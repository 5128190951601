import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "./contactus.css";
import { Button, Card, Col, Row } from "react-bootstrap";
import Logo from "../../Comman/Logo/Logo";
import SideBar from "../../Comman/SideBar";
import api from "../../CommonApi/axios";
import Select from "react-select";
import {
  clearChargesData,
  clearProductList,
  getBranch,
  getBranchDetails,
  getBranchTime,
  getCustomData,
  getMenuList,
  setBranchId,
  setBranchList,
  setBranchName,
  getOrderCost,
  setOrderCostData,
  setBranchTimeData,
  setCustome,
  setDeliveryCost,
  setLanguage,
  setProductList,
  setType,
} from "../../Store";
import { connect } from "react-redux";
import $ from "jquery";
import {  showSuccessNotificationText } from "../CommonComponet/Swal/Swal";
import useError from "../CommonComponet/Error/useError";
import { validateEmail } from "../CommonComponet/Email/Email";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import Footer from "../../Comman/Footer";
import { extractHostname } from "../CommonComponet/UrlUtils/urlUtils";

window.jquery = window.$ = $;

function ContactUs(props) {

  const { error, showError, clearError } = useError();

  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );


  const {
    setLanguage,
    setType,
    language,
    type,
    getBranch,
    branchList,
    setBranchId,
    setBranchName,
    branchId,
    deliverycosterror,
    getOrderCost,
    setOrderCostData,
    getBranchTime,
    setBranchTimeData,
    setBranchList,
    getMenuList,
    clearProductList,
    clearChargesData,
    setDeliveryCost,
    getBranchDetails,
    setCustome,
    getCustomData,
    customReducer,
    customRes,
    customUrl,
    branchwelcomemessageData,
    orderData,
  } = props;

  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  const [fullname, setfullname] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");


  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0px',
      height : 45 // Adjust the border radius as needed
      // Add other styles if required
    }),
  };

  const hostname = extractHostname();
  const bbbb = "mcd.mypreview.xyz";

  useEffect(() => {
    getBranchList();
  }, []);
  const [user, setuser] = useState([]);
  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState([]);

  function getBranchList() {
    let item = {
      url: hostname,
      language: "EN",
    };
    api
      .post(`/client/order/branchlist`, item)
      .then((res) => {
         const options = res.data.data.map((item) => ({
          label: item.branch_name_EN,
          value: item.branch_id,
        }));
        setuser(options);
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.message);
      });
  }



  function handalSubmit(event) {
    event.preventDefault();
    clearError(null);


    if (fullname.trim() === "") {
      showError("Please enter your full name.");
      return;
    } else if (phone_number.trim() === "") {
      showError("Please enter your phone number.");
      return;
    } else if (email.trim() === "") {
      showError("Please enter your email.");
      return;
    } else if (!validateEmail(email)) {
      showError("Please enter a valid email.");
      return;
    } else if (branch_id === "") {
      showError("Please select a branch.");
      return;
    } else if (subject.trim() === "") {
      showError("Please enter a subject.");
      return;
    } else if (message.trim() === "") {
      showError("Please enter a message.");
      return;
    }
    else {
      let item = {
        name: String(fullname),
        branch_id: String(branch_id),
        phone_number: String(phone_number),
        email: String(email),
        subject: String(subject),
        message: String(message),
      };
      api
        .post(`/client/inquiry`, item)
        .then((res) => {
          clearError(null);
          showSuccessNotificationText("Your enquiry details has been submitted successfully we will reach shortly.")
          setemail("");
          setphone_number("");
          setbranch_id("");
          setfullname("");
          setmessage("");
          setsubject("");
          setbranch_id2([]);
        })
        .catch((err) => {
          if (err.response) {
            showError(err.response?.data?.error?.message);
          }
        });
    }
  }

  return (
    <React.Fragment>
        {/* <Navbar
          style={{ height: 75 }}
          className="p-2 bg-body"
          collapseOnSelect
          expand="lg"
          variant="dark"
        >
          <Container fluid>
            <Navbar.Brand>
              <React.Fragment>
                <SideBar />
              </React.Fragment>
              <Logo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav></Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}

      <CommonHeader/>
        <div style={{ backgroundColor: "#F0EEED", minHeight: "100vh" }}>
          <br/>
        <Container className="mb-10">
          <h1 className="main_header">Contact Us</h1>
          <Card style={{ border: 0, borderRadius: 0 }}>
            <Form className="lebal_text mx-2" onSubmit={handalSubmit}>
              <Row style={{ padding: 14 }}>
                <Col xs={12} lg={6} md={6}>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>
                      Full Name{" "}
                      <span style={{ color: "red", fontSize: 18 }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="text_input_contact"
                      value={fullname}
                      onChange={(e) => setfullname(e.target.value)}
                      placeholder="Enter your full name"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={6} md={6}>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>
                      Phone Number{" "}
                      <span style={{ color: "red", fontSize: 18 }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="filterme text_input_contact"
                      value={phone_number}
                      onChange={(e) => setphone_number(e.target.value)}
                      placeholder="Enter your number"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={6} md={6}>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>
                      Email{" "}
                      <span style={{ color: "red", fontSize: 18 }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      className="text_input_contact"
                      onChange={(e) => setemail(e.target.value)}
                      placeholder="Enter your email"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={6} md={6}>
                  <div className="mb-3 mt-3">
                    <Form.Label>
                      Branch Select{" "}
                      <span style={{ color: "red", fontSize: 18 }}>*</span>
                    </Form.Label>
                    <Select
                    isSearchable={false}
                      blurInputOnSelect={true}
                      placeholder="Select Branch"
                      styles={customStyles}
                      options={user}
                      value={branch_id2}
                      onChange={(opt) => {
                        setbranch_id(opt.value);
                        setbranch_id2(opt);
                      }}
                    />
                  </div>
                </Col>

                <Form.Group className="mb-4 mt-3" controlId="formBasicPassword">
                  <Form.Label>
                    Subject{" "}
                    <span style={{ color: "red", fontSize: 18 }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={subject}
                    className="text_input_contact"
                    onChange={(e) => setsubject(e.target.value)}
                    placeholder="Enter your subject"
                  />
                </Form.Group>
                <Form.Group className="mb-4 mt-3" controlId="formBasicPassword">
                  <Form.Label>
                    Message{" "}
                    <span style={{ color: "red", fontSize: 18 }}>*</span>
                  </Form.Label>
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Enter your message"
                  >
                    <Form.Control
                      as="textarea"
                      style={{
                        height: "100px",
                        color: "#5a5a5a",
                        borderRadius: "2px",
                        border: "1px solid rgb(212 209 209)",
                      }}
                      placeholder="Leave a comment here"
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
                <div className="col-md-12"> {errorDiv} </div>
                <Row className="justify-content-center mb-4">
                <Col  xs={12} md={6} lg={3} sm={6}>
                  <Button
                    style={{
                      borderRadius: 15,
                      backgroundColor: customRes?.button_colour ?? "#dc3545",
                      color: customRes?.non_highlighted_text ?? "white",
                      height: "42px",
                      width: "100%",
                      fontWeight: "bold",
                      border: 0,
                    }}
                    className="text-center custom_hover"
                    type="submit"
                  >
                    Send
                  </Button>
                </Col>
                </Row>
              </Row>
            </Form>
          </Card>
        </Container>
        <br/>
        <br/>
        <br/>
      <Footer/>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  language: state.branch.language,
  type: state.branch.type,
  branchList: state.branch.branchList,
  branchId: state.branch.branchId,
  branchTime: state.branch.branchTime,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
  deliverycosterror: state.order.deliverycosterror,
  branchwelcomemessageData: state.branch.branchwelcomemessageData,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    setType: (type) => dispatch(setType(type)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    getBranch: () => dispatch(getBranch()),
    getBranchTime: () => dispatch(getBranchTime()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setBranchTimeData: (data) => dispatch(setBranchTimeData(data)),
    setBranchList: (data) => dispatch(setBranchList(data)),
    getMenuList: () => dispatch(getMenuList()),
    clearProductList: () => dispatch(clearProductList()),
    clearChargesData: () => dispatch(clearChargesData()),
    setDeliveryCost: (value) => dispatch(setDeliveryCost(value)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
