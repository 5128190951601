import { CUSTOM } from "../../actions";

const customUrlReducer = (state = null, action) => {
    switch (action.type) {
        case CUSTOM.URL:
            return action.url;
        default:
            return state;
    }
}

export default customUrlReducer;