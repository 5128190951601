import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  apiAppBranchDetails,
  apiAppBranchList,
  apiAppBranchTimeFilter,
} from "../../api";
import { branchData, branchTimeData } from "../../core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BRANCH,
  setBranchLanguageData,
  setBranchList,
  setBranchPrimaryLanguage,
  setBranchTimeData,
  setDeliverySettingsData,
  setDineInTimeData,
  setWelcomeMessageData,
  setBranchDetailsGetById,
} from "../actions";

const language = (state) => state.branch.language;
const type = (state) => state.branch.type;
const branchId = (state) => state.branch.branchId;
const url = (state) => state.custom.customUrl;

function* callBranch() {

  // console.log("ssss")
  try {
    const language_ = yield select(language);

    const url_ = yield select(url);
    const data = branchData(language_.toUpperCase(), url_);
    // console.log("setBranch_data", data);
    const response = yield call(apiAppBranchList, data);
    // console.log("responseBranchlist", response);
    // // console.log('responseBranchlist', response.data);
    // let arr = response.data;
    // let f_branch = [];
    // if (arr) {
    //   for (let index = 0; index < arr.length; index++) {
    //     const element = arr[index];
    //     f_branch.push({
    //       id: element.branch_id,
    //       label: element.branch_name,
    //     });
    //   }
      yield put(setBranchList(response?.data));
    // }
  } catch (error) {
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // console.log("ErrorbranchListAPi", error);
  }
}

function* callBranchTimeFilter() {
  
  try {
    const language_ = yield select(language);
    const type_ = yield select(type);
    const id_ = yield select(branchId);
    // console.log("id_", id_);
    const data = branchTimeData(language_.toUpperCase(), type_, id_);
    // // console.log("data", data);
    const response = yield call(apiAppBranchTimeFilter, data);
    // console.log('responseBranchTime', response);
    if (response?.data) {
      yield put(setBranchTimeData(response.data));
    }
  } catch (error) {
    // console.log("Errortime", error);
  }
}

function* callBranchDetails() {
  try {
    const id_ = yield select(branchId);
    console.log("id_ff", id_);
    // const data = branchTimeData(language_.toUpperCase(), type_, id_);
    // console.log("data", data);
    const response = yield call(apiAppBranchDetails, id_);
    // console.log("responseBranchDetails", response.data);
    if (response?.data) {
      // const branchAlldata = response?.data?.resData;
      const { languange, primary_language } = response?.data?.resData;
      const { DineInTimeData } = response?.data;
      const type_ = yield select(type);
      // console.log("rrrrr", type_);
      const DeliverySettingsData =
        type_ == "pickup"
          ? response.data.PickupSettingsData
          : response.data.DeliverySettingsData;
      // console.log("DineInTimeData", DineInTimeData);
      const WelcomeMessageData = response?.data;
      const BranchDetailsGetById = response?.data;
      // console.log("WelcomeMessageData", WelcomeMessageData);
      yield put(setDeliverySettingsData(DeliverySettingsData));
      yield put(setDineInTimeData(DineInTimeData));
      yield put(setWelcomeMessageData(WelcomeMessageData));
      yield put(setBranchDetailsGetById(BranchDetailsGetById));
      // console.log("primary_language", primary_language == 'Italiano' ? 'it' : primary_language == 'Deutsch' ? 'de' : primary_language == 'français' ? 'fr' : 'en');
      yield put(
        setBranchPrimaryLanguage(
          primary_language == "Italiano"
            ? "it"
            : primary_language == "Deutsch"
            ? "de"
            : primary_language == "français"
            ? "fr"
            : "en"
        )
      );
      let arr = [];
      if (language != undefined && languange) {
        for (let index = 0; index < languange.length; index++) {
          const element = languange[index];
          if (element.includes("Eng")) {
            arr.push("en");
          }
          if (element.includes("fra")) {
            arr.push("fr");
          }
          if (element.includes("Due")) {
            arr.push("de");
          }
          if (element.includes("Ita")) {
            arr.push("it");
          }
        }
        // console.log("FARRAY", arr);
        yield put(setBranchLanguageData(arr));
      } else {
        yield put(setBranchLanguageData(["en", "it", "de", "fr"]));
        // console.log("NOLANGU");
      }
    }
  } catch (error) {
    // console.log("branchDetails", error);
  }
}

export function* watchBranchList() {
  yield takeEvery(BRANCH.GETBRANCH, callBranch);
}

export function* watchBranchTimeFilter() {
  yield takeEvery(BRANCH.GETBRANCHTIME, callBranchTimeFilter);
}

export function* watchBranchDetails() {
  yield takeEvery(BRANCH.BRANCHDETAILS, callBranchDetails);
}
