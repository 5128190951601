import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import "./paymentfail.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../Comman/Logo/Logo";
import api from "../../CommonApi/axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";




function PaymentFailedDineIn(props) {
  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    customRes,
    branchDineInTimeData,
    orderData,
  } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let history = useHistory();

  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
    var Branch_id = String(pathnamesplits[1])
    var TableNo = String(pathnamesplits[2])
    var unique_code = String(pathnamesplits[5]);

    const myString = unique_code;
    const extractedString = myString?.split('#')[0];

  const [loader, setloader] = useState(false);
  function handleSubmit() {
    setloader(true);
    let item = {
      unique_code: extractedString,
      table_number : TableNo,
      branch_id : Branch_id
    };
    // console.log(item);
    api
      .post(`/client/order/paymentReInitializeForDineIn`, item)
      .then((response) => {
        setloader(false);
        // console.log("res1", response?.data?.data?.RedirectUrl);
        if (response?.data?.data?.RedirectUrl) {
          window.open(`${response?.data?.data?.RedirectUrl}`, "_self");
        }
      })
      .catch((err) => {
        setloader(false);
        if (err.response) {
          toast.error(err?.response?.data?.error?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  const location = useLocation();

  useEffect(()=>{
    window.history.pushState(null, null, location.href);
    window.onpopstate = function(event) {
    history.go(1);
  };
  },[])


  return (
    <React.Fragment>
      {/* <Navbar
      style={{height :75}}
        className="shadow-lg p-2 bg-body"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <div className="my-2"></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Row className="justify-content-sm-center">
            <div
              className="text-center"
              style={{ width: "600px", backgroundColor: "#F0EEED" }}
            >
              <Card style={{border : 0 , borderRadius : 0}}>
                <Container>
                  <Card.Body>
                    <Card.Title>
                      <div>
                        <img
                          style={{ height: "80px" }}
                          src="/assets/media/my/cancel.png"
                          alt="payment_fail"
                        />
                      </div>
                    </Card.Title>
                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={8}>
                        <Card.Title className="header_success mt-3">
                          Payment Failed
                        </Card.Title>
                      </Col>
                      <Col lg={2}></Col>
                    </Row>

                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={8}>
                        <Card.Title className="payment_text_info mt-2">
                          We are sorry that you transaction failed please try
                          again.
                        </Card.Title>
                      </Col>
                      <Col lg={2}></Col>
                    </Row>

                    <Card.Text>
                      {loader == true ? (
                        <Box>
                          <CircularProgress size={50} thickness={5} />
                        </Box>
                      ) : (
                        <Button
                          type="button"
                          style={{
                            backgroundColor:
                              customRes?.button_colour ?? "#dc3545",
                            border: 0,
                          }}
                          className="custom_hover my-4"
                          onClick={handleSubmit}
                        >
                          <strong
                            style={{
                              color: customRes?.non_highlighted_text ?? "white",
                            }}
                          >
                            Retry
                          </strong>
                        </Button>
                      )}
                      {/* <Button
                        type="button"
                        style={{
                          backgroundColor:
                            customRes?.button_colour ?? "#dc3545",
                          border: 0,
                        }}
                        className="custom_hover my-4 mx-2"
                        onClick={() => history.push(`/${user?.resData?.branch_name_EN}/menu?no=${TableNo}`)}
                      >
                        <strong
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          Go Back
                        </strong>
                      </Button> */}
                    </Card.Text>
                  </Card.Body>
                </Container>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFailedDineIn);
