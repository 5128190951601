import React from 'react'

export default function RightSvg({ color }) {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 146 461"
                        fill="none"
                  >
                        <path
                              style={{
                                    fill: `${color || "rgb(255, 151, 30)"}`,
                                    filter: "brightness(90%)",
                              }}
                              d="M99.3825 60.8366C131.472 46.7378 141.596 14.6931 145.672 1.6489C145.723 1.49404 145.722 1.32666 145.669 1.17245C145.617 1.01825 145.515 0.885751 145.38 0.795294C145.245 0.704837 145.085 0.661423 144.923 0.67172C144.761 0.682015 144.606 0.745449 144.484 0.852285C136.017 8.21811 126.079 13.6752 115.34 16.855C65.4151 30.4345 64.377 81.3943 65.1008 95.8177C65.1943 97.8025 65.8693 97.8833 66.5392 96.0025C72.838 78.4674 87.8325 67.3778 99.3825 60.8366ZM-109.386 451.808C-109.81 451.975 -110.169 452.278 -110.406 452.67C-110.644 453.062 -110.748 453.52 -110.702 453.976C-110.656 454.432 -110.463 454.86 -110.152 455.195C-109.842 455.53 -109.431 455.754 -108.981 455.832C42.2899 484.632 146.324 373.438 90.6682 221.047C79.954 191.583 63.9808 163.701 47.2406 126.8C37.6593 105.656 27.5271 44.6934 66.5592 17.7771C66.7272 17.6511 66.8512 17.4749 66.9133 17.2738C66.9754 17.0726 66.9725 16.857 66.9049 16.6579C66.8373 16.4588 66.7086 16.2865 66.5372 16.1658C66.3659 16.0451 66.1607 15.9822 65.9513 15.9862C-34.7154 19.2456 -83.7177 90.2427 -93.8715 154.977C-99.413 190.235 -97.3866 220.351 -91.4126 238.37C-91.36 238.524 -91.3555 238.69 -91.3996 238.846C-91.4437 239.002 -91.5343 239.141 -91.6593 239.244C-91.7846 239.347 -91.9384 239.409 -92.0996 239.421C-92.2609 239.433 -92.4218 239.395 -92.5606 239.311C-116.266 225.514 -127.354 195.696 -130.105 187.168C-130.158 186.929 -130.282 186.711 -130.46 186.544C-130.638 186.377 -130.862 186.268 -131.103 186.231C-131.344 186.194 -131.591 186.231 -131.811 186.337C-132.031 186.443 -132.215 186.613 -132.338 186.824C-137.005 193.415 -151.284 216.522 -154.501 256.37C-160.328 328.383 -127.762 351.959 -95.8662 392.399C-67.4694 428.389 -93.9103 445.439 -109.386 451.808Z"
                              fill="#FF971E"
                        />
                  </svg>
            </>
      )
}
