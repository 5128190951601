import { BRANCH } from "../../actions";


const branchwelcomemessageDataReducer = (state = null, action) => {
    switch (action.type) {
        case BRANCH.WELCOMEMESSAGEDATA:
            return action.WelcomeMessageData;
        default:
            return state;
    }
}

export default branchwelcomemessageDataReducer;