import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

if (process.env.REACT_APP_BASE_URL === 'https://customerapi.kitchenparameter.com') {
  const noop = () => {}
  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ].forEach((method) => {
    window.console[method] = noop
  })
}


export default api;
