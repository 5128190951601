import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LanguageIcon from "@mui/icons-material/Language";
import Tooltip from "react-bootstrap/Tooltip";
import TooltipMUi from "@mui/material/Tooltip";
import { Link, useHistory, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { styled } from "@mui/material/styles";
import ButtonMUi from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import $ from "jquery";
import "./food.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../Comman/Logo/Logo";
import LanguageSelect from "../../Comman/LanguageSelect/LanguageSelect";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { connect } from "react-redux";
import "animate.css";
import {
  getCharges,
  setProductList,
  checkout,
  setCustome,
  getCustomData,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
  setCartId,
} from "../../Store";
import moment from "moment";
import api from "../../CommonApi/axios";
import Swal from "sweetalert2";
import SideBar from "../../Comman/SideBar";
import { Box, Hidden } from "@mui/material";
import { Dining } from "@mui/icons-material";

window.jquery = window.$ = $;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 20,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "9px 7px",
  },
}));

function FoodListBoot(props) {
  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  const Delivery_detailes = JSON.parse(
    sessionStorage.getItem("Delivery_detailes")
  );

  const Pickup_detailes = JSON.parse(sessionStorage.getItem("Pickup_detailes"));
  const cart_id = sessionStorage.getItem("cart_id");

  // console.log("Delivery_detailes", Delivery_detailes);
  // console.log("Pickup_detailes", Pickup_detailes);
  // console.log("cart_id", cart_id);

  const history = useHistory();

  const [ProductView, setProductView] = useState(
    useMediaQuery({ query: `(max-width: 760px)` }) == true
      ? "Gridview"
      : "Listview"
  );
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const isTablet = useMediaQuery({ minWidth: 761, maxWidth: 988 });

  // console.log("isTablet", isTablet);

  // console.log("isMobile", isMobile);

  useEffect(() => {
    document.querySelectorAll(".navbar-nav li a").forEach(function (e) {
      e.addEventListener("click", function (e) {
        e.preventDefault();
        let headerHeight = document.querySelector("#kt_header").offsetHeight;
        let ths = this;

        let target = ths.getAttribute("href");
        window.scrollTo(0, $(target).offset().top - headerHeight - 30);
        // console.log($(target).offset().top);
      });
    });
    window.addEventListener("scroll", function (e) {
      let headerHeight = document.querySelector("#kt_header").offsetHeight;
      let top = window.pageYOffset;

      document.querySelectorAll(".scroll-section").forEach(function (e) {
        let section = e;
        if (e.offsetTop - (headerHeight + 10) < top) {
          document.querySelectorAll(".nav-link").forEach(function (e) {
            // e.classList.remove("active");
            let target = section.getAttribute("id");
            document
              .querySelector(".nav-item a[href='#" + target + "']")
              .classList.add("active");
            //    $("#scroll-nav").scrollLeft($(".nav-item a[href='#" + target + "']").offset().left)
            if (target == section.getAttribute("id")) {
              e.classList.remove("active");
              document
                .querySelector(".nav-item a[href='#" + target + "']")
                .classList.add("active");
              $("#scroll-nav").scrollLeft(
                $(".nav-item a[href='#" + target + "']").offset().left - 60
              );
              // let w = $(window).width();
              // let l = $(".nav-item a[href='#" + target + "']").offset().left;
              // let cw = $(".nav-item a[href='#" + target + "']").width();
              // let tl = cw + l;
            }
          });
        }
      });
    });
  }, []);

  const { poId } = useParams();
  const { tableId } = useParams();

  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  var url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");

  var reg = url
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

  var urlBranchID = String(poId);
  var urlTableId = String(tableId);

  // console.log("urlBranchID", urlBranchID);
  // console.log("urlTableId", urlTableId);
  //

  const [show, setShow] = useState(false);
  const [shoeMoredescription, setshoeMoredescription] = useState(false);

  const [Product_detaisBy_id, setProduct_detaisBy_id] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [ProductId_, setProductId_] = useState("");

  // console.log("Product_detaisBy_id", Product_detaisBy_id);

  const handleClose = () => {
    setShow(false);
    setTotalCost(0);
    setProduct_detaisBy_id("");
  };
  const handleShow = () => setShow(true);

  const [shoeMore, setshoeMore] = useState(false);

  function ProducteDetailsGet(productID_) {
    setProductId_(productID_);
    setTotalCost(0);
    api
      .get(`/client/product/${productID_}`)
      .then((res) => {
        const tableData = res.data.data;
        // console.log("ProducteDetailsGet", tableData);
        // setProduct_detaisBy_id(tableData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function increment_quantity() {
    setQuantity((quan) => quan + 1);
  }

  function decrement_quantity() {
    if (quantity > 1) setQuantity((quan) => quan - 1);
  }

  const [orderedItems, setOrderedItems] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  const handleChange = (item, event) => {
    if (event.target.checked) {
      setOrderedItems((cartItem) => [
        ...cartItem,
        { ingredient: item.ingredient, _id: item._id },
      ]);
      setTotalCost((total) => total + parseInt(item.price));
      // add item to orderedItems array
    } else {
      // remove item from orderedItems array
      setOrderedItems((cartItem) =>
        cartItem.filter((i) => i.ingredient !== item.ingredient)
      );
      setTotalCost((total) => total - parseInt(item.price));
    }
  };

  ////////////////////// handalSubmitDineIn //////////////////////

  const [full_name_dineIn, setfull_name_dineIn] = useState("");
  const [email_dineIn, setemail_dineIn] = useState("");
  const [phone_number_dineIn, setphone_number_dineIn] = useState("");
  const [ShowDineIn, setShowDineIn] = useState(urlTableId > 0 ? true : false);
  const DineIn_detailes = JSON.parse(sessionStorage.getItem("DineIn_detailes"));
  const [DineInPreTime, setDineInPreTime] = useState("");

  useEffect(() => {
    if (DineIn_detailes?.order_type === "dineIn") {
      setShowDineIn(false);
    }
  }, []);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const validateEmail = (email_dineIn) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email_dineIn).toLowerCase());
  };

  function handalSubmitDineIn(event) {
    event.preventDefault();

    if (!validateEmail(email_dineIn)) {
      setError("The entered email is invalid.");
      return;
    } else {
      let item = {
        full_name: full_name_dineIn,
        email: email_dineIn,
        phone_number: phone_number_dineIn,
        order_date: moment().format(),
        order_time: moment().format("LT"),
        order_type: "dineIn",
        branch_id: urlBranchID,
        PreTime: DineInPreTime,
      };

      sessionStorage.setItem("DineIn_detailes", JSON.stringify(item));
      sessionStorage.removeItem("Pickup_detailes");
      sessionStorage.removeItem("Delivery_detailes");
      sessionStorage.removeItem("cart_id");
      setShowDineIn(false);
      setfull_name_dineIn("");
      setemail_dineIn("");
      setphone_number_dineIn("");
    }
  }

  ///////////////////// handalSubmitDineIn //////////////////////
  useEffect(() => {
    if (urlTableId > 0) {
      var reg = url
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      setBranchId(urlBranchID);
      setType("dineIn");
      setBranchName(reg);
      if (branchDineInTimeData) {
        let value =
          branchDineInTimeData[
            moment(new Date()).format("dddd").toLocaleLowerCase()
          ];
        setDineInPreTime(value[0]?.prep_time);
      }
    }
    getMenuList();
    getBranchDetails();
    setBranchId(urlBranchID);
  }, []);

  function Add_Order(image_url) {
    setShow(false);
    if (Delivery_detailes != null) {
      let item = new FormData();

      if (cart_id) {
        item.append("cart_id", cart_id);
      }
      if (Delivery_detailes?.full_name) {
        item.append("full_name", String(Delivery_detailes?.full_name));
      }
      if (Delivery_detailes?.email) {
        item.append("email", String(Delivery_detailes?.email));
      }
      if (Delivery_detailes?.phone_number) {
        item.append("phone_number", String(Delivery_detailes?.phone_number));
      }
      if (Delivery_detailes?.address_line1) {
        item.append("address_line1", String(Delivery_detailes?.address_line1));
      }
      if (Delivery_detailes?.address_line2) {
        item.append("address_line2", String(Delivery_detailes?.address_line2));
      }
      if (Delivery_detailes?.lat) {
        item.append("lat", String(Delivery_detailes?.lat));
      }
      if (Delivery_detailes?.lng) {
        item.append("long", String(Delivery_detailes?.lng));
      }
      if (Delivery_detailes?.order_date) {
        item.append(
          "order_date",
          moment(Delivery_detailes?.order_date).format()
        );
      }
      // "2023-02-11T02:11:51",
      if (Delivery_detailes?.order_time) {
        item.append("order_time", String(Delivery_detailes?.order_time));
      }
      if (Delivery_detailes?.order_type) {
        item.append("order_type", "delivery");
      }
      if (Delivery_detailes?.branch_id) {
        item.append("branch_id", Delivery_detailes?.branch_id);
      }
      if (Delivery_detailes?.pincode) {
        item.append("pincode", String(Delivery_detailes?.pincode));
      }
      if (quantity) {
        item.append("quantity", Number(quantity));
      }
      if (ProductId_) {
        item.append("product_id", ProductId_);
      }
      // if (
      //   Number(Product_detaisBy_id.base_price) * Number(quantity) +
      //   Number(totalCost * quantity)
      // ) {
      //   item.append(
      //     "amount",
      //     Number(Product_detaisBy_id.base_price) * Number(quantity) +
      //       Number(totalCost * quantity)
      //   );
      // }
      if (orderedItems.length > 0) {
        item.append("custom_property", JSON.stringify(orderedItems));
      }

      // console.log("krunal", Object.fromEntries(item.entries()));
      // console.log("krunal", Object.fromEntries(orderedItems.entries()));

      api
        .post(`/client/cart`, item)
        .then((res) => {
          setQuantity(1);
          setProduct_detaisBy_id("");
          if (res?.data?.data?.status === "success") {
            sessionStorage.setItem("cart_id", res.data.data._id);
          }
          setQuantity(1);
          setProduct_detaisBy_id("");
          Swal.fire({
            title: "Your item has been added",
            // imageUrl: image_url,
            // imageHeight: 170,
            // imageWidth: 170,
            // imageClass: "img-responsive rounded-circle",
            showConfirmButton: false,
            timer: 1500,
            icon: "success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
          setTimeout(() => {
            if (cartCount_ >= 1) {
              cartCount();
            } else {
              api
                .get(`/client/cart/itemCount/${res.data.data._id}`)
                .then((res) => {
                  setCartCount_(res.data.data);
                  // const tableData = res.data.data;
                  // console.log("tableData", res.data.data);
                })
                .catch((err) => {
                  console.log(err.response?.data?.error?.message);
                });
              // window.location.reload(true);
            }
          }, 1500);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
    if (Pickup_detailes != null) {
      let item = new FormData();

      if (cart_id) {
        item.append("cart_id", cart_id);
      }
      if (Pickup_detailes?.full_name) {
        item.append("full_name", String(Pickup_detailes?.full_name));
      }
      if (Pickup_detailes?.email) {
        item.append("email", String(Pickup_detailes?.email));
      }
      if (Pickup_detailes?.phone_number) {
        item.append("phone_number", String(Pickup_detailes?.phone_number));
      }

      if (Pickup_detailes?.order_date) {
        item.append("order_date", moment(Pickup_detailes?.order_date).format());
      }
      // "2023-02-11T02:11:51",
      if (Pickup_detailes?.order_time) {
        item.append("order_time", String(Pickup_detailes?.order_time));
      }
      if (Pickup_detailes?.order_type) {
        item.append("order_type", "pickup");
      }
      if (Pickup_detailes?.branch_id) {
        item.append("branch_id", Pickup_detailes?.branch_id);
      }
      if (quantity) {
        item.append("quantity", Number(quantity));
      }
      if (ProductId_) {
        item.append("product_id", ProductId_);
      }
      // if (
      //   Number(Product_detaisBy_id.base_price) * Number(quantity) +
      //   Number(totalCost * quantity)
      // ) {
      //   item.append(
      //     "amount",
      //     Number(Product_detaisBy_id.base_price) * Number(quantity) +
      //       Number(totalCost * quantity)
      //   );
      // }
      if (orderedItems.length > 0) {
        item.append("custom_property", JSON.stringify(orderedItems));
      }
      // item.forEach((entries) => console.log(entries));
      // console.log(Object.fromEntries(item.entries()));
      // console.log("krunal", Object.fromEntries(orderedItems.entries()));
      api
        .post(`/client/cart`, item)
        .then((res) => {
          setQuantity(1);
          setProduct_detaisBy_id("");
          if (res?.data?.data?.status === "success") {
            sessionStorage.setItem("cart_id", res.data.data._id);
          }
          Swal.fire({
            title: "Your item has been added",
            // imageUrl: image_url,
            // imageHeight: 170,
            // imageWidth: 170,
            // imageClass: "img-responsive rounded-circle",
            showConfirmButton: false,
            timer: 1500,
            icon: "success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });

          setTimeout(() => {
            if (cartCount_ >= 1) {
              cartCount();
            } else {
              api
                .get(`/client/cart/itemCount/${res.data.data._id}`)
                .then((res) => {
                  setCartCount_(res.data.data);
                  // const tableData = res.data.data;
                  // console.log("tableData", res.data.data);
                })
                .catch((err) => {
                  console.log(err.response?.data?.error?.message);
                });
              // window.location.reload(true);
            }
          }, 1500);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
    if (DineIn_detailes != null) {
      let item = new FormData();

      if (cart_id) {
        item.append("cart_id", cart_id);
      }
      if (DineIn_detailes?.full_name) {
        item.append("full_name", String(DineIn_detailes?.full_name));
      }
      if (DineIn_detailes?.email) {
        item.append("email", String(DineIn_detailes?.email));
      }
      if (DineIn_detailes?.phone_number) {
        item.append("phone_number", String(DineIn_detailes?.phone_number));
      }

      if (DineIn_detailes?.order_date) {
        item.append("order_date", moment(DineIn_detailes?.order_date).format());
      }
      // "2023-02-11T02:11:51",
      if (DineIn_detailes?.order_time) {
        item.append("order_time", String(DineIn_detailes?.order_time));
      }
      if (DineIn_detailes?.order_type) {
        item.append("order_type", DineIn_detailes?.order_type);
      }
      if (DineIn_detailes?.branch_id) {
        item.append("branch_id", DineIn_detailes?.branch_id);
      }
      if (quantity) {
        item.append("quantity", Number(quantity));
      }
      if (ProductId_) {
        item.append("product_id", ProductId_);
      }
      if (orderedItems.length > 0) {
        item.append("custom_property", JSON.stringify(orderedItems));
      }
      // item.forEach((entries) => console.log(entries));
      // console.log(Object.fromEntries(item.entries()));
      api
        .post(`/client/cart`, item)
        .then((res) => {
          setQuantity(1);
          setProduct_detaisBy_id("");
          if (res?.data?.data?.status === "success") {
            sessionStorage.setItem("cart_id", res.data.data._id);
          }
          Swal.fire({
            title: "Your item has been added",
            // imageUrl: image_url,
            // imageHeight: 170,
            // imageWidth: 170,
            // imageClass: "img-responsive rounded-circle",
            showConfirmButton: false,
            timer: 1500,
            icon: "success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
          setTimeout(() => {
            if (cartCount_ >= 1) {
              cartCount();
            } else {
              api
                .get(`/client/cart/itemCount/${res.data.data._id}`)
                .then((res) => {
                  setCartCount_(res.data.data);
                  // const tableData = res.data.data;
                  // console.log("tableData", res.data.data);
                })
                .catch((err) => {
                  console.log(err.response?.data?.error?.message);
                });
              // window.location.reload(true);
            }
          }, 1500);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
  }

  //////////////////////// Redux //////////////////////////

  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    getCustomData,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    setCustome,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    branchdetailsbyid,
    cartCountData,
    customRes,
    branchDineInTimeData,
  } = props;

  // console.log("language", language);
  // console.log("menuList", menuList);

  useEffect(() => {
    setCartId(cart_id);
  }, []);

  useEffect(() => {
    cartCount();
  }, [cart_id != null]);

  const [cartCount_, setCartCount_] = useState("");

  function cartCount() {
    if (cart_id != null) {
      api
        .get(`/client/cart/itemCount/${cart_id}`)
        .then((res) => {
          setCartCount_(res.data.data);
          // const tableData = res.data.data;
          // console.log("tableData", res.data.data);
        })
        .catch((err) => {
          console.log(err.response?.data?.error?.message);
        });
    }
  }

  ////////////////////////////// Drewer ////////////////////////////////

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "#F0EEED", minHeight: "100vh" }}>
        <Navbar
          className="shadow-lg p-2 mb-4 bg-body rounded"
          collapseOnSelect
          expand="lg"
          variant="dark"
          // style={{
          //   position: "sticky",
          //   top: 0,
          //   zIndex: 1,
          //   padding: 0,
          //   backgroundColor: "#FFF",
          // }}
        >
          <Container fluid>
            <Navbar.Brand>
              <React.Fragment>
                <SideBar />
              </React.Fragment>
              <Logo />
            </Navbar.Brand>
            <Nav.Link
              style={{
                fontFamily: "'Baloo 2', cursive",
                fontWeight: 500,
                textTransform: "none",
                letterSpacing: "1.25px",
                textAlign: "left",
                fontSize: "15px",
                color: "black",
                marginInlineEnd: "auto",
              }}
            >
              {Delivery_detailes != null ? (
                <React.Fragment>
                  Delivery | <WatchLaterIcon /> {Delivery_detailes?.order_time}
                </React.Fragment>
              ) : Pickup_detailes?.order_time != null ? (
                <React.Fragment>
                  Pickup | <WatchLaterIcon /> {Pickup_detailes?.order_time}
                </React.Fragment>
              ) : urlTableId > 0 ? (
                <React.Fragment>
                  DineIn | <WatchLaterIcon /> {moment().format("LT")}
                </React.Fragment>
              ) : (
                ""
              )}
            </Nav.Link>

            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <Row>
                {isMobile != true ? (
                  <Col>
                    {cartCount_ != "" ? (
                      <StyledBadge
                        badgeContent={cartCount_}
                        color="success"
                        onClick={() =>
                          history.push(`/${urlBranchID}/${urlTableId}/cartpage`)
                        }
                      >
                        <ShoppingCartOutlinedIcon
                          className="mt-1 mx-3"
                          style={{
                            fontSize: 40,
                            color: "grey",
                          }}
                        />
                      </StyledBadge>
                    ) : (
                      <StyledBadge
                        onClick={() =>
                          history.push(`/${urlBranchID}/${urlTableId}/cartpage`)
                        }
                      >
                        <ShoppingCartOutlinedIcon
                          className="mt-1 mx-3"
                          style={{
                            fontSize: 40,
                            color: "grey",
                          }}
                        />
                      </StyledBadge>
                    )}
                  </Col>
                ) : (
                  ""
                )}
                <Col>
                  <TooltipMUi title="Select Language" className="mt-2">
                    <LanguageIcon
                      style={{
                        fontSize: 27,
                        color: "grey",
                        marginTop: isMobile == true ? 0 : "",
                      }}
                    />
                  </TooltipMUi>
                </Col>
              </Row>
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link className="d-none d-lg-block">
                  <Row>
                    <Col>
                      {cartCount_ != "" ? (
                        <StyledBadge
                          badgeContent={cartCount_}
                          color="success"
                          onClick={() =>
                            history.push(
                              `/${urlBranchID}/${urlTableId}/cartpage`
                            )
                          }
                        >
                          <ShoppingCartOutlinedIcon
                            className="mt-1 mx-3"
                            style={{
                              fontSize: 40,
                              color: "grey",
                            }}
                          />
                        </StyledBadge>
                      ) : (
                        <StyledBadge
                          onClick={() =>
                            history.push(
                              `/${urlBranchID}/${urlTableId}/cartpage`
                            )
                          }
                        >
                          <ShoppingCartOutlinedIcon
                            className="mt-1 mx-3"
                            style={{
                              fontSize: 40,
                              color: "grey",
                            }}
                          />
                        </StyledBadge>
                      )}
                    </Col>
                  </Row>
                </Nav.Link>
                <div className="my-2">
                  <LanguageSelect />
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container
        // style={{
        //   position: "sticky",
        //   top: "122px",
        //   zIndex: 1,
        //   padding: 0,
        //   left: 0,
        //   right: 0,
        // }}
        >
          <div className="justify-content-sm-center">
            <Row>
              <Col xs={isMobile == true ? 12 : 8} lg={10} md={8}>
                <h2
                  className="text-start"
                  style={{
                    fontFamily: "'Baloo 2', cursive",
                    fontWeight: 800,
                    textTransform: "none",
                    letterSpacing: "1.25px",
                    textAlign: "left",
                    fontSize: "25px",
                    color: "black",
                  }}
                >
                  {language == "it"
                    ? branchdetailsbyid?.resData?.branch_name_IT
                      ? branchdetailsbyid?.resData?.branch_name_IT
                      : branchdetailsbyid?.resData?.branch_name_EN
                    : language == "de"
                    ? branchdetailsbyid?.resData?.branch_name_DE
                      ? branchdetailsbyid?.resData?.branch_name_DE
                      : branchdetailsbyid?.resData?.branch_name_EN
                    : language == "fr"
                    ? branchdetailsbyid?.resData?.branch_name_FR
                      ? branchdetailsbyid?.resData?.branch_name_FR
                      : branchdetailsbyid?.resData?.branch_name_EN
                    : language == "AR"
                    ? branchdetailsbyid?.resData?.branch_name_AR
                      ? branchdetailsbyid?.resData?.branch_name_AR
                      : branchdetailsbyid?.resData?.branch_name_EN
                    : branchdetailsbyid?.resData?.branch_name_EN}
                </h2>
                <h4 className="mt-2 text-start address pointer">
                  <div>
                    {branchdetailsbyid?.resData?.address.length > 100 ? (
                      <div onClick={() => setshoeMore(!shoeMore)}>
                        {shoeMore == true
                          ? `${branchdetailsbyid?.resData?.address}... See less`
                          : `${branchdetailsbyid?.resData?.address.substring(
                              0,
                              100
                            )}... Show more`}
                      </div>
                    ) : (
                      <div>{branchdetailsbyid?.resData?.address}</div>
                    )}
                  </div>
                </h4>
              </Col>
              {isMobile == true ? (
                ""
              ) : (
                <Col xs={4} lg={2} md={4}>
                  <h4 className="mt-2 text-end">
                    <TooltipMUi title="Listview">
                      <ViewListIcon
                        style={{
                          fontSize: 37,
                        }}
                        onClick={(e) => setProductView("Listview")}
                        className="mx-2 p-1"
                      />
                    </TooltipMUi>
                    <TooltipMUi title="Gridview">
                      <GridViewIcon
                        style={{ fontSize: 35 }}
                        onClick={(e) => setProductView("Gridview")}
                        className="mx-2 p-1"
                      />
                    </TooltipMUi>
                  </h4>
                </Col>
              )}
            </Row>
          </div>
        </Container>

        <Container
          className="mb-3 mt-2 sticky_tap"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            padding: 0,
            backgroundColor: "#FFF",
          }}
        >
          <div id="kt_header">
            <nav className="navbar navbar-expand-sm w-100">
              {/* <div className="container-fluid fw-bold fs-4"> */}
              <ul
                id="scroll-nav"
                className="navbar-nav m-auto"
                style={{
                  flexDirection: "row",
                  overflow: "auto",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                {menuList.map((item, index) => (
                  <li className="nav-item px-3" key={index}>
                    <a
                      id="product_catagory"
                      className={
                        index == 0 ? "nav-link active px-3" : "nav-link px-3"
                      }
                      // onClick={() =>
                      //   console.log(
                      //     `#${item.category_name_EN.replace(/ /g, "")}`
                      //   )
                      // }
                      href={`#${item.category_name_EN.replace(/ /g, "")}`}
                    >
                      {item?.category_image ? (
                        <img
                          alt="Logo"
                          className="mx-3"
                          src={item?.category_image ? item?.category_image : ""}
                          style={{
                            backgroundColor: "#f3f3f3f7",
                            height: "22px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {language == "it"
                        ? item?.category_name_IT
                          ? item?.category_name_IT
                          : item?.category_name_EN
                        : language == "de"
                        ? item?.category_name_DE
                          ? item?.category_name_DE
                          : item?.category_name_EN
                        : language == "fr"
                        ? item?.category_name_FR
                          ? item?.category_name_FR
                          : item?.category_name_EN
                        : language == "AR"
                        ? item?.category_name_AR
                          ? item?.category_name_AR
                          : item?.category_name_EN
                        : item?.category_name_EN}
                    </a>
                  </li>
                ))}
              </ul>
              {/* </div> */}
            </nav>
          </div>
        </Container>

        <div className="foodlist_page">
          <Container>
            <Card className="mb-6">
              {menuList.map((main, index) => (
                <div
                  className="scroll-section"
                  id={main.category_name_EN.replace(/ /g, "")}
                  key={index.toString()}
                >
                  <div className="not_hover">
                    <Card
                      style={{ backgroundColor: "#F7F5EB", border: "none" }}
                    >
                      <Card.Body>
                        <Row>
                          <Card.Title className="catagory_description pointer">
                            {language == "it" ? (
                              main?.category_description_IT ? (
                                main?.category_description_IT?.length > 100 ? (
                                  <div
                                    onClick={() =>
                                      setshoeMoredescription(
                                        !shoeMoredescription
                                      )
                                    }
                                  >
                                    {shoeMoredescription == true
                                      ? `${main.category_description_IT}... See less`
                                      : `${main.category_description_IT.substring(
                                          0,
                                          150
                                        )}... Show more`}
                                  </div>
                                ) : (
                                  <div>{main.category_description_IT}</div>
                                )
                              ) : main?.category_description_EN?.length >
                                100 ? (
                                <div
                                  onClick={() =>
                                    setshoeMoredescription(!shoeMoredescription)
                                  }
                                >
                                  {shoeMoredescription == true
                                    ? `${main.category_description_EN}... See less`
                                    : `${main.category_description_EN.substring(
                                        0,
                                        150
                                      )}... Show more`}
                                </div>
                              ) : (
                                <div>{main.category_description_EN}</div>
                              )
                            ) : language == "de" ? (
                              main?.category_description_DE ? (
                                main?.category_description_DE.length > 100 ? (
                                  <div
                                    onClick={() =>
                                      setshoeMoredescription(
                                        !shoeMoredescription
                                      )
                                    }
                                  >
                                    {shoeMoredescription == true
                                      ? `${main.category_description_DE}... See less`
                                      : `${main.category_description_DE.substring(
                                          0,
                                          150
                                        )}... Show more`}
                                  </div>
                                ) : (
                                  <div>{main.category_description_DE}</div>
                                )
                              ) : main?.category_description_EN.length > 100 ? (
                                <div
                                  onClick={() =>
                                    setshoeMoredescription(!shoeMoredescription)
                                  }
                                >
                                  {shoeMoredescription == true
                                    ? `${main.category_description_EN}... See less`
                                    : `${main.category_description_EN.substring(
                                        0,
                                        150
                                      )}... Show more`}
                                </div>
                              ) : (
                                <div>{main.category_description_EN}</div>
                              )
                            ) : language == "fr" ? (
                              main?.category_description_FR ? (
                                main?.category_description_FR?.length > 100 ? (
                                  <div
                                    onClick={() =>
                                      setshoeMoredescription(
                                        !shoeMoredescription
                                      )
                                    }
                                  >
                                    {shoeMoredescription == true
                                      ? `${main.category_description_FR}... See less`
                                      : `${main.category_description_FR.substring(
                                          0,
                                          150
                                        )}... Show more`}
                                  </div>
                                ) : (
                                  <div>{main.category_description_FR}</div>
                                )
                              ) : main?.category_description_EN.length > 100 ? (
                                <div
                                  onClick={() =>
                                    setshoeMoredescription(!shoeMoredescription)
                                  }
                                >
                                  {shoeMoredescription == true
                                    ? `${main.category_description_EN}... See less`
                                    : `${main.category_description_EN.substring(
                                        0,
                                        150
                                      )}... Show more`}
                                </div>
                              ) : (
                                <div>{main.category_description_EN}</div>
                              )
                            ) : language == "AR" ? (
                              main?.category_description_AR ? (
                                main?.category_description_AR?.length > 100 ? (
                                  <div
                                    onClick={() =>
                                      setshoeMoredescription(
                                        !shoeMoredescription
                                      )
                                    }
                                  >
                                    {shoeMoredescription == true
                                      ? `${main.category_description_AR}... See less`
                                      : `${main.category_description_AR.substring(
                                          0,
                                          150
                                        )}... Show more`}
                                  </div>
                                ) : (
                                  <div>{main.category_description_AR}</div>
                                )
                              ) : main?.category_description_EN.length > 100 ? (
                                <div
                                  onClick={() =>
                                    setshoeMoredescription(!shoeMoredescription)
                                  }
                                >
                                  {shoeMoredescription == true
                                    ? `${main.category_description_EN}... See less`
                                    : `${main.category_description_EN.substring(
                                        0,
                                        150
                                      )}... Show more`}
                                </div>
                              ) : (
                                <div>{main.category_description_EN}</div>
                              )
                            ) : main?.category_description_EN.length > 100 ? (
                              <div
                                onClick={() =>
                                  setshoeMoredescription(!shoeMoredescription)
                                }
                              >
                                {shoeMoredescription == true
                                  ? `${main.category_description_EN}... See less`
                                  : `${main.category_description_EN.substring(
                                      0,
                                      150
                                    )}... Show more`}
                              </div>
                            ) : (
                              <div>{main.category_description_EN}</div>
                            )}
                          </Card.Title>
                        </Row>
                        <Row>
                          <Card.Text className="catagory_disclaimer">
                            <i>
                              <strong
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {language == "it"
                                  ? "Disclaimer"
                                  : language == "de"
                                  ? "Haftungsausschluss"
                                  : language == "fr"
                                  ? "Clause de non-responsabilité"
                                  : language == "AR"
                                  ? "تنصل"
                                  : "Disclaimer"}
                                :{" "}
                              </strong>

                              <span>
                                {language == "it"
                                  ? main.category_disclaimer_IT
                                    ? main.category_disclaimer_IT
                                    : main.category_disclaimer_EN
                                  : language == "de"
                                  ? main.category_disclaimer_DE
                                    ? main.category_disclaimer_DE
                                    : main.category_disclaimer_EN
                                  : language == "fr"
                                  ? main.category_disclaimer_FR
                                    ? main.category_disclaimer_FR
                                    : main.category_disclaimer_EN
                                  : language == "AR"
                                  ? main.category_disclaimer_AR
                                    ? main.category_disclaimer_AR
                                    : main.category_disclaimer_EN
                                  : main.category_disclaimer_EN}
                              </span>
                            </i>
                          </Card.Text>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                  <br />
                  {ProductView === "Listview" ? (
                    <Container>
                      <Row className="justify-content-sm-start mx-0">
                        {main?.products?.length > 0 ? (
                          main?.products?.map((mainPro, index) => (
                            <>
                              <Col
                                key={index}
                                className="mt-4"
                                xs={12}
                                md={12}
                                lg={6}
                              >
                                <div className="not_hover">
                                  <Card
                                    className="Listview_hover my-1 "
                                    // onClick={handleShow}
                                  >
                                    <Card.Body className="p-0">
                                      <Row
                                        style={{
                                          minHeight: "150px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <Col xs={7} lg={8} md={8}>
                                          <div className="p-4">
                                            <Card.Title className="product_name">
                                              {/* {mainPro.name_EN} */}
                                              {language == "it"
                                                ? mainPro.name_IT
                                                  ? mainPro.name_IT
                                                  : mainPro.name_EN
                                                : language == "de"
                                                ? mainPro.name_DE
                                                  ? mainPro.name_DE
                                                  : mainPro.name_EN
                                                : language == "fr"
                                                ? mainPro.name_FR
                                                  ? mainPro.name_FR
                                                  : mainPro.name_EN
                                                : language == "AR"
                                                ? mainPro.name_AR
                                                  ? mainPro.name_AR
                                                  : mainPro.name_EN
                                                : mainPro.name_EN}
                                            </Card.Title>

                                            <Card
                                              style={{ border: 0, height: 55 }}
                                            >
                                              <Card.Text className="product_description">
                                                <div
                                                  // dir={
                                                  //   language == "AR"
                                                  //     ? "rtl"
                                                  //     : "ltr"
                                                  // }
                                                  // lang={
                                                  //   language == "AR" ? "ar" : ""
                                                  // }
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      language == "it"
                                                        ? mainPro
                                                            ?.description_IT
                                                            ?.length > 0
                                                          ? `${mainPro?.description_IT?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                          : `${mainPro?.description_EN?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                        : language == "de"
                                                        ? mainPro
                                                            ?.description_DE
                                                            ?.length > 0
                                                          ? `${mainPro?.description_EN?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                          : `${mainPro?.description_EN?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                        : language == "fr"
                                                        ? mainPro
                                                            ?.description_FR
                                                            ?.length > 0
                                                          ? `${mainPro?.description_FR?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                          : `${mainPro?.description_EN?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                        : language == "AR"
                                                        ? mainPro
                                                            ?.description_AR
                                                            ?.length > 0
                                                          ? `${mainPro?.description_AR?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                          : `${mainPro?.description_EN?.slice(
                                                              0,
                                                              165
                                                            )}...`
                                                        : mainPro
                                                            ?.description_EN
                                                            ?.length > 0
                                                        ? `${mainPro?.description_EN?.slice(
                                                            0,
                                                            165
                                                          )}...`
                                                        : "",
                                                  }}
                                                />

                                                {/* <div>
                                                {mainPro??.description_EN
                                                  ?.length > 120 ? (
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: `${mainPro?.description_EN?.slice(
                                                        0,
                                                        100
                                                      )}...`,
                                                    }}
                                                  />
                                                ) : (
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        mainPro?.description_EN
                                                          ? mainPro.description_EN
                                                          : "No description availabal",
                                                    }}
                                                  />
                                                )}
                                              </div> */}
                                              </Card.Text>
                                            </Card>

                                            <Col>
                                              <Card
                                                style={{
                                                  border: 0,
                                                  height: 28,
                                                }}
                                              >
                                                <Card.Text>
                                                  {mainPro?.properties?.map(
                                                    (item, index) => (
                                                      <TooltipMUi
                                                        // placement="right-end"
                                                        title={item}
                                                      >
                                                        <img
                                                          key={index}
                                                          alt="product_property"
                                                          style={{
                                                            backgroundColor:
                                                              "#f3f3f3f7",
                                                            height: "20px",
                                                            width: "135",
                                                            borderRadius: 30,
                                                          }}
                                                          src={
                                                            item == "Vegetarian"
                                                              ? "/assets/media/my/vegitarian_icon1.png"
                                                              : item == "Vegan"
                                                              ? "/assets/media/my/vegan.png"
                                                              : item ==
                                                                "Gluten Free"
                                                              ? "/assets/media/my/gluten-free.png"
                                                              : item ==
                                                                "Lactose Free"
                                                              ? "/assets/media/my/lactose-free.png"
                                                              : item == "Spicy"
                                                              ? "/assets/media/my/spicy.png"
                                                              : item ==
                                                                "Contains Alcohol"
                                                              ? "/assets/media/my/contains_alcohol.png"
                                                              : item ==
                                                                "Alcohol Free"
                                                              ? "/assets/media/my/alcoholno.png"
                                                              : item ==
                                                                "Chef’s Special"
                                                              ? "/assets/media/my/chef-hat.png"
                                                              : "/assets/media/my/wedding-dinner.png"
                                                          }
                                                          className="mx-1"
                                                        />
                                                      </TooltipMUi>
                                                    )
                                                  )}
                                                  {/* <img
                                                alt="Logo"
                                                src="/assets/media/vegan.png"
                                                style={{
                                                  backgroundColor: "#f3f3f3f7",
                                                  height: "20px",
                                                  width: "135",
                                                  borderRadius: 30,
                                                }}
                                              /> */}
                                                </Card.Text>
                                              </Card>
                                            </Col>
                                            <Row>
                                              <Col lg={4} md={4}>
                                                <Card.Title className="product_chf mt-2 text-start">
                                                  <h5>
                                                    {`CHF ${mainPro.base_price}`}
                                                  </h5>
                                                </Card.Title>
                                              </Col>
                                              <Col lg={8} md={8}>
                                                <div className="text-end mt-2">
                                                  <ButtonMUi
                                                    variant="contained"
                                                    endIcon={
                                                      <ShoppingCartOutlinedIcon />
                                                    }
                                                    className="custom_hover"
                                                    style={{
                                                      borderRadius: "120px",
                                                      // width:
                                                      //   isTablet == true
                                                      //     ? "145px"
                                                      //     : "130px",
                                                      width: "145px",

                                                      backgroundColor:
                                                        customRes?.button_colour ??
                                                        "#dc3545",
                                                      // height:
                                                      //   isTablet == true
                                                      //     ? "32px"
                                                      //     : "30px",
                                                      height: "32px",
                                                      fontWeight: 600,
                                                      textTransform: "none",
                                                      fontFamily:
                                                        '"Calibri", sans-serif',
                                                    }}
                                                    onClick={() => {
                                                      handleShow();
                                                      ProducteDetailsGet(
                                                        mainPro._id
                                                      );
                                                    }}
                                                  >
                                                    <strong
                                                      style={{
                                                        color:
                                                          customRes?.non_highlighted_text ??
                                                          "white",
                                                      }}
                                                    >
                                                      Add to Cart
                                                    </strong>{" "}
                                                  </ButtonMUi>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={5}
                                          lg={4}
                                          md={4}
                                          className="align-middle"
                                        >
                                          {mainPro?.image_url ? (
                                            <img
                                              alt="Product_image"
                                              src={
                                                mainPro.image_url
                                                  ? mainPro.image_url
                                                  : ""
                                              }
                                              style={{
                                                verticalAlign: "middle",
                                                // maxWidth: "100%",
                                                height: "200px",
                                                maxWidth:
                                                  "-webkit-fill-available",
                                                width: "100%",
                                                objectFit: "cover",
                                                backgroundColor: "white",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "initial",
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </Col>
                            </>
                          ))
                        ) : (
                          <React.Fragment>
                            <Row className="text-center">
                              <Col>No product available</Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Row>
                      <br />
                      <br />
                    </Container>
                  ) : (
                    <Container className="product_view_grid_mobile">
                      <Row className="justify-content-start">
                        {main?.products?.length > 0 ? (
                          main?.products?.map((mainPro, index) => (
                            <Col
                              md={6}
                              lg={3}
                              xs={12}
                              style={{
                                height: "auto",
                              }}
                              className="g-4"
                            >
                              <Card
                                className="GridView_hover d-flex text-center mx-1"
                                key={index}
                                style={{
                                  height: mainPro?.image_url ? "100%" : "",
                                }}
                              >
                                <Card.Body className="p-0">
                                  <Card.Title>
                                    {mainPro?.image_url ? (
                                      <img
                                        className="text-end justify-content-end d-flex pe-0"
                                        alt="Logo"
                                        src={
                                          mainPro.image_url
                                            ? mainPro.image_url
                                            : ""
                                        }
                                        style={{
                                          height: "220px",
                                          width: "100%",
                                          // maxHeight: "30%",
                                          maxWidth: "-webkit-fill-available",
                                          // borderRadius: "8px",
                                          backgroundColor: "#F1F6F5",
                                        }}
                                      />
                                    ) : (
                                      // <div
                                      //   className="text-center justify-content-center d-flex pe-0"
                                      //   style={{
                                      //     height: "220px",
                                      //     width: "100%",
                                      //     maxWidth: "-webkit-fill-available",
                                      //     backgroundColor: "white",
                                      //   }}
                                      // ></div>
                                      ""
                                    )}
                                  </Card.Title>

                                  <div className="px-3 py-2">
                                    <Row>
                                      <Col lg={12} md={12} xs={12}>
                                        <Card.Title className="product_name text-start mt-2">
                                          {language == "it"
                                            ? mainPro.name_IT
                                              ? mainPro.name_IT
                                              : mainPro.name_EN
                                            : language == "de"
                                            ? mainPro.name_DE
                                              ? mainPro.name_DE
                                              : mainPro.name_EN
                                            : language == "fr"
                                            ? mainPro.name_FR
                                              ? mainPro.name_FR
                                              : mainPro.name_EN
                                            : language == "AR"
                                            ? mainPro.name_AR
                                              ? mainPro.name_AR
                                              : mainPro.name_EN
                                            : mainPro.name_EN}
                                        </Card.Title>
                                      </Col>
                                      {isMobile ? (
                                        ""
                                      ) : (
                                        <Col lg={12} md={12} xs={12}>
                                          <Card
                                            style={{ border: 0, height: 51 }}
                                          >
                                            <Card.Text className="product_description text-start my-2">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    language == "it"
                                                      ? mainPro?.description_IT
                                                          ?.length > 0
                                                        ? `${mainPro?.description_IT?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                        : `${mainPro?.description_EN?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                      : language == "de"
                                                      ? mainPro?.description_DE
                                                          ?.length > 0
                                                        ? `${mainPro?.description_EN?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                        : `${mainPro?.description_EN?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                      : language == "fr"
                                                      ? mainPro?.description_FR
                                                          ?.length > 0
                                                        ? `${mainPro?.description_FR?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                        : `${mainPro?.description_EN?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                      : language == "AR"
                                                      ? mainPro?.description_AR
                                                          ?.length > 0
                                                        ? `${mainPro?.description_AR?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                        : `${mainPro?.description_EN?.slice(
                                                            0,
                                                            80
                                                          )}...`
                                                      : mainPro?.description_EN
                                                          ?.length > 0
                                                      ? `${mainPro?.description_EN?.slice(
                                                          0,
                                                          80
                                                        )}...`
                                                      : "",
                                                }}
                                              />
                                            </Card.Text>
                                          </Card>
                                        </Col>
                                      )}
                                      <Col lg={12} md={12} xs={12}>
                                        <Card style={{ border: 0, height: 28 }}>
                                          <Card.Text className="text-start mt-1">
                                            {mainPro?.properties?.map(
                                              (item, index) => (
                                                <TooltipMUi title={item}>
                                                  <img
                                                    key={index}
                                                    alt="product_property"
                                                    style={{
                                                      backgroundColor:
                                                        "#f3f3f3f7",
                                                      height: "20px",
                                                      width: "135",
                                                      borderRadius: 30,
                                                    }}
                                                    src={
                                                      item == "Vegetarian"
                                                        ? "/assets/media/my/vegitarian_icon1.png"
                                                        : item == "Vegan"
                                                        ? "/assets/media/my/vegan.png"
                                                        : item == "Gluten Free"
                                                        ? "/assets/media/my/gluten-free.png"
                                                        : item == "Lactose Free"
                                                        ? "/assets/media/my/lactose-free.png"
                                                        : item == "Spicy"
                                                        ? "/assets/media/my/spicy.png"
                                                        : item ==
                                                          "Contains Alcohol"
                                                        ? "/assets/media/my/contains_alcohol.png"
                                                        : item == "Alcohol Free"
                                                        ? "/assets/media/my/alcoholno.png"
                                                        : item ==
                                                          "Chef’s Special"
                                                        ? "/assets/media/my/chef-hat.png"
                                                        : "/assets/media/my/wedding-dinner.png"
                                                    }
                                                    className="mx-1"
                                                  />
                                                </TooltipMUi>
                                              )
                                            )}
                                          </Card.Text>
                                        </Card>
                                      </Col>
                                      <Col lg={12} md={12} xs={12}>
                                        <Card.Text
                                          className="product_chf text-start"
                                          style={{
                                            marginTop: 16,
                                          }}
                                        >
                                          {`CHF ${mainPro.base_price}`}
                                        </Card.Text>
                                      </Col>
                                      <Col lg={12} md={12} xs={12}>
                                        <div className="text-center my-3">
                                          <ButtonMUi
                                            variant="contained"
                                            className="custom_hover"
                                            endIcon={
                                              <ShoppingCartOutlinedIcon />
                                            }
                                            style={{
                                              borderRadius: "120px",
                                              width: "176px",
                                              backgroundColor:
                                                customRes?.button_colour ??
                                                "#dc3545",
                                              height: "39px",
                                              fontWeight: 600,
                                              textTransform: "none",
                                              marginTop: 12,
                                              fontFamily:
                                                '"Calibri", sans-serif',
                                            }}
                                            onClick={() => {
                                              handleShow();
                                              ProducteDetailsGet(mainPro._id);
                                            }}
                                          >
                                            <strong
                                              style={{
                                                color:
                                                  customRes?.non_highlighted_text ??
                                                  "white",
                                              }}
                                            >
                                              Add to Cart
                                            </strong>{" "}
                                          </ButtonMUi>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))
                        ) : (
                          <React.Fragment>
                            <Row className="text-center">
                              <Col>No product available</Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Row>
                      <br />
                      <br />
                    </Container>
                  )}
                </div>
              ))}
            </Card>
          </Container>

          {isMobile == true ? (
            ""
          ) : (
            <React.Fragment>
              <br />
              <br />
              <br />
              <br />
              <Card>
                <Row
                  expand="lg"
                  className="text-center"
                  style={{
                    position: "fixed",
                    bottom: 0,
                    zIndex: 1,
                    width: "100%",
                    padding: 0,
                    backgroundColor: "#FFF",
                  }}
                >
                  <Col lg={6} md={6}>
                    <div className="row mt-3 mb-3">
                      <div className="col text-center">
                        <strong
                          style={{
                            color: "black",
                          }}
                          className="Total_item"
                        >
                          {language == "it"
                            ? "Articolo totale"
                            : language == "de"
                            ? "Gesamtartikel"
                            : language == "fr"
                            ? "Article total"
                            : "Total item"}
                          : {cartCount_ > 0 ? cartCount_ : 0}
                        </strong>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="row mt-3 mb-3">
                      <div className="col text-center">
                        <button
                          style={{
                            backgroundColor:
                              customRes?.button_colour ?? "#dc3545",
                            border: 0,
                          }}
                          className="add_itm_to_continues_button text-center custom_hover"
                          // onClick={() => history.push("/CartPage")}
                          onClick={() =>
                            history.push(
                              `/${urlBranchID}/${urlTableId}/cartpage`
                            )
                          }
                        >
                          <strong
                            style={{
                              color: customRes?.non_highlighted_text ?? "white",
                            }}
                            className="view_cart_icon"
                          >
                            VIEW CART{" "}
                            <ArrowForwardIosIcon
                              style={{
                                fontSize: "24px",
                                marginLeft: "17px",
                                marginRight: "-14px",
                              }}
                            />
                            <ArrowForwardIosIcon />
                          </strong>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </React.Fragment>
          )}
        </div>

        <Modal
          scrollable={true}
          style={{ overflow: "auto" }}
          centered
          show={show}
          onHide={handleClose}
        >
          {Product_detaisBy_id != "" ? (
            <React.Fragment>
              <Modal.Body>
                <div className="hover14 column">
                  <figure>
                    <div onClick={handleClose} className="text-end">
                      <button
                        type="button"
                        // className="btn-close btn-close-white"
                        className="btn-close btn-close"
                        aria-label="Close"
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          right: -1,
                          // backgroundColor: "#5E6C79",
                          color: "white",
                        }}
                      ></button>
                    </div>
                    {Product_detaisBy_id?.image_url ? (
                      <img
                        src={
                          Product_detaisBy_id.image_url
                            ? Product_detaisBy_id.image_url
                            : ""
                        }
                        style={{
                          position: "relative",
                          backgroundColor: "#F1F6F5",
                          height: "260px",
                          // width: "135",
                          marginLeft: "-17px",
                          marginTop: "-17px",
                          width: "calc(95% + 60px)",
                          marginRight: "-17px",
                          // minHeight: "100vh",
                          maxWidth: "-webkit-fill-available",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </figure>
                </div>
                <h3
                  // className={Product_detaisBy_id?.image_url ? "" : "mt-5"}
                  style={{
                    margin: "0px",
                    fontFamily: "'Baloo 2', cursive",
                    fontWeight: "500",
                    letterSpacing: "0.25px",
                    color: "black",
                    fontStyle: "normal",
                  }}
                >
                  {language == "it"
                    ? Product_detaisBy_id.name_IT
                      ? Product_detaisBy_id.name_IT
                      : Product_detaisBy_id.name_EN
                    : language == "de"
                    ? Product_detaisBy_id.name_DE
                      ? Product_detaisBy_id.name_DE
                      : Product_detaisBy_id.name_EN
                    : language == "fr"
                    ? Product_detaisBy_id.name_FR
                      ? Product_detaisBy_id.name_FR
                      : Product_detaisBy_id.name_EN
                    : language == "AR"
                    ? Product_detaisBy_id.name_AR
                      ? Product_detaisBy_id.name_AR
                      : Product_detaisBy_id.name_EN
                    : Product_detaisBy_id.name_EN}
                </h3>
                <div className="d-flex m-1 mb-0 justify-content-center">
                  {Product_detaisBy_id.properties.length > 0
                    ? Product_detaisBy_id.properties.map((item, index) => (
                        <div style={{ width: "70px" }} key={index}>
                          <img
                            className="mx-4"
                            alt="Product_properties"
                            src={
                              item == "Vegetarian"
                                ? "/assets/media/my/vegitarian_icon1.png"
                                : item == "Vegan"
                                ? "/assets/media/my/vegan.png"
                                : item == "Gluten Free"
                                ? "/assets/media/my/gluten-free.png"
                                : item == "Lactose Free"
                                ? "/assets/media/my/lactose-free.png"
                                : item == "Spicy"
                                ? "/assets/media/my/spicy.png"
                                : item == "Contains Alcohol"
                                ? "/assets/media/my/contains_alcohol.png"
                                : item == "Alcohol Free"
                                ? "/assets/media/my/alcoholno.png"
                                : item == "Chef’s Special"
                                ? "/assets/media/my/chef-hat.png"
                                : "/assets/media/my/wedding-dinner.png"
                            }
                            style={{ height: "25px" }}
                          />
                          <div
                            className="mb-1 text-center"
                            style={{ fontSize: "0.70rem" }}
                          >
                            {item}
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
                <div
                  style={{
                    margin: "0px",
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight: "400",
                    letterSpacing: "0.25px",
                    color: "rgba(48, 50, 59, 0.73)",
                    fontStyle: "normal",
                    fontSize: "15px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        language == "it"
                          ? Product_detaisBy_id.description_IT
                            ? Product_detaisBy_id.description_IT
                            : Product_detaisBy_id.description_EN
                          : language == "de"
                          ? Product_detaisBy_id.description_DE
                            ? Product_detaisBy_id.description_DE
                            : Product_detaisBy_id.description_EN
                          : language == "fr"
                          ? Product_detaisBy_id.description_FR
                            ? Product_detaisBy_id.description_FR
                            : Product_detaisBy_id.description_EN
                          : language == "AR"
                          ? Product_detaisBy_id.description_AR
                            ? Product_detaisBy_id.description_AR
                            : Product_detaisBy_id.description_EN
                          : Product_detaisBy_id.description_EN,
                    }}
                  />
                </div>

                {Product_detaisBy_id.custom_property.length > 0 ? (
                  <React.Fragment>
                    <hr />
                    <h4
                      style={{
                        fontFamily: "'Baloo 2', sans-serif",
                        fontWeight: 400,
                        letterSpacing: "0.25px",
                        color: "black",
                        fontStyle: "normal",
                        fontSize: "17px",
                        marginBottom: 6,
                      }}
                    >
                      Choose from given choice
                    </h4>
                    {Product_detaisBy_id?.custom_property?.length > 0 ? (
                      Product_detaisBy_id?.custom_property?.map(
                        (item, index) => (
                          <Row className="checbox_tic" key={index}>
                            <Col lg={8} xs={8} md={8}>
                              <Form.Check
                                type="checkbox"
                                id="custom-switch"
                                label={item.ingredient}
                                name={item.name}
                                // checked={isChecked[item.ingredient]}
                                onChange={(event) => handleChange(item, event)}
                              />
                            </Col>
                            <Col lg={4} xs={4} md={4} className="text-end">
                              CHF {item.price}
                            </Col>
                          </Row>
                        )
                      )
                    ) : (
                      <h4
                        style={{
                          fontFamily: "'Baloo 2', sans-serif",
                          fontWeight: 400,
                          letterSpacing: "0.25px",
                          color: "black",
                          fontStyle: "normal",
                          fontSize: "13px",
                          marginBottom: 6,
                        }}
                      >
                        Custom Addon not available.
                      </h4>
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="container">
                  <div className="row">
                    <div className="col text-center">
                      <Row className="mb-2">
                        <Col lg={8} xs={8} md={8} className="text-start">
                          <span className="qut_price">
                            Qty :
                            <RemoveCircleOutlineIcon
                              className="pointer"
                              onClick={decrement_quantity}
                              style={{
                                marginRight: "12px",
                                marginLeft: "12px",
                                color: "rgba(48, 50, 59, 0.73)",
                              }}
                            />
                            &nbsp;
                            <button
                              style={{ border: 0, marginLeft: "-11px" }}
                              className="btn btn-outline"
                            >
                              {quantity}
                            </button>{" "}
                            &nbsp;
                            <AddCircleOutlineIcon
                              className="pointer"
                              onClick={increment_quantity}
                              style={{
                                marginLeft: "0px",
                                color: "rgba(48, 50, 59, 0.73)",
                              }}
                            />
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          xs={4}
                          md={4}
                          className="text-end mt-2 qut_price"
                        >
                          CHF{" "}
                          {Number(Product_detaisBy_id.base_price) *
                            Number(quantity) +
                            Number(totalCost * quantity)}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col text-center">
                      <div className="d-grid gap-2">
                        <Button
                          style={{
                            borderRadius: 10,
                            backgroundColor:
                              customRes?.button_colour ?? "#dc3545",
                          }}
                          className="text-center custom_hover"
                          onClick={() =>
                            Add_Order(Product_detaisBy_id.image_url)
                          }
                          // onClick={() => history.push("/CartPage")}
                        >
                          <strong
                            style={{
                              color: customRes?.non_highlighted_text ?? "white",
                            }}
                          >
                            {" "}
                            Add To Cart
                          </strong>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          ) : (
            ""
          )}
        </Modal>
      </div>
      {cartCount_ != "" && isMobile == true ? (
        <Card className="mobile_view_cart">
          <div
            style={{
              position: "fixed",
              bottom: 0,
              zIndex: 1,
              width: "100%",
              padding: 0,
              backgroundColor: "#FFF",
            }}
            className="container"
          >
            <div className="row mt-3 mb-3">
              <div className="col text-center">
                <button
                  style={{
                    backgroundColor: customRes?.button_colour ?? "#dc3545",
                    border: 0,
                  }}
                  className="add_itm_to_continues_button text-center custom_hover"
                  // onClick={() => history.push("/CartPage")}
                  onClick={() =>
                    history.push(`/${urlBranchID}/${urlTableId}/cartpage`)
                  }
                >
                  <strong
                    style={{
                      color: customRes?.non_highlighted_text ?? "white",
                    }}
                  >
                    VIEW CART ({cartCount_})
                  </strong>
                </button>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        ""
      )}

      <Modal style={{ overflow: "hidden" }} centered show={ShowDineIn}>
        <Container>
          <Row>
            <Col>
              <Box sx={{ width: "100%" }}>
                <Modal.Header style={{ border: 0 }}>
                  <h5>
                    {language == "it"
                      ? "Cenare in"
                      : language == "de"
                      ? "Speisen in"
                      : language == "fr"
                      ? "Dîner dans"
                      : language == "AR"
                      ? "تناول الطعام في"
                      : "Dine In"}
                  </h5>
                </Modal.Header>
                <div>
                  <div style={{ marginTop: -16 }}>
                    <Form onSubmit={handalSubmitDineIn}>
                      <Modal.Body>
                        <Form.Group>
                          <Form.Label className="order_food">
                            {language == "it"
                              ? "Ordine per cenare"
                              : language == "de"
                              ? "Bestellung für Dine In"
                              : language == "fr"
                              ? "Commande pour dîner sur place"
                              : language == "AR"
                              ? "اطلب تناول الطعام في"
                              : "Order for Dine In"}
                          </Form.Label>
                          <React.Fragment>
                            <Form.Group className="mb-3">
                              <Form.Control
                                type="text"
                                value={full_name_dineIn}
                                required
                                onChange={(e) =>
                                  setfull_name_dineIn(e.target.value)
                                }
                                placeholder={
                                  language == "it"
                                    ? "Inserisci il tuo nome e cognome *"
                                    : language == "de"
                                    ? "Geben Sie Ihren vollständigen Namen ein *"
                                    : language == "fr"
                                    ? "Entrez votre nom complet *"
                                    : language == "AR"
                                    ? "أدخل اسمك الكامل *"
                                    : "Enter your full name *"
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Control
                                type="number"
                                className="filterme"
                                value={phone_number_dineIn}
                                onChange={(e) =>
                                  setphone_number_dineIn(e.target.value)
                                }
                                required
                                placeholder={
                                  language == "it"
                                    ? "Inserisci il tuo telefono completo *"
                                    : language == "de"
                                    ? "Geben Sie Ihre vollständige Telefonnummer ein *"
                                    : language == "fr"
                                    ? "Entrez votre numéro de téléphone complet *"
                                    : language == "AR"
                                    ? "أدخل هاتفك *"
                                    : "Enter your phone *"
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="email"
                                required
                                placeholder={
                                  language == "it"
                                    ? "Inserisci la tua email completa *"
                                    : language == "de"
                                    ? "Geben Sie Ihre vollständige E-Mail-Adresse ein *"
                                    : language == "fr"
                                    ? "Entrez votre email complet *"
                                    : language == "AR"
                                    ? "أدخل بريدك الإلكتروني *"
                                    : "Enter your email *"
                                }
                                value={email_dineIn}
                                onChange={(e) =>
                                  setemail_dineIn(e.target.value)
                                }
                              />
                            </Form.Group>{" "}
                          </React.Fragment>
                        </Form.Group>
                      </Modal.Body>
                      <div className="col-md-12"> {errorDiv} </div>
                      <Modal.Footer>
                        <Container>
                          <Row>
                            <Col className="text-center">
                              <button
                                type="submit"
                                style={{
                                  backgroundColor:
                                    customRes?.button_colour ?? "#dc3545",
                                  border: 0,
                                }}
                                className="proceed_button text-center custom_hover"
                                // onClick={() => history.push("/FoodListPage")}
                              >
                                <strong
                                  style={{
                                    color:
                                      customRes?.non_highlighted_text ??
                                      "white",
                                  }}
                                >
                                  {language == "it"
                                    ? "PROCEDERE"
                                    : language == "de"
                                    ? "FORTFAHREN"
                                    : language == "fr"
                                    ? "PROCÉDER"
                                    : language == "AR"
                                    ? "يتابع"
                                    : "PROCEED"}
                                </strong>
                              </button>
                            </Col>
                          </Row>
                        </Container>
                      </Modal.Footer>
                    </Form>
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  branchdetailsbyid: state.branch.branchdetailsbyid,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  cartId: state.cart.cartId,
  cartCountData: state.cart.cartCountData,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCartId: (id) => dispatch(setCartId(id)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodListBoot);
