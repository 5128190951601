import { combineReducers } from "@reduxjs/toolkit";
import customUrlReducer from "./custom-data";
import customUrlReducerRes from "./custom-res";
import cmsUrlReducer from "./cms-data";
import cmsUrlReducerRes from "./cms-res";

const customReducer = combineReducers({
    customUrl: customUrlReducer,
    customRes: customUrlReducerRes,
    cmsUrl : cmsUrlReducer,
    cmsRes : cmsUrlReducerRes
})

export { customReducer }